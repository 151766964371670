import {
    Box,
    Chip,
    Grid,
    IconButton,
    Paper,
    Tab,
    Table,
    TableContainer, TableHead, TableRow,
    Tabs,
    TextField,
    TableCell, TableBody, TablePagination, FormControlLabel, Switch, InputAdornment, useMediaQuery
} from "@mui/material";
import React, {useState} from "react";
import {MoreVert, Search} from "@mui/icons-material";
import {PaperTheme, TextFieldTheme} from "../../../themes/themes";

export interface ColumnProps {
    label: string;
    minWidth?: number;
    align?: 'right';
    format: (row: any) => string | JSX.Element;
    search?: boolean;
    searchDefaultValue?: (row: any) => string;
}

interface DataTableProps {
    isLoading: boolean;
    isError: boolean;
    data: any[];
    columns: ColumnProps[];
    targetLabel?: string | null;
    mobileItem?: (row: any) => JSX.Element;
}

const dataColorTargetLabelFilter: string[] = [
    'secondary',
    'warning',
    'success',
    'info',
    'error',
]

export default function DataTable({
                                      isLoading,
                                      isError,
                                      data,
                                      columns,
                                      targetLabel = null,
                                      mobileItem
                                  }: DataTableProps): JSX.Element {
    const [tabs, setTabs] = useState("all");
    const [dense, setDense] = useState<boolean>(localStorage.getItem('dense') === 'true');
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const isMobile = useMediaQuery('(max-width:600px)');

    function handleChangeTabs(event: React.SyntheticEvent, newValue: string) {
        setTabs(newValue);
    }

    function handleFilterFromTabs() {
        if (tabs === "all") {
            return data;
        }
        const target = columns.find((column: ColumnProps) => column.label === targetLabel);
        if (target && target.searchDefaultValue) {
            return data.filter((row: any) => {
                if (target.searchDefaultValue && target.searchDefaultValue(row))
                    return target.searchDefaultValue(row) === tabs
                return false
            });
        }
        return data;
    }

    function handleFilterFromSearch() {
        return handleFilterFromTabs().filter((row: any) => {
            return columns.some((column: ColumnProps) => {
                if (column.searchDefaultValue && column.searchDefaultValue(row)) {
                    return column.searchDefaultValue ? column.searchDefaultValue(row).toLowerCase().includes(search.toLowerCase()) : false;
                }
                return false;
            });
        });
    }

    function getAllTheDifferentValuesByTargetValues(): { label: string, numberOfElement: number }[] {
        const allData: any = data.map((row: any) => {
            const target = columns.find((column: ColumnProps) => column.label === targetLabel);
            if (target && target.searchDefaultValue) {
                return target.searchDefaultValue(row);
            }
            return null;
        });

        const uniqueValues = Array.from(new Set(allData));
        return uniqueValues.map((value: any) => ({
            label: value,
            numberOfElement: allData.filter((elem: any) => elem === value).length
        }));
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = handleFilterFromSearch();
    const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    if (isMobile && mobileItem)
        return <>
            <Box sx={{borderBottom: 1, borderColor: 'divider', p: 2}}>
                <Grid container item xs={12} alignItems={"center"}>
                    <TextField fullWidth placeholder={'Rechercher'} variant={'outlined'} sx={{...TextFieldTheme}}
                               value={search} onChange={(event) => setSearch(event.target.value)}
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">
                                       <Search/>
                                   </InputAdornment>
                               }}/>
                </Grid>
            </Box>
            <Grid container item xs={12} spacing={2} sx={{mb: 4}}>
                {paginatedData.map((row: any, index: number) => <Grid item xs={12} key={`item-list-${row.id}-${index}`}>
                    <Paper sx={{...PaperTheme}}>
                        {mobileItem(row)}
                    </Paper>
                </Grid>)}
            </Grid>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>

    return <Paper sx={{boxShadow: 4, borderRadius: 4}}>
        {targetLabel && <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tabs} onChange={handleChangeTabs}>
                <Tab value={'all'} label={
                    <Box sx={{height: 20}} display={'flex'} alignItems={'center'}>
                        Tout
                        <Chip color={'primary'} size={'small'} label={data.length}
                              sx={{borderRadius: 1, fontSize: 11, ml: 0.5}}/>
                    </Box>
                }/>
                {getAllTheDifferentValuesByTargetValues().map((elem: {
                    label: string,
                    numberOfElement: number
                }, index: number) => (
                    <Tab key={`${elem.label}-tabs`} value={elem.label} label={
                        <Box sx={{height: 20}} display={'flex'} alignItems={'center'}>
                            {elem.label}
                            <Chip color={dataColorTargetLabelFilter[index] as any} size={'small'}
                                  label={elem.numberOfElement.toString()}
                                  sx={{borderRadius: 1, fontSize: 11, ml: 0.5}}/>
                        </Box>
                    }/>
                ))}
            </Tabs>
        </Box>}
        <Box sx={{borderBottom: 1, borderColor: 'divider', p: 2}}>
            <Grid container item xs={12} spacing={2} alignItems={"center"}>
                <Grid item xs={11.5}>
                    <TextField fullWidth placeholder={'Rechercher'} variant={'outlined'} sx={{...TextFieldTheme}}
                               value={search} onChange={(event) => setSearch(event.target.value)}
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">
                                       <Search/>
                                   </InputAdornment>
                               }}/>
                </Grid>
                <Grid container item xs={0.5} alignItems={'center'} justifyContent={'center'}>
                    <IconButton>
                        <MoreVert/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
        <TableContainer>
            <Table size={dense ? "small" : "medium"} stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column: ColumnProps, index: number) => (
                            <TableCell key={`${index}-${column.label}-header`} align={column.align}
                                       style={{minWidth: column.minWidth}}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading && <TableRow>
                        <TableCell colSpan={columns.length} align={'center'}>Chargement...</TableCell>
                    </TableRow>}
                    {isError && <TableRow>
                        <TableCell colSpan={columns.length} align={'center'}>Erreur de chargement...</TableCell>
                    </TableRow>}
                    {paginatedData.map((row: any, index: number) => {
                        return (
                            <TableRow key={`${index}-${row.id}-body`}>
                                {columns.map((column, index) => (
                                    <TableCell key={`${index}-${column.label}-columns`} align={column.align}>
                                        {column.format(row)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <FormControlLabel control={<Switch checked={dense} onClick={() => {
                setDense(prevState => !prevState);
                localStorage.setItem('dense', (!dense).toString());
            }}/>} label="Dense" sx={{ml: 2}}/>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    </Paper>
}