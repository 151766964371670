import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useSnackBar} from "../../tools/context/SnackBar";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {LoadingButton} from "@mui/lab";
import axios from "axios";

interface DialogTourProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    dataToEdit?: any;
}

export default function DialogTour({open, onClose, dataToEdit = null}: DialogTourProps): JSX.Element {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('');
    const msg = useSnackBar()
    const handleError = useHandleError();

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setName('')
    }

    useEffect(() => {
        if (dataToEdit && open) {
            setName(dataToEdit.name);
        }
    }, [dataToEdit, open]);

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setIsLoading(true);
            if (dataToEdit) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/tour/${dataToEdit.id}`,
                    {name},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/tour`,
                    {name},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            }
            msg('Tour créé avec succès', 'success');
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }


    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            component={'form'}
            onSubmit={handleSubmit}
            maxWidth={'xs'}
            fullWidth
        >
            <DialogTitle>{dataToEdit ? 'Editer un tour' : 'Créer un tour'}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{mt: 1}}
                    autoFocus
                    id="name"
                    label="Nom"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Annuler
                </Button>
                <LoadingButton variant={'contained'} loading={isLoading} type={'submit'} color="primary">
                    {dataToEdit ? 'Modifier' : 'Créer'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}