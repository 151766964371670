// Fonction pour vérifier si une date est un jour férié
function isHoliday(date: any) {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Jours fériés fixes
    const holidays: any = {
        0: [1], // Jour de l'An
        4: [1, 8], // Fête du Travail, Victoire des Alliés
        6: [14], // Fête nationale (14 juillet)
        7: [15], // Assomption
        10: [1, 11], // Toussaint, Armistice 1918
        11: [25], // Noël
    };

    // Calculer le jour de Pâques (date de Pâques - 1)
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n = Math.floor((h + l - 7 * m + 114) / 31);
    const p = (h + l - 7 * m + 114) % 31;
    const easterDate = new Date(year, n - 1, p + 1);
    const easterMonth = easterDate.getMonth();
    const easterDay = easterDate.getDate();

    holidays[easterMonth] = holidays[easterMonth] || [];
    holidays[easterMonth].push(easterDay - 1, easterDay, easterDay + 1); // Vendredi Saint, Pâques, Lundi de Pâques
    holidays[0].push(21); // Lundi de Pâques
    holidays[4].push(39); // Ascension
    holidays[6].push(50); // Lundi de Pentecôte
    holidays[11] = [26]; // Lendemain de Noël

    // Vérifier si la date est un jour férié
    return !!(holidays[month] && holidays[month].indexOf(day) !== -1);
}

function getFrenchDayName(dayOfWeek: any) {
    const daysOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    return daysOfWeek[dayOfWeek];
}

// Fonction pour générer tous les jours par mois d'une année avec les jours fériés
export default function generateDaysWithHolidays(year: number) {
    const monthsData = [];
    for (let month = 0; month < 12; month++) {
        const monthData: any = {
            open: true,
            month: "",
            days: [],
        };
        switch(month) {
            case 0:
                monthData.month = "Janvier";
                break;
            case 1:
                monthData.month = "Février";
                break;
            case 2:
                monthData.month = "Mars";
                break;
            case 3:
                monthData.month = "Avril";
                break;
            case 4:
                monthData.month = "Mai";
                break;
            case 5:
                monthData.month = "Juin";
                break;
            case 6:
                monthData.month = "Juillet";
                break;
            case 7:
                monthData.month = "Août";
                break;
            case 8:
                monthData.month = "Septembre";
                break;
            case 9:
                monthData.month = "Octobre";
                break;
            case 10:
                monthData.month = "Novembre";
                break;
            case 11:
                monthData.month = "Décembre";
                break;
        }
        for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
            const date = new Date(year, month, day);
            monthData.days.push({
                day: day,
                dayOfWeek: getFrenchDayName(date.getDay()),
                isHoliday: isHoliday(date),
                slots: 0,
            });
        }
        monthsData.push(monthData);
    }
    return monthsData;
}
