import React from "react";
import {useSnackBar} from "./SnackBar";

const ErrorHandlerContext: React.Context<(err: any) => void> = React.createContext((err: any) => {});

export default function ErrorHandler({children}: {
    children: React.ReactNode
}): JSX.Element {
    const msg = useSnackBar();

    const handleError = React.useCallback((err: any) => {
        if (err.response) {
            if (err.response.status === 401) {
                msg('Vous n\'êtes pas autorisé à effectuer cette action', 'error');
                localStorage.removeItem('token');
                window.location.href = '/login';
                return;
            }
            if (err.response.status === 403) {
                msg('Vous n\'êtes pas autorisé à effectuer cette action', 'error');
                return;
            }
            if (err.response.status === 404) {
                msg('La ressource demandée est introuvable', 'error');
                return;
            }
            if (err.response.status === 500) {
                msg('Une erreur s\'est produite', 'error');
                return;
            }
        } else {
            if (err.message === 'Network Error') {
                localStorage.removeItem('token');
                msg('Impossible de se connecter au serveur', 'error');
                return;
            } else {
                console.error(err);
                msg('Une erreur s\'est produite', 'error');
                return;
            }
        }
    }, [msg]);


    return <ErrorHandlerContext.Provider value={handleError}>
        {children}
    </ErrorHandlerContext.Provider>
}

export const useHandleError = () => React.useContext(ErrorHandlerContext);