import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import {TextFieldTheme} from "../../../themes/themes";
import {useEffect, useState} from "react";
import {useHandleError} from "../context/ErrorHandler";
import axios from "axios";

interface MultiAutocompleteUrlProps {
    url: string
    value: any[] | undefined;
    onChange: (value: any[] | undefined) => void
    disabled?: boolean
    required?: boolean
    helperText?: string
    label?: string
    size?: 'small' | 'medium'

    renderOption?(option: any): JSX.Element

    optionEqualToValue?(option: any, value: any): boolean

    optionLabel?(option: any): string
}

export default function MultiAutocompleteUrl({
                                                 url,
                                                 value,
                                                 onChange,
                                                 disabled,
                                                 required,
                                                 helperText,
                                                 label,
                                                 size = 'medium',
                                                 optionEqualToValue = (option, value): boolean => option.id === value.id,
                                                 optionLabel = (option: any) => option.name,
                                                 renderOption = (option) => <Typography>{option.label}</Typography>
                                             }: MultiAutocompleteUrlProps): JSX.Element {
    const [data, setData] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const handleError = useHandleError();

    useEffect(() => {
        if (!open)
            return;
        (async (): Promise<void> => {
            try {
                setIsError(false);
                setIsLoading(true);
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                setData(response.data);
                setIsLoading(false);
            } catch (err: any) {
                setIsLoading(false);
                setIsError(true);
                handleError(err);
            }
        })()
    }, [handleError, open, url]);

    return <Autocomplete
        disablePortal
        options={data}
        loading={isLoading}
        multiple
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={optionEqualToValue}
        getOptionLabel={optionLabel}
        open={open}
        fullWidth
        value={value}
        size={size}
        disabled={disabled}
        onChange={(event: any, newValue: any[] | undefined) => {
            onChange(newValue);
        }}
        renderOption={(props, option) => {
            return <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                {renderOption(option)}
            </Box>
        }}
        sx={{...TextFieldTheme}}
        renderInput={(params: any) => <TextField {...params} error={isError} label={label}
                                                 required={required ? value?.length === 0 : false}
                                                 helperText={isError ? "Une erreur est survenue, veuilliez réessaye." : helperText}
                                                 inputProps={{
                                                     ...params.inputProps,
                                                     autoComplete: 'new-password',
                                                 }}
        />}
    />;
}