import {Alert, LoadingButton} from "@mui/lab";
import {Grid, TextField, Link, Box} from "@mui/material";
import axios from "axios";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {TextFieldTheme} from "../../themes/themes";

export default function Login(): JSX.Element {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/app');
        }
    }, [navigate]);

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, {
                email,
                password
            });
            localStorage.setItem('token', response.data.token);
            navigate('/app');
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setError(true);
            console.error(e);
        }
    }

    return <Grid container item xs={12} height={'100vh'}>
        <Grid container item xl={8.8} md={8.8} xs={12} sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            display: {xs: 'none', md: 'flex', xl: 'flex'}
        }} alignItems={'center'} justifyContent={'center'} direction={'column'}>
            <h1>Hi, Welcome back</h1>
            <img alt={'illustration_login'} src={'/illustration_dashboard.png'}/>
        </Grid>
        <Grid container item xs={12} md={3.2} xl={3.2} sx={{
            bgcolor: 'background.paper',
            color: 'text.primary',
        }} p={6} alignItems={'center'}>
            <Box sx={{textAlign: 'center'}} component="form" onSubmit={handleSubmit}>
                <h1>Sign in</h1>
                <TextField value={email} onChange={(event: any) => setEmail(event.target.value)}
                           placeholder={"john.doe@gmail.com"} sx={{mb: 2, mt: 2, ...TextFieldTheme}} label={'Email'}
                           variant={'outlined'} fullWidth type={'email'}/>
                <TextField value={password} onChange={(event: any) => setPassword(event.target.value)}
                           placeholder={"***********"} sx={{mb: 2, ...TextFieldTheme}} label={'Mot de passe'}
                           variant={'outlined'} fullWidth type={'password'}/>
                <Box sx={{textAlign: 'right', mb: 2, ...TextFieldTheme}}>
                    <Link>Forgot password?</Link>
                </Box>
                {error && <Alert severity="error" sx={{mb: 2}}>Votre email ou mot de passe est incorrect.</Alert>}
                <LoadingButton loading={isLoading} sx={{mb: 2}} size={'large'}
                               type={'submit'} variant={'contained'} fullWidth>Login</LoadingButton>
                <Link href={'/'}>Back to home</Link>
            </Box>
        </Grid>
    </Grid>;
}