import {PaperTheme} from "../../themes/themes";
import {Box, List, ListItem, Paper, Typography} from "@mui/material";
import {Alert} from "@mui/lab";
import dayjs from "dayjs";

interface CardPlanningProps {
    data: any[]
}

export default function CardPlanning({data}: CardPlanningProps) {
    return <Paper variant={'outlined'} sx={{...PaperTheme, boxShadow: 0}}>
        <Typography variant={'body1'} fontWeight={'bold'} sx={{mb: 1}}>
            Mon planning de la journee
        </Typography>
        {data.length === 0 ? <Alert severity="info" sx={{mb: 1}}>
            Vous n'avez aucun planning pour aujourd'hui
        </Alert> : <List>
            {data.map((event: any) => <ListItem key={event.id}>
                <Box sx={{width: '100%', height: '10px', backgroundColor: event.color, borderRadius: '5px', mb: 1}}/>
                <Typography variant={'body2'} fontWeight={'bold'}>
                    {event.title}
                </Typography>
                <Typography variant={'body2'}>
                    {dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}
                </Typography>
            </ListItem>)}
        </List>}
    </Paper>
}