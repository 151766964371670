import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {useState} from "react";
import {TextFieldTheme} from "../../../themes/themes";
import {DatePicker} from "@mui/x-date-pickers";
import {Alert} from "@mui/lab";
import dayjs from "dayjs";

interface DialogNewDateRangeProps {
    open: boolean;
    onClose: (isToReload: boolean, data: {
        startDate: any,
        endDate: any,
        color: string
    } | null) => void;
}

export default function DialogNewDateRange({open, onClose}: DialogNewDateRangeProps) {
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [error, setError] = useState<string>('');
    const [color, setColor] = useState<string>('blue');

    function handleClose(isToReload: boolean) {
        if (!isToReload) {
            onClose(isToReload, null);
            setStartDate(null);
            setEndDate(null);
        } else {
            if (startDate === null || endDate === null) {
                setError('Vous devez renseigner une date de debut et une date de fin');
                return;
            }
            onClose(isToReload, {
                startDate,
                endDate,
                color
            });
            setStartDate(null);
            setEndDate(null);
        }
    }

    return <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>
            Nouvelle plage de date
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{mt: 0}}>
                <Grid item xs={6}>
                    <DatePicker label={'Date de debut'} sx={{
                        width: '100%', ...TextFieldTheme
                    }} value={
                        startDate
                    } maxDate={
                        dayjs()
                    } onChange={(date) => setStartDate(date)}
                                    format={'DD/MM/YYYY'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker label={'Date de fin'} sx={{
                        width: '100%', ...TextFieldTheme
                    }} value={
                        endDate
                    } disabled={startDate === null} minDate={
                        startDate
                    } maxDate={
                        dayjs()
                    } onChange={(date) => setEndDate(date)}
                                    format={'DD/MM/YYYY'}
                    />
                </Grid>
                {error !== '' && <Grid item xs={12}>
                    <Alert severity={'error'}>{error}</Alert>
                </Grid>}
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{...TextFieldTheme}}>
                        <InputLabel>Couleur</InputLabel>
                        <Select
                            value={color}
                            label="Couleur"
                            onChange={
                                (event: any) => {
                                    setColor(event.target.value)
                                }
                            }
                        >
                            <MenuItem value={'blue'}>Bleu</MenuItem>
                            <MenuItem value={'red'}>Rouge</MenuItem>
                            <MenuItem value={'green'}>Vert</MenuItem>
                            <MenuItem value={'yellow'}>Jaune</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(false)}>Annuler</Button>
            <Button variant={'contained'} onClick={() => handleClose(true)}>Valider</Button>
        </DialogActions>
    </Dialog>
}