import {CSSObject, Divider, List, ListSubheader, styled, Theme} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import SpeedIcon from "@mui/icons-material/Speed";
import NewspaperIcon from '@mui/icons-material/Newspaper';
// import ChatIcon from "@mui/icons-material/Chat";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ApprovalIcon from "@mui/icons-material/Approval";
import DashboardIcon from '@mui/icons-material/Dashboard';
// import NewspaperIcon from "@mui/icons-material/Newspaper";
import BusinessIcon from '@mui/icons-material/Business';
import {CalendarMonth, DynamicForm, GitHub, Settings} from "@mui/icons-material";
import {drawerWidth, UIDrawerHeader} from "../../pages/app/page";
import ListItemCollapse from "./ListItemCollapse";
import {useUser} from "../tools/context/User";
import {useMediaQuery} from '@mui/material';
import {useOffice} from "../tools/context/Office";
import BarChartIcon from '@mui/icons-material/BarChart';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';

export interface DrawerItemsUrl {
    name: string;
    icon: JSX.Element;
    url: string;
    subItems?: { title: string, url: string }[];
    condition?: boolean;
}

const drawerOfiice: DrawerItemsUrl[] = [
    {name: 'Employés', icon: <SpeedIcon fontSize={'small'}/>, url: '/offices/workers'},
    {name: 'DDC', icon: <ApprovalIcon fontSize={'small'}/>, url: '/offices/holiday-approval'},
    {name: 'Planning', icon: <CalendarMonth fontSize={'small'}/>, url: '/offices/planning'},
];

const drawerAdmin: DrawerItemsUrl[] = [
    {name: 'Analyse', icon: <BarChartIcon fontSize={'small'}/>, url: '/analyze'},
    {name: 'Bureaux', icon: <BusinessIcon fontSize={'small'}/>, url: '/offices'},
    {name: 'Employés', icon: <SpeedIcon fontSize={'small'}/>, url: '/workers'},
    {name: 'DDC', icon: <ApprovalIcon fontSize={'small'}/>, url: '/holiday-approval'},
    {name: 'Planning', icon: <CalendarMonth fontSize={'small'}/>, url: '/planning'},
    {name: 'Fichiers', icon: <FolderCopyIcon fontSize={'small'}/>, url: '/files'},
    // {name: 'News', icon: <NewspaperIcon fontSize={'small'}/>, url: '/news'},
    {
        name: 'Paramétres', icon: <Settings fontSize={'small'}/>, url: '/settings', subItems: [
            // {title: 'Général', url: '/general'},
            {title: 'Congés', url: '/settings/holidays'},
            {title: 'Evenements', url: '/settings/events'},
            {title: 'Planning', url: '/settings/planning'},
            {title: 'Tournée', url: '/settings/tour'},
            // {title: 'Entreprise', url: '/company'},
            // {title: 'Notifications', url: '/notifications'},
        ]
    },
];

interface DrawerDashboardProps {
    open: boolean;
}

export default function DrawerDashboard({open}: DrawerDashboardProps): JSX.Element {
    const user = useUser();
    const isMobile = useMediaQuery('(max-width:600px)');
    const office = useOffice();

    const drawerUser: DrawerItemsUrl[] = [
        {name: 'Dashboard', icon: <DashboardIcon fontSize={'small'}/>, url: '/dashboard'},
        // {name: 'Chat', icon: <ChatIcon fontSize={'small'}/>, url: '/chat'},
        {name: 'Quotidien', icon: <DynamicForm fontSize={'small'}/>, url: '/dailyForm', condition: user.dailyFormMandatory},
        {name: 'Planning', icon: <CalendarMonth fontSize={'small'}/>, url: '/calendar'},
        {name: 'Congés', icon: <ChecklistIcon fontSize={'small'}/>, url: '/holidays'},
        {name: 'News', icon: <NewspaperIcon fontSize={'small'}/>, url: '/news'},
    ];

    const openedMixin = (theme: Theme): CSSObject => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: isMobile ? 0 : `calc(${theme.spacing(7)} + 20px)`,
        [theme.breakpoints.up('sm')]: {
            width: isMobile ? 0 : `calc(${theme.spacing(8)} + 20px)`,
        },
    });

    const UIDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            zIndex: 0,
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    return <UIDrawer anchor={"left"} variant={"permanent"} open={open}>
        <UIDrawerHeader sx={{display: 'flex', justifyContent: 'start', ml: 2.5}}>
            <GitHub/>
        </UIDrawerHeader>
        <List component="nav"
              dense
              aria-labelledby="nested-list-subheader"
              sx={{textAlign: open ? 'start' : undefined, ml: 1, mr: 1}}
              subheader={
                  open ? <ListSubheader component="div" id="nested-list-subheader">
                      {"DASHBOARD"}
                  </ListSubheader> : undefined
              }>
            {drawerUser.map((text, index) => {
                if (text.condition !== undefined && !text.condition) return null;
                return <ListItemCollapse key={`${text.name}-${index}-items`}
                                         url={text.url} drawerOpen={open} icon={text.icon}
                                         title={text.name} subItems={text.subItems}/>
            })}
        </List>
        {!open && !isMobile && user.role !== "WORKER" && user.offices.length > 0 && <Divider/>}
        {!isMobile && user.offices.length > 0 && <List component="nav"
                                                       dense
                                                       aria-labelledby="nested-list-subheader"
                                                       sx={{textAlign: open ? 'start' : undefined, ml: 1, mr: 1}}
                                                       subheader={
                                                           open ? <ListSubheader component="div"
                                                                                 id="nested-list-subheader">
                                                               {!!office ? office.name : "Mon bureaux"}
                                                           </ListSubheader> : undefined
                                                       }>
            {drawerOfiice.map((text, index) => <ListItemCollapse key={`${text.name}-${index}-itemsOffices`}
                                                                 url={text.url} drawerOpen={open}
                                                                 icon={text.icon}
                                                                 title={text.name} subItems={text.subItems}/>)}
        </List>}
        {!open && !isMobile && user.role !== "WORKER" && user.role !== "ADMIN" && <Divider/>}
        {!isMobile && <List component="nav"
                            dense
                            aria-labelledby="nested-list-subheader"
                            sx={{textAlign: open ? 'start' : undefined, ml: 1, mr: 1}}
                            subheader={
                                open ? <ListSubheader component="div" id="nested-list-subheader">
                                    {user.company.name.toUpperCase()}
                                </ListSubheader> : undefined
                            }>
            {drawerAdmin.map((text, index) => <ListItemCollapse key={`${text.name}-${index}-itemsAdmin`}
                                                                url={text.url} drawerOpen={open}
                                                                icon={text.icon}
                                                                title={text.name} subItems={text.subItems}/>)}
        </List>}
    </UIDrawer>
}