import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, Icon, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem,
    Paper, Select, Table, TableBody, TableCell, TableContainer,
    TableHead,
    TableRow,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import MultiAutocompleteUrl from "../../tools/input/MultiAutocompleteUrl";
import {Add, Delete} from "@mui/icons-material";
import axios from "axios";
import {useSnackBar} from "../../tools/context/SnackBar";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {Alert, LoadingButton} from "@mui/lab";
import {listGradientColor} from "../../calendar/dialog/DialogEventTemplate";
import moment from "moment";
import {TimePicker} from "@mui/x-date-pickers";
import {TextFieldTheme} from "../../../themes/themes";
import dayjs from "dayjs";

interface DialogTemplatePlanningProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    planningToEdit?: any | null;
}

function handleGenerateRamdonId(): string {
    return Math.random().toString(36).substring(7) + '-' + moment().unix();
}

export default function DialogTemplatePlanning({
                                                   open,
                                                   onClose,
                                                   planningToEdit
                                               }: DialogTemplatePlanningProps): JSX.Element {
    const [name, setName] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [offices, setOffices] = useState<any[] | undefined>([]);
    const [data, setData] = useState<any[]>([{
        title: null,
        notes: null,
        startTime: null,
        endTime: null,
        id: handleGenerateRamdonId(),
        color: listGradientColor[0]
    }]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const msg = useSnackBar();
    const handleError = useHandleError();

    useEffect(() => {
        if (planningToEdit) {
            setName(planningToEdit.title);
            setNotes(planningToEdit.notes);
            setOffices(planningToEdit.offices);
            let newData = planningToEdit.data.map((row: any) => {
                return {
                    ...row,
                    startTime: row.startTime ? dayjs(row.startTime) : dayjs().hour(4).minute(30).second(0).millisecond(0),
                    endTime: row.endTime ? dayjs(row.endTime) : dayjs().hour(13).minute(0).second(0).millisecond(0),
                }
            })
            setData(newData);
        }
    }, [planningToEdit]);

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setName('');
        setNotes('');
        setOffices([]);
        setData([{
            title: null,
            notes: null,
            startTime: dayjs().hour(4).minute(30).second(0).millisecond(0),
            endTime: dayjs().hour(13).minute(0).second(0).millisecond(0),
            id: handleGenerateRamdonId(),
            color: listGradientColor[0]
        }]);
    }

    async function handleSubmit(eventTarget: any) {
        eventTarget.preventDefault();
        setIsLoading(true);
        try {
            if (planningToEdit) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/events/templatePlanning/${planningToEdit.id}`, {
                    title: name,
                    notes,
                    offices,
                    data
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/events/templatePlanning`, {
                    title: name,
                    notes,
                    offices,
                    data
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            }
            msg('Le modèle de planning a bien été sauvegardé.', 'success');
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    return <Dialog open={open} onClose={
        () => handleClose(false)
    } fullWidth maxWidth={'md'} onSubmit={handleSubmit} component={'form'}>
        <DialogTitle>
            {planningToEdit ? 'Modifier' : 'Ajouter'} un modèle de planning
        </DialogTitle>
        <DialogContent>
            <Grid container item xs={12} spacing={2} sx={{mt: 0}}>
                <Grid item xs={6}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Nom</Typography>
                    <TextField
                        required
                        variant={'outlined'}
                        sx={{
                            ...TextFieldTheme
                        }}
                        fullWidth
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Relier des
                        bureaux</Typography>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={offices}
                                          onChange={(value: any[] | undefined) => setOffices(value)}
                                          renderOption={(option: any) => option.name}
                                          helperText={'Permet de relier une template à des bureaux, si aucun bureau n\'est relié, la template sera visible pour tous les bureaux.'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert severity={'info'}>
                        Les événements seront affectés à chaque jour de la semaine. (Lundi, Mardi, Mercredi, Jeudi,
                        Vendredi, Samedi, Dimanche)
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Tooltip title={'Ajouter un evenement'}>
                            <IconButton size={'small'} onClick={() => {
                                setData([...data, {
                                    title: null,
                                    notes: null,
                                    id: handleGenerateRamdonId(),
                                    color: listGradientColor[0]
                                }])
                            }}>
                                <Add fontSize={'small'}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <List disablePadding dense sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        p: 0.5
                    }}>
                        {data.map((row, index) => {
                            return <ListItem key={`${row.id}-list`} sx={{mb: 1}}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField label={'Titre'} required sx={{
                                            ...TextFieldTheme
                                        }} variant={'outlined'} size={'small'} fullWidth
                                                   value={row.title} onChange={(e) => {
                                            data[index].title = e.target.value;
                                            setData([...data]);
                                        }}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TimePicker sx={
                                            {
                                                width: '100%',
                                                '& .MuiInputBase-root': {
                                                    width: '100%',
                                                    height: 40,
                                                },
                                                ...TextFieldTheme
                                            }
                                        } label={'Heure de début'} value={dayjs(row.startTime)} onChange={(value) => {
                                            data[index].startTime = value;
                                            setData([...data]);
                                        }}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TimePicker sx={
                                            {
                                                width: '100%',
                                                '& .MuiInputBase-root': {
                                                    width: '100%',
                                                    height: 40
                                                },
                                                ...TextFieldTheme
                                            }
                                        } label={'Heure de fin'} value={dayjs(row.endTime)} onChange={(value) => {
                                            data[index].endTime = value;
                                            setData([...data]);
                                        }}/>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl size={'small'} fullWidth>
                                            <InputLabel>Couleur</InputLabel>
                                            <Select
                                                label="Couleur"
                                                value={row.color}
                                                onChange={(event: any) => {
                                                    data[index].color = event.target.value;
                                                    setData([...data]);
                                                }}
                                                renderValue={(value) => <Box
                                                    sx={{background: value, borderRadius: 1, height: 24}}/>}
                                            >
                                                {
                                                    listGradientColor.map((color, index) => <MenuItem key={index}
                                                                                                      value={color}
                                                                                                      sx={{
                                                                                                          background: color,
                                                                                                          borderRadius: 1,
                                                                                                          height: 20,
                                                                                                          m: 1
                                                                                                      }}>

                                                    </MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField sx={{
                                            ...TextFieldTheme
                                        }} label={'Notes'} rows={3} multiline variant={'outlined'}
                                                   size={'small'} fullWidth
                                                   value={row.notes} onChange={(e) => {
                                            data[index].notes = e.target.value;
                                            setData([...data]);
                                        }}/>
                                    </Grid>
                                </Grid>

                                <ListItemSecondaryAction>
                                    <IconButton size={'small'} onClick={
                                        () => {
                                            data.splice(index, 1);
                                            setData([...data]);
                                        }
                                    }>
                                        <Delete fontSize={'small'}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Note</Typography>
                    <TextField
                        variant={'outlined'}
                        fullWidth
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value)
                        }}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} variant={'contained'} type={'submit'}>
                {planningToEdit ? 'Modifier' : 'Sauvegarder'}
            </LoadingButton>
        </DialogActions>
    </Dialog>
}