import {Box, useMediaQuery} from "@mui/material";
import React from "react";

interface StretchProps {
    children: React.ReactNode;
}

export default function Stretch({children}: StretchProps) {
    const isMobile = useMediaQuery('(max-width:600px)');

    return <Box width={isMobile ? "100%" : 1200} height={'100%'}>
        {children}
    </Box>;
}