import React, {createContext, useContext, useEffect} from "react";
import DialogFirstConnectionUser from "../../drawer/dialog/DialogFirstConnectionUser";
import {Typography} from "@mui/material";
import {useHandleError} from "./ErrorHandler";
import axios from "axios";
import DialogSeeNews from "../../news/dialog/DialogSeeNews";

const UserContext: React.Context<any | null> = createContext(null);

export default function User({children, setSelectedOffice, setIsConnected}: {
    children: React.ReactNode,
    setSelectedOffice: any,
    setIsConnected: any
}): JSX.Element {
    const handleError = useHandleError()
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<any | null>(null);
    const [news, setNews] = React.useState<any | null>(null);

    function handleCloseFirstConnection(isToReload: boolean) {
        if (isToReload) {
            window.location.reload();
        } else {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                 });

                 const news = await axios.get(`${process.env.REACT_APP_API_URL}/api/news?seen=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                 });

                setNews(news.data ? news.data : null);

                console.log(news.data)

                const data = response.data;
                setData(data)
                setSelectedOffice(data.offices.length > 0 ? data.offices[0] : null);
                setIsConnected(true);
                setIsLoading(false);
            } catch (err: any) {
                handleError(err);
                window.location.href = '/login';
            }
        })()
    }, [setIsConnected, handleError, setSelectedOffice]);

    if (isLoading || !data) return <Typography>Loading...</Typography>

    // handleSelectOffice(data.offices[0]);
    return <UserContext.Provider value={data}>
        {children}
        <DialogFirstConnectionUser open={data?.isFirstConnection} onClose={handleCloseFirstConnection} user={data}/>
        <DialogSeeNews open={news} onClose={() => setNews(null)} news={news}/>
    </UserContext.Provider>
}

export const useUser = () => useContext(UserContext);