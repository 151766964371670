export default function getStartAndEndOfMonth() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    const startDate = new Date(year, month, 1);

    const endDate = new Date(year, month + 1, 0);

    return {
        startDate: startDate,
        endDate: endDate
    };
}