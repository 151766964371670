import {Box, Breadcrumbs, Button, Grid, IconButton, Link, Typography} from "@mui/material";
import {Add, Delete, Edit} from "@mui/icons-material";
import DataTable, {ColumnProps} from "../../../components/tools/display/DataTable";
import React, {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import dayjs from "dayjs";
import DialogTour from "../../../components/tour/dialog/DialogTour";
import DialogConfirmationDelete from "../../../components/tools/dialog/DialogConfirmationDelete";

export default function Tour() {
    const [data, setData] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [isToReload, setIsToReload] = React.useState<boolean>(true);
    const [tourToEdit, setTourToEdit] = React.useState<any | null>(null);
    const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const handleError = useHandleError();

    useEffect(() => {
        (async () => {
            if (!isToReload)
                return;
            try {
                setIsLoading(true);
                const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/tour`, localStorage.getItem('token'));
                setData(response);
                setIsLoading(false);
                setIsToReload(false);
            } catch (err: any) {
                setIsLoading(false);
                handleError(err);
            }
        })()
    }, [handleError, isToReload]);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleDelete(event: any) {
        setTourToEdit(event);
        setOpenDialogDelete(true);
    }

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Nom',
            format: (row: any) => row.name,
            searchDefaultValue: (row: any) => row.name,
        },
        {
            label: 'Créé le',
            format: (row: any) => dayjs(row.createdAt).format('DD/MM/YYYY HH:mm'),
            searchDefaultValue: (row: any) => dayjs(row.createdAt).format('DD/MM/YYYY HH:mm'),
        },
        {
            label: 'Modifié le',
            format: (row: any) => dayjs(row.updatedAt).format('DD/MM/YYYY HH:mm'),
            searchDefaultValue: (row: any) => dayjs(row.updatedAt).format('DD/MM/YYYY HH:mm'),
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton onClick={async () => {
                    setTourToEdit(row);
                    setOpenDialog(true);
                }}>
                    <Edit/>
                </IconButton>
                <IconButton onClick={() => {
                    handleDelete(row)
                }}>
                    <Delete/>
                </IconButton>
            </Box>
        }
    ], [])

    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>
                    Planning Template
                </Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app/settings')}
                    >
                        Paramétre
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Tournée
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                <Button variant="contained" color="primary" startIcon={<Add/>}
                        onClick={handleOpenDialog}>
                    Créer une tournée
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataTable isLoading={isLoading} isError={false} data={data ? data : []} columns={columns}/>
            </Grid>
        </Grid>
        <DialogTour open={openDialog} onClose={(isToReload: boolean) => {
            setOpenDialog(false);
            setTourToEdit(null)
            setIsToReload(isToReload);
        }} dataToEdit={tourToEdit}/>
        <DialogConfirmationDelete url={
            `${process.env.REACT_APP_API_URL}/api/tour/${tourToEdit ? tourToEdit.id : ''}`
        } open={openDialogDelete} onClose={(isToReload: boolean) => {
            setOpenDialogDelete(false);
            setTourToEdit(null)
            setIsToReload(isToReload);
        }}/>
    </Box>
}