import {
    Avatar,
    Badge,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, ListItem, ListItemAvatar, ListItemText,
    TextField,
    Typography
} from "@mui/material";
import {Alert, LoadingButton} from "@mui/lab";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {useSnackBar} from "../../tools/context/SnackBar";
import React, {useState} from "react";
import axios from "axios";
import AutocompleteUrl from "../../tools/input/AutocompleteUrl";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {PickersDay} from "@mui/x-date-pickers";
import {TextFieldTheme} from "../../../themes/themes";

export default function DialogHolidaysCreate({open, onClose}: any) {
    const handleError = useHandleError();
    const msg = useSnackBar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [user, setUser] = useState(null);
    const [reason, setReason] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [comment, setComment] = useState(null);
    const [dateError, setDateError] = useState<boolean>(false);

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setDateError(false)
        setUser(null)
        setReason(null)
        setStart(null)
        setEnd(null)
        setComment(null)
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            if (start === null || end === null) {
                setDateError(true)
                return
            }

            setIsLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}/api/company/holidays`, {
                user,
                reason,
                start,
                end,
                comment,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            msg('Congé cree avec succès !', 'success');
            setIsLoading(false);
            handleClose(true)
        } catch (err) {
            setIsLoading(false)
            handleError(err)
        }
    }

    return <Dialog open={open} onClose={() => {
        handleClose(false)
    }} component={'form'} onSubmit={handleSubmit} fullWidth maxWidth={'sm'}>
        <DialogTitle>
            Cree un conges
        </DialogTitle>
        <DialogContent>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'}
                                color={'text.primary'}>Utilisateurs</Typography>
                    <AutocompleteUrl
                        url={`${process.env.REACT_APP_API_URL}/api/users`}
                        value={reason}
                        required
                        onChange={(value: any | undefined) => {
                            setUser(value)
                        }}
                        optionLabel={(option: any) => option.firstName + ' ' + option.lastName}
                        renderOption={(option: any) => <ListItem sx={{p: 0}}>
                            <ListItemAvatar>
                                <Avatar src={option.avatar}/>
                            </ListItemAvatar>
                            <ListItemText primary={option.firstName + ' ' + option.lastName}
                                          secondary={option.tag}/>
                        </ListItem>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'}
                                color={'text.primary'}>Raison</Typography>
                    <AutocompleteUrl
                        url={`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/reason`}
                        value={reason}
                        required
                        onChange={(value: any | undefined) => {
                            setReason(value)
                        }}
                        optionLabel={(option: any) => option.reason}
                        renderOption={(option: any) => option.reason}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Date de
                        début</Typography>
                    <DatePicker
                        value={start}
                        onChange={(date: any) => {
                            setStart(date)
                        }}
                        minDate={dayjs()}
                        sx={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Date de
                        reprise</Typography>
                    <DatePicker
                        disabled={start === null}
                        value={end}
                        onChange={(date: any) => {
                            setEnd(date)
                        }}
                        minDate={
                            dayjs(start)
                        }
                        sx={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    {dateError && <Grid item xs={12}>
                        <Alert severity={'warning'}>
                            Vous date ne sont pas conforme
                        </Alert>
                    </Grid>}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'}
                                color={'text.primary'}>Commentaire</Typography>
                    <TextField
                        value={comment}
                        onChange={(e: any) => {
                            setComment(e.target.value)
                        }}
                        fullWidth variant={'outlined'} multiline rows={6}
                        placeholder={'Ajouter plus de précision'} sx={{mt: 0.5, ...TextFieldTheme}}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                handleClose(false)
            }}>Annuler</Button>
            <LoadingButton type={'submit'} loading={isLoading} variant={'contained'}>
                Sauvegarder
            </LoadingButton>
        </DialogActions>
    </Dialog>
}