import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Grid,
    IconButton,
    Link,
    ListItem,
    ListItemIcon, ListItemText,
    Typography
} from "@mui/material";
import {Add, Block, Delete, RemoveCircle, Visibility} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import DataTable, {ColumnProps} from "../../../components/tools/display/DataTable";
import {useCallback, useEffect, useMemo, useState} from "react";
import {userRoles} from "../../../lib/translate/roles";
import DialogConfirmationDelete from "../../../components/tools/dialog/DialogConfirmationDelete";
import DialogBanUser from "../../../components/workers/dialog/DialogBanUser";
import axios from "axios";
import {useSnackBar} from "../../../components/tools/context/SnackBar";
import {useOffice} from "../../../components/tools/context/Office";

interface WorkersProps {
    isOfficeMode?: boolean;
}

export default function Workers({isOfficeMode}: WorkersProps): JSX.Element {
    const navigate = useNavigate();
    const handleError = useHandleError()
    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
    const [openBanDialog, setOpenBanDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const msg = useSnackBar();
    const office = useOffice();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users${isOfficeMode ? `?officeId=${office.id}` : ''}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setData(response.data);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setIsError(true);
                handleError(err);
            }
        })()
    }, [handleError, office, isOfficeMode]);

    function getUserStatus(deleted: boolean, banned: boolean): JSX.Element {
        if (deleted) {
            return <Chip label={'Supprimé'} color={'error'} sx={{borderRadius: 1}}/>
        }
        if (banned) {
            return <Chip label={'Banni'} color={'warning'} sx={{borderRadius: 1}}/>
        }
        return <Chip label={'Actif'} color={'success'} sx={{borderRadius: 1}}/>
    }

    function getUserStatusLabel(deleted: boolean, banned: boolean): string {
        if (deleted) {
            return 'Supprimé'
        }
        if (banned) {
            return 'Banni'
        }
        return 'Actif'
    }

    const unbanUser = useCallback(async (userId: string) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/${userId}/unban`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Utilisateur débanni avec succès', 'success');
            window.location.reload();
        } catch (err: any) {
            handleError(err);
            return false;
        }
    }, [msg, handleError]);


    const columns: ColumnProps[] = useMemo(() => [
        /* {
            label: 'Identifiant',
            format: (row: any) => row.id
        }, */
        {
            label: 'Nom',
            format: (row: any) => <ListItem sx={{p: 0}}>
                <ListItemIcon>
                    <Avatar/>
                </ListItemIcon>
                <ListItemText primary={row.firstName + " " + row.lastName} secondary={row.email}/>
            </ListItem>,
            searchDefaultValue: (row: any) => row.firstName + " " + row.lastName
        },
        {
            label: 'Téléphone',
            format: (row: any) => row.phoneNumber ? row.phoneNumber : '-',
            searchDefaultValue: (row: any) => row.phoneNumber ? row.phoneNumber : '-',
        },
        {
            label: 'Bureaux',
            format: (row: any) => <Typography
                style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                variant={'body2'}>{row.offices.map((office: any) => office.name).join(', ')}</Typography>,
            searchDefaultValue: (row: any) => row.offices.map((office: any) => office.name).join(', ')
        },
        {
            label: 'Tag',
            format: (row: any) => row.tag,
            searchDefaultValue: (row: any) => row.tag
        },
        {
            label: 'Role',
            format: (row: any) => userRoles(row.role),
            searchDefaultValue: (row: any) => userRoles(row.role)
        },
        {
            label: 'Status',
            format: (row: any) => getUserStatus(row.deleted, row.banned),
            searchDefaultValue: (row: any) => getUserStatusLabel(row.deleted, row.banned)
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton onClick={() => navigate('/app/workers/' + row.id)}>
                    <Visibility/>
                </IconButton>
                <IconButton disabled={row.deleted} onClick={async () => {
                    if (row.banned) {
                        await unbanUser(row.id)
                    } else {
                        setIdToDelete(row.id)
                        setOpenBanDialog(true)
                    }
                }}>
                    {row.banned ? <Block/> : <RemoveCircle/>}
                </IconButton>
                <IconButton disabled={row.deleted} onClick={() => {
                    setIdToDelete(row.id)
                    setOpenDialogDelete(true)
                }}>
                    <Delete/>
                </IconButton>
            </Box>
        }
    ], [navigate, unbanUser])

    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Mes employés {isOfficeMode && `au bureau ${office.name}`}</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        key="2"
                        color="text.primary"
                    >
                        Mes employés
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                <Button variant="contained" color="primary" startIcon={<Add/>}
                        onClick={() => navigate(window.location.pathname + '/new')}>
                    Créer un employé
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataTable isLoading={isLoading} isError={isError} data={data ? data : []} columns={columns}
                           targetLabel={'Status'}/>
            </Grid>
        </Grid>
        <DialogConfirmationDelete open={openDialogDelete} onClose={(isToReload: boolean) => {
            setOpenDialogDelete(false);
            if (isToReload) {
                window.location.reload();
            }
        }} url={`${process.env.REACT_APP_API_URL}/api/users/${idToDelete}`}/>
        <DialogBanUser open={openBanDialog} onClose={(isToReload: boolean) => {
            setOpenBanDialog(false);
            if (isToReload) {
                window.location.reload();
            }
        }} userId={idToDelete}/>
    </Box>
}