import {
    Box,
    styled, useMediaQuery
} from "@mui/material";
import React, {useState} from "react";
import DrawerSettings from "../../components/settings/DrawerSettings";
import User from "../../components/tools/context/User";
import AppBarDashboard from "../../components/appbar";
import DrawerDashboard from "../../components/drawer";
import {Navigate, Route} from "react-router-dom";
import EmbedRoutes from "../../components/tools/router/EmbedRoutes";
import Offices from "./offices/page";
import Stretch from "../../components/tools/theme/Stretch";
import OfficeById from "./offices/byId";
import Workers from "./workers/page";
import CreateNewUser from "./workers/new";
import UserById from "./workers/byId";
import Office from "../../components/tools/context/Office";
import SettingsHolidays from "./settings/holidays";
import PageHolidayWorker from "./holidays/pageWorker";
import HolidaysById from "./holidays/byId";
import CheckHolidays from "./holidays/checkHolidays";
import DrawerNotifications from "../../components/notifications/DrawerNotifications";
import Calendar from "./calendar/page";
import AdminCalendar from "./calendar/adminCalendar";
import EventTemplate from "./settings/eventTemplate";
import PlanningTemplate from "./settings/planningTemplate";
import DailyForm from "./dailyForm/page";
import DailyFormById from "./dailyForm/byId";
import Dashboard from "./dashboard/page";
import Tour from "./settings/tour";
import Analyze from "./analyse/page";
import News from "./news/page";
import FilesManagerPage from "./filesManager/page";
import MobilePage from "./mobile/page";

export const drawerWidth: number = 240;

export const UIDrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function App(): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [openSettings, setOpenSettings] = useState<boolean>(false);
    const [openNotifications, setOpenNotifications] = useState<boolean>(false);
    const [selectedOffice, setSelectedOffice] = useState<any>(null);
    const [isConnection, setIsConnection] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    function handleSelectOffice(office: any) {
        // localStorage.setItem('selectedOffice', office.id);
        setSelectedOffice(office);
    }

    if (isMobile) {
        return <Box height={'100vh'} width={'100vw'} display={'flex'} sx={{bgcolor: 'background.paper'}}>
            <Office office={selectedOffice}>
                <User setSelectedOffice={setSelectedOffice} setIsConnected={setIsConnection}>
                    {isConnection && <AppBarDashboard open={open} setOpen={setOpen} setOpenSettings={setOpenSettings}
                                                      handleSelectOffice={handleSelectOffice}
                                                      selectedOffice={selectedOffice}
                                                      setOpenNotifications={setOpenNotifications}
                                                      openNotifications={openNotifications}/>}
                    {isConnection && <Box component="main" sx={{flexGrow: 1, p: 2, mt: 8}}>
                        <EmbedRoutes>
                            <Route path={'/'} element={<Navigate to={"/app/dashboard"}/>}/>
                            <Route path={'/dashboard'} element={
                                <Dashboard/>
                            }/>
                            <Route path={'/calendar'} element={
                                <Calendar/>
                            }/>
                            <Route path={'/holidays'} element={
                                <PageHolidayWorker/>
                            }/>
                            <Route path={'/holidays/:id'} element={
                                <HolidaysById/>
                            }/>
                            <Route path={'/news'} element={
                                <News/>
                            }/>
                            <Route path={'/dailyForm'} element={
                                <DailyForm/>
                            }/>
                            <Route path={'/dailyForm/:id'} element={
                                <DailyFormById/>
                            }/>
                        </EmbedRoutes>
                    </Box>}
                    {isConnection && <MobilePage/>}
                </User>
            </Office>
        </Box>
    }

    return <Box height={'100vh'} width={'100vw'} display={'flex'} sx={{bgcolor: 'background.paper'}}>
        <Office office={selectedOffice}>
            <User setSelectedOffice={setSelectedOffice} setIsConnected={setIsConnection}>
                {isConnection && <AppBarDashboard open={open} setOpen={setOpen} setOpenSettings={setOpenSettings}
                                                  handleSelectOffice={handleSelectOffice}
                                                  selectedOffice={selectedOffice}
                                                  setOpenNotifications={setOpenNotifications}
                                                  openNotifications={openNotifications}/>}
                {isConnection && <DrawerDashboard open={open}/>}
                {isConnection && <Box component="main" sx={{flexGrow: 1, px: 3, pt: 3}}>
                    <UIDrawerHeader/>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100% - 64px)',
                        width: '100%',
                        overflow: 'auto'
                    }}>
                        <EmbedRoutes>
                            <Route path={'/'} element={<Navigate to={"/app/dashboard"}/>}/>
                            <Route path={'/dashboard'} element={
                                <Dashboard/>
                            }/>
                            <Route path={'/calendar'} element={
                                <Stretch>
                                    <Calendar/>
                                </Stretch>
                            }/>
                            <Route path={'/holidays'} element={
                                <Stretch>
                                    <PageHolidayWorker/>
                                </Stretch>
                            }/>
                            <Route path={'/holiday-approval'} element={
                                <Stretch>
                                    <CheckHolidays/>
                                </Stretch>
                            }/>
                            <Route path={'/holidays/:id'} element={
                                <Stretch>
                                    <HolidaysById/>
                                </Stretch>
                            }/>
                            <Route path={'/offices'} element={
                                <Stretch>
                                    <Offices/>
                                </Stretch>
                            }/>
                            <Route path={'/files'} element={
                                <Stretch>
                                    <FilesManagerPage/>
                                </Stretch>
                            }/>
                            <Route path={'/offices/holiday-approval'} element={
                                <Stretch>
                                    <CheckHolidays isOfficeMode/>
                                </Stretch>
                            }/>
                            <Route path={'/offices/workers'} element={
                                <Stretch>
                                    <Workers isOfficeMode/>
                                </Stretch>
                            }/>
                            <Route path={'/offices/planning'} element={
                                <Stretch>
                                    <AdminCalendar isOfficeMode/>
                                </Stretch>
                            }/>
                            <Route path={'/offices/workers/new'} element={
                                <Stretch>
                                    <CreateNewUser isOfficeMode/>
                                </Stretch>
                            }/>
                            <Route path={'/offices/:id'} element={
                                <Stretch>
                                    <OfficeById/>
                                </Stretch>
                            }/>
                            <Route path={'/workers'} element={
                                <Stretch>
                                    <Workers/>
                                </Stretch>
                            }/>
                            <Route path={'/workers/new'} element={
                                <Stretch>
                                    <CreateNewUser/>
                                </Stretch>
                            }/>
                            <Route path={'/workers/:id'} element={
                                <Stretch>
                                    <UserById/>
                                </Stretch>
                            }/>
                            <Route path={'/settings/holidays'} element={
                                <Stretch>
                                    <SettingsHolidays/>
                                </Stretch>
                            }/>
                            <Route path={'/settings/events'} element={
                                <Stretch>
                                    <EventTemplate/>
                                </Stretch>
                            }/>
                            <Route path={'/settings/planning'} element={
                                <Stretch>
                                    <PlanningTemplate/>
                                </Stretch>
                            }/>
                            <Route path={'/settings/tour'} element={
                                <Stretch>
                                    <Tour/>
                                </Stretch>
                            }/>
                            <Route path={'/planning'} element={
                                <Stretch>
                                    <AdminCalendar/>
                                </Stretch>
                            }/>
                            <Route path={'/dailyForm'} element={
                                <Stretch>
                                    <DailyForm/>
                                </Stretch>
                            }/>
                            <Route path={'/dailyForm/:id'} element={
                                <Stretch>
                                    <DailyFormById/>
                                </Stretch>
                            }/>
                            <Route path={'/analyze'} element={
                                <Stretch>
                                    <Analyze/>
                                </Stretch>
                            }/>
                            <Route path={'/news'} element={
                                <Stretch>
                                    <News/>
                                </Stretch>
                            }/>
                        </EmbedRoutes>
                    </Box>
                </Box>}
                {isConnection && <DrawerSettings open={openSettings} onClose={() => setOpenSettings(false)}/>}
                {isConnection &&
                    <DrawerNotifications open={openNotifications} onClose={() => setOpenNotifications(false)}/>}
            </User>
        </Office>
    </Box>;
}