import React, {createRef, useEffect, useState} from "react";
import {PaperTheme, useCalendarStyle} from "../../../themes/themes";
import {
    Avatar,
    Box,
    Breadcrumbs, Button, Chip, CircularProgress,
    Grid,
    Link,
    ListItem,
    ListItemAvatar, ListItemText,
    Paper,
    Typography
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {useNavigate} from "react-router-dom";
import DialogEvent, {ActionType} from "../../../components/calendar/dialog/DialogEvent";
import {useSnackBar} from "../../../components/tools/context/SnackBar";
import CalendarEvent from "../../../components/calendar/CalendarEvent";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import axios from "axios";
import MultiAutocompleteUrl from "../../../components/tools/input/MultiAutocompleteUrl";
import {getEndOfWeek, getStartOfWeek} from "../../../lib/planning/week";
import {useOffice} from "../../../components/tools/context/Office";
import {Add} from "@mui/icons-material";
import DialogImportPlanning from "../../../components/calendar/dialog/DialogImportPlanning";

interface AdminCalendarProps {
    isOfficeMode?: boolean;
}

export default function AdminCalendar({isOfficeMode = false}: AdminCalendarProps): JSX.Element {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const calendarRef = createRef<any>();
    const calendarStyle = useCalendarStyle();
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<any>(null);
    const [events, setEvents] = useState<any[]>([]);
    const [offices, setOffices] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [range, setRange] = useState<{ start: string, end: string }>({
        start: getStartOfWeek(),
        end: getEndOfWeek()
    }); // [start, end]
    const msg = useSnackBar();
    const handleError = useHandleError();
    const office = useOffice()
    const [openDialogImportPlanning, setOpenDialogImportPlanning] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                let url = `${process.env.REACT_APP_API_URL}/api/events`;

                if (isOfficeMode) {
                    url += '/office?officeId=' + office.id + '&users=' + users.map((item: any) => item.id + ',');
                } else {
                    url += '?users=' + users.map((item: any) => item.id + ',') + '&offices=' + offices.map((item: any) => item.id + ',');
                }

                url += `&start=${range.start}&end=${range.end}`;


                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                let updatedEvents = response.data.map((e: any) => {
                    return {
                        id: e.id.toString(),
                        title: e.title,
                        start: e.start,
                        end: e.end,
                        data: {
                            notes: e.notes,
                            color: e.color,
                            users: e.users,
                            offices: e.offices
                        }
                    }
                });
                setEvents(updatedEvents);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                handleError(err);
            }
        })()
    }, [handleError, isOfficeMode, office, offices, range, users]);

    function handleDateClick(info: any) {
        const today = new Date();

        if (today > info.date) {
            msg('Vous ne pouvez pas ajouter un événement dans le passé', 'warning')
            return;
        }

        setEvent({
            start: info.dateStr,
            end: info.dateStr,
        });
        setOpen(true);
    }

    function handleClose(type: any, eventData: any | null): void {
        let updatedEvents = [...events];

        if (type === ActionType.ADD) {
            updatedEvents.push(eventData);
        } else if (type === ActionType.DELETE) {
            updatedEvents = updatedEvents.filter((e) => e.id !== eventData.id);
        } else if (type === ActionType.EDIT) {
            const index = updatedEvents.findIndex((e) => e.id === eventData.id);
            if (index !== -1) {
                updatedEvents[index] = eventData;
            }
        }
        setEvents(updatedEvents);
        setOpen(false);
        // setEvent(null)
    }

    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Plannification</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Plannification
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                <Button variant={'contained'} startIcon={<Add/>} color={'primary'} onClick={() => {
                    setOpenDialogImportPlanning(true)
                }}>
                    Import d&apos;un planning
                </Button>
            </Grid>
        </Grid>
        <Paper sx={{...PaperTheme, p: 1.5, mb: 2, mt: 2}}>
            <Grid container item xs={12} spacing={2}>
                {!isOfficeMode && <Grid item xs={6}>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={offices}
                                          label={'Bureaux'}
                                          onChange={(value: any[] | undefined) => setOffices(value ? value : [])}
                                          renderOption={(option: any) => option.name}
                    />
                </Grid>}
                <Grid container item xs={isOfficeMode ? 12 : 6} justifyContent={'end'} alignItems={'center'}>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/users` + (isOfficeMode ? `?officeId=${office.id}` : '')} value={users}
                                          onChange={(value: any[] | undefined) => setUsers(value ? value : [])}
                                          label={'Utilisateurs'}
                                          optionLabel={(option: any) => option.firstName + ' ' + option.lastName}
                                          renderOption={(option: any) => <ListItem sx={{p: 0}}>
                                              <ListItemAvatar>
                                                  <Avatar src={option.avatar}/>
                                              </ListItemAvatar>
                                              <ListItemText primary={option.firstName + ' ' + option.lastName}
                                                            secondary={option.tag}/>
                                              {option.holidays && option.holidays.length > 0 &&
                                                  <Chip label={option.holidays[0].reason}/>}
                                          </ListItem>}
                    />
                </Grid>
            </Grid>

            <Box sx={{ ...calendarStyle, maxHeight: '100%', height: '620px', position: 'relative' }}>
                <Paper variant={'outlined'} sx={{
                    position: 'absolute',
                    zIndex: 100,
                    top: '50%',
                    left: '50%',
                    // display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 10,
                    display: isLoading ? 'flex' : 'none',
                }}>
                    <CircularProgress sx={{mb: 2}}/>
                    Chargement...
                </Paper>
                <FullCalendar
                    datesSet={(dateInfo: any) => {
                        setRange({
                            start: new Date(dateInfo.startStr).toISOString(),
                            end: new Date(dateInfo.endStr).toISOString()
                        })
                    }}
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="timeGridWeek"
                    headerToolbar={{
                        start: 'prev,next today',
                        center: 'title',
                        end: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    events={events}
                    editable={true}
                    locale={'fr'}
                    dateClick={handleDateClick}
                    nowIndicator
                    eventChange={async (info: any) => {
                        try {
                            const today = new Date();
                            let updatedEvents = [...events]; // Créez une nouvelle référence d'objet
                            const target = updatedEvents.find((e) => e.id === info.event._def.publicId);

                            if (!target) {
                                return;
                            }

                            if (today > new Date(info.event.startStr)) {
                                msg('Vous ne pouvez pas modifier un événement dans le passé', 'warning')
                                return;
                            }

                            await axios.put(`${process.env.REACT_APP_API_URL}/api/events/${target.id}`, {
                                title: target.title,
                                start: info.event.startStr,
                                end: info.event.endStr,
                                offices: target.data.offices,
                                users: target.data.users,
                                note: target.data.note,
                                color: target.data.color
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                }
                            })

                            msg('Événement mis à jour', 'success');

                            target.start = info.event.startStr;
                            target.end = info.event.endStr;
                            setEvents(updatedEvents);


                        } catch (err: any) {
                            handleError(err);
                        }

                    }}
                    eventClick={(info: any) => {
                        if (!info.event._def.publicId) {
                            return;
                        }

                        const target = events.find((e) => e.id === info.event._def.publicId);

                        if (!target) {
                            return;
                        }

                        setEvent({...target});
                        setOpen(true);
                    }}
                    height={'100%'}
                    eventContent={(eventInfo: any) => <CalendarEvent key={eventInfo.event._def.publicId}
                                                                     event={events.find((e) => e.id === eventInfo.event._def.publicId)}
                    />}
                />
            </Box>
        </Paper>
        <DialogImportPlanning open={openDialogImportPlanning} onClose={(isToReload: boolean) => {
            if (isToReload) {
                window.location.reload();
            }
            setOpenDialogImportPlanning(false)
        }}/>
        <DialogEvent open={open} onClose={handleClose} event={event} officesFilter={offices} usersFilter={users} isOfficeMode={isOfficeMode}/>
    </Box>
}