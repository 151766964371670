export function userRoles(role: string): string {
    switch (role) {
        case 'SUPER_ADMIN':
            return 'Super Administateur';
        case 'ADMIN':
            return 'Administateur';
        case 'DEV':
            return 'Développeur';
        case 'WORKER':
            return 'Employé';
        default:
            return 'Unknown';
    }
}