import {
    Box,
    Breadcrumbs, Button, Chip, Grid,
    IconButton,
    Link,
    Typography, useMediaQuery
} from "@mui/material";
import React, {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import DataTable, {ColumnProps} from "../../../components/tools/display/DataTable";
import {Add, AddCircleOutline, Delete, Edit} from "@mui/icons-material";
import moment from "moment";
import DialogConfirmationDelete from "../../../components/tools/dialog/DialogConfirmationDelete";
import Notes from "../../../components/tools/display/TableCell/Notes";
import axios from "axios";
import { useHandleError } from "../../../components/tools/context/ErrorHandler";
import {useSnackBar} from "../../../components/tools/context/SnackBar";

export default function PageHolidayWorker() {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/users/holidays`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>('');
    const handleError = useHandleError();
    const msg = useSnackBar();

    function translateStatus(status: string, isDeleted: boolean) {
        if (isDeleted) {
            return 'Supprimé';
        }
        switch (status) {
            case 'PENDING':
                return 'En attente';
            case 'ACCEPTED':
                return 'Accepté';
            case 'REFUSED':
                return 'Refusé';
            case 'CANCELLED':
                return 'Annulé';
            default:
                return 'En attente';
        }
    }

    function translateStatusElement(status: string, isDeleted: boolean): JSX.Element {
        if (isDeleted) {
            return <Chip color={'error'} label={'Supprimé'} sx={{borderRadius: 1}}/>;
        }
        switch (status) {
            case 'PENDING':
                return <Chip color={'info'} label={'En attente'} sx={{borderRadius: 1}}/>;
            case 'ACCEPTED':
                return <Chip color={'success'} label={'Accepté'} sx={{borderRadius: 1}}/>;
            case 'REFUSED':
                return <Chip color={'error'} label={'Refusé'} sx={{borderRadius: 1}}/>;
            case 'CANCELLED':
                return <Chip color={'warning'} label={'Annulé'} sx={{borderRadius: 1}}/>;
            default:
                return <Chip color={'info'} label={'En attente'} sx={{borderRadius: 1}}/>;
        }
    }

    const downloadFile = async (file: string) => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/download/file?file=${file}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            // Créer un lien temporaire
            const link = document.createElement('a');

            // Définir le contenu du lien comme le fichier Base64
            link.href = response.data;

            // Définir le nom du fichier pour le téléchargement
            link.download = file.split('/').pop() || '';

            // Ajouter le lien au document
            document.body.appendChild(link);

            // Simuler un clic sur le lien pour lancer le téléchargement
            link.click();

            // Supprimer le lien du document
            document.body.removeChild(link);
            msg('Fichier téléchargé avec succès', 'success');
        } catch (err) {
            handleError(err);
        }
    };

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Reason',
            format: (row: any) => row.reason.reason,
            searchDefaultValue: (row: any) => row.reason.reason
        },
        {
            label: 'Date de début',
            format: (row: any) => moment(row.start).format("DD/MM/YYYY"),
            searchDefaultValue: (row: any) => moment(row.start).format("DD/MM/YYYY"),
        },
        {
            label: 'Date de fin',
            format: (row: any) => moment(row.end).format("DD/MM/YYYY"),
            searchDefaultValue: (row: any) => moment(row.end).format("DD/MM/YYYY"),
        },
        {
            label: 'Commentaire',
            format: (row: any) => row.comment ? <Notes notes={row.comment}/> : <em>Aucun commentaire renseigné.</em>,
        },
        {
            label: 'Commentaire de refus',
            format: (row: any) => row.refuseComment ? <Notes notes={row.refuseComment}/> :
                <em>Aucun commentaire de refus renseigné.</em>,
        },
        {
            label: 'Fichier',
            format: (row: any) => row.file ? <Link target={'_blank'}
                                                   onClick={() => downloadFile(row.file)}
                >{row.file.split('/').pop() || ''}</Link> :
                <em>-</em>,
        },
        {
            label: 'Status',
            format: (row: any) => translateStatusElement(row.status, row.deleted),
            searchDefaultValue: (row: any) => translateStatus(row.status, row.deleted)
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                {/* <IconButton onClick={() => navigate('/app/holidays/' + row.id)}>
                    <Edit/>
                </IconButton> */}
                <IconButton disabled={row.deleted} onClick={() => {
                    setIdToDelete(row.id);
                    setOpenDialogDelete(true);
                }}>
                    <Delete/>
                </IconButton>
            </Box>
        }
    ], [])


    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2} pb={10}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Mes congés</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Congés
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                {isMobile ? <IconButton size={'large'} color="primary"
                                        onClick={() => navigate(window.location.pathname + '/new')}>
                    <AddCircleOutline fontSize={'large'}/>
                </IconButton> : <Button variant="contained" color="primary" startIcon={<Add/>}
                                        onClick={() => navigate(window.location.pathname + '/new')}>
                    Demande de congés
                </Button>}
            </Grid>
            <Grid item xs={12}>
                <DataTable isLoading={isLoading}
                           isError={error}
                           data={data ? data : []}
                           columns={columns}
                           mobileItem={(row: any) => <Grid container item xs={12} spacing={2}>
                               <Grid item xs={12}>
                                   <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Demande de
                                       congés</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body1'} color={'text.primary'}>{row.reason ? row.reason.reason : ''}</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   {row.start && row.end && <Typography variant={'body1'}
                                                color={'text.primary'}>Du {moment(row.start).format("DD/MM/YYYY")} au {moment(row.end).format("DD/MM/YYYY")}</Typography>}
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body1'}
                                               color={'text.primary'}>{row.comment ? row.comment : 'Aucun commentaire'}</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body1'}
                                               color={'text.primary'}>{row.refuseComment ? row.refuseComment : 'Aucun commentaire de refus'}</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body1'}
                                               color={'text.primary'}>{translateStatus(row.status, row.deleted)}</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Box>
                                       <IconButton onClick={() => navigate('/dashboard/holidays/' + row.id)}>
                                           <Edit/>
                                       </IconButton>
                                       <IconButton disabled={row.deleted} onClick={() => {
                                           setIdToDelete(row.id);
                                           setOpenDialogDelete(true);
                                       }}>
                                           <Delete/>
                                       </IconButton>
                                   </Box>
                               </Grid>
                           </Grid>}
                           targetLabel={'Status'}/>
            </Grid>
            <DialogConfirmationDelete open={openDialogDelete} onClose={(isToReload: boolean) => {
                setOpenDialogDelete(false);
                setIdToDelete('');
                if (isToReload) {
                    window.location.reload();
                }
            }} url={`${process.env.REACT_APP_API_URL}/api/users/holidays/${idToDelete}`}/>
        </Grid>
    </Box>
}