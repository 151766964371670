import React from "react";

const OfficeContext: React.Context<any> = React.createContext(null);

export default function Office({children, office}: {
    children: React.ReactNode,
    office: any
}): JSX.Element {
    return <OfficeContext.Provider value={office}>
        {children}
    </OfficeContext.Provider>
}

export const useOffice = () => React.useContext(OfficeContext);