import {Box, TableCell, Typography} from "@mui/material";
import Notes from "../../tools/display/TableCell/Notes";
import {useState} from "react";
import DialogEventImportPlanning from "./DailogEventImportPlanning";

interface CellPlanningProps {
    day: any;
    selectedUser: any;
    dataTemplate: any;
    setDataTemplate: any;
}

export default function CellPlanning({day, selectedUser, dataTemplate, setDataTemplate}: CellPlanningProps) {
    const [openDialog, setOpenDialog] = useState(false);

    return <TableCell sx={{
        borderRight: 1,
        borderColor: 'divider',
        p: 0
    }}>
        <Box width={'150px'} display={'flex'} justifyContent={'space-between'}
             alignItems={'center'} sx={{
            '&:hover': {
                background: '#f0f0f0',
                cursor: 'pointer'
            },
            p: 1
        }} onClick={() => {
            if (selectedUser) {
                if (day.users.find((item: any) => item.id === selectedUser.id)) {
                    day.users = day.users.filter((item: any) => item.id !== selectedUser.id);
                } else {
                    day.users.push(selectedUser);
                }
                setDataTemplate([...dataTemplate]);
            } else {
                setOpenDialog(true);
            }
        }}>
            <Box>
                <Typography variant={'body2'} fontStyle={day.users.length > 0 ? 'normal' : 'italic'}
                            color={day.users.length > 0 ? 'text.primary' : 'text.secondary'}>
                    {day.users.length > 0 ? day.users.map((user: any) => user.firstName + ' ' + user.lastName).join(', ') : 'Aucun utilisateur'}
                </Typography>
                <Typography variant={'caption'} color={'text.secondary'}>
                    {day.startTime && day.endTime ? day.startTime.format('HH:mm') + ' - ' + day.endTime.format('HH:mm') : 'Aucune heure'}
                </Typography>
            </Box>
            <Notes notes={day.notes} size={'small'}/>
        </Box>
        <DialogEventImportPlanning open={openDialog} onClose={() => setOpenDialog(false)} data={day} setDataTemplate={
            (data: any) => {
                setDataTemplate(data);
            }
        } dataTemplate={dataTemplate}/>
    </TableCell>
}