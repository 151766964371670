interface FileInputProps {
    value: {
        name: string;
        data: string;
    } | null,
    disabled?: boolean;
    onChange: (value: {
        name: string;
        data: string;
    }) => void;
}

export default function FileInput({value, onChange, disabled = false}: FileInputProps): JSX.Element {
    return (
        <div>
            <input
                disabled={disabled}
                type={'file'}
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            if (e.target) {
                                onChange({
                                    name: file.name,
                                    data: e.target.result as string
                                });
                            }
                        }
                        reader.readAsDataURL(file);
                    }
                }}
            />
        </div>
    )
}