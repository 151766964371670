import {
    Box,
    Breadcrumbs, FormControl, FormControlLabel, FormLabel,
    Grid,
    Link,
    Paper, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {PaperTheme, TextFieldTheme} from "../../../themes/themes";
import {LoadingButton} from "@mui/lab";
import TimeDuration from "../../../components/tools/input/TimeDuration";
import {useState} from "react";
import getTotalsHour from "../../../lib/time/getTotalsHour";
import AutocompleteUrl from "../../../components/tools/input/AutocompleteUrl";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import {useSnackBar} from "../../../components/tools/context/SnackBar";
import axios from "axios";

export default function DailyFormById() {
    const navigate = useNavigate();
    const [timeDriving, setTimeDriving] = useState<any>('0:0');
    const [timeWork, setTimeWork] = useState<any>('0:0');
    const [timeRest, setTimeRest] = useState<any>('0:0');
    const [isLoading, setIsLoading] = useState(false);
    const handleError = useHandleError();
    const params = useParams();
    const [plateNumber, setPlateNumber] = useState<string | null>(null);
    const [comment, setComment] = useState<string | null>('');
    const [officeStart, setOfficeStart] = useState<any>(null);
    const [officeEnd, setOfficeEnd] = useState<any>(null);
    const [tour, setTour] = useState<any | null>(null);
    const [extraWork, setExtraWork] = useState<boolean>(false);
    const [extraWorkTime, setExtraWorkTime] = useState<any>('0:0');
    const msg = useSnackBar();

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setIsLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/dailyForms/${params.id}`, {
                plateNumber,
                officeStart,
                officeEnd,
                timeDriving,
                timeWork,
                timeRest,
                comment,
                tour,
                extraWork,
                extraWorkTime
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Formulaire journalier enregistré avec succès', 'success');
            navigate('/app/dailyForm');
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false)
            handleError(err);
        }
    }


    return <Box width={'100%'} height={'100%'} component={'form'} onSubmit={handleSubmit}>
        <Grid container item xs={12} pb={10}>
            <Grid item xs={12} sx={{mb: 4}}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Mon formulaire</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app/dailyForm')}
                    >
                        Formulaires journaliers
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        {dayjs().format('DD/MM/YYYY')}
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={5}>
                <Typography variant={'h6'} color={'text.primary'}>Mon service de la journee</Typography>
                <Typography variant={'caption'} color={'action.disabled'}>
                    Informations sur la prise de service numero 1
                </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
                <Paper sx={{...PaperTheme}}>
                    <Grid container item md={12} spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Numero d'immatriculation du vehicule utiliser
                            </Typography>
                            <TextField sx={{...TextFieldTheme}} required placeholder={
                                'Exemple: 1234 AB 01'
                            } variant={'outlined'} fullWidth value={
                                plateNumber
                            } onChange={(event: any) => {
                                setPlateNumber(event.target.value);
                            }}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Lieu de prise de service
                            </Typography>
                            <AutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={officeStart}
                                             onChange={(value: any | undefined) => {
                                                 setOfficeStart(value)
                                             }} required
                                             renderOption={(option: any) => option.name}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Lieu de cessation de service
                            </Typography>
                            <AutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={officeEnd}
                                             onChange={(value: any | undefined) => {
                                                 setOfficeEnd(value)
                                             }} required
                                             renderOption={(option: any) => option.name}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Tournée
                            </Typography>
                            <AutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/tour`} value={tour}
                                             onChange={(value: any | undefined) => {
                                                 setTour(value)
                                             }} required
                                             renderOption={(option: any) => option.name}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Temps de conduite
                            </Typography>
                            <TimeDuration value={
                                timeDriving
                            } onChange={(value: any) => {
                                setTimeDriving(value);
                            }}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Temps de travail manuelle
                            </Typography>
                            <TimeDuration value={
                                timeWork
                            } onChange={(value: any) => {
                                setTimeWork(value);
                            }}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography variant={'body2'} color={'text.primary'}>
                                Total des heures de travail: {getTotalsHour(
                                [timeDriving, timeWork]
                            ).split(':')[0]} heures et {getTotalsHour(
                                [timeDriving, timeWork]
                            ).split(':')[1]} minutes
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Temps de repos
                            </Typography>
                            <TimeDuration value={
                                timeRest
                            } onChange={(value: any) => {
                                setTimeRest(value);
                            }}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl >
                                <FormLabel>
                                    Avez-vous effectué un nettoyage ou entretien technique du vehicule ?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={extraWork}
                                    onChange={
                                        (_: any) => {
                                            setExtraWork(!extraWork);
                                        }
                                    }
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {extraWork && <Grid item md={6} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Temps de travail supplementaire
                            </Typography>
                            <TimeDuration value={
                                extraWorkTime
                            } onChange={(value: any) => {
                                setExtraWorkTime(value);
                            }}/>
                        </Grid>}
                        <Grid item md={12} xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                                Commentaire
                            </Typography>
                            <TextField sx={{...TextFieldTheme}} rows={4} multiline variant={'outlined'}
                                       fullWidth value={
                                comment
                            } onChange={
                                (event: any) => {
                                    setComment(event.target.value);
                                }
                            }/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid container item xs={12} justifyContent={'end'} sx={{mt: 3}}>
                <LoadingButton loading={isLoading} size={'large'} type={'submit'} variant="contained" color="primary">
                    Enregistrer
                </LoadingButton>
            </Grid>
        </Grid>
    </Box>
}