import {Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Circle, ExpandLess, ExpandMore} from "@mui/icons-material";

interface ListItemCollapseProps {
    title: string;
    url: string;
    icon: JSX.Element;
    drawerOpen: boolean;
    subItems?: { title: string, url: string }[];
}

export default function ListItemCollapse({title, url, icon, drawerOpen, subItems}: ListItemCollapseProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return <>
        <ListItem disablePadding button sx={{
            minHeight: 48,
            justifyContent: drawerOpen ? 'initial' : 'center',
            px: 2.5,
            py: 1,
            borderRadius: 2,
            '&:hover': {
                borderRadius: 2,
            }
        }} onClick={(event: any) => {
            if (subItems) {
                if (drawerOpen) {
                    setOpen(!open);
                    return;
                } else {
                    handleClick(event);
                    return;
                }
            }
            navigate("/app" + url)
        }}>
            <ListItemIcon sx={{
                minWidth: 0,
                mr: drawerOpen ? 3 : 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: drawerOpen ? 'row' : 'column',
            }}>
                {icon}
                <Typography sx={{ml: drawerOpen ? 2 : 0}} variant={'body2'}>{title}</Typography>
                {subItems && drawerOpen && (open ? <ExpandLess sx={{ml: 8}} fontSize={'small'}/> : <ExpandMore sx={{ml: 8}} fontSize={'small'}/>)}
            </ListItemIcon>
        </ListItem>
        <Collapse in={open && drawerOpen} timeout={'auto'} unmountOnExit>
            <List component="div" disablePadding dense>
                {subItems?.map((item, index) => <ListItemButton key={`${item.title}-${index}-subitems`} sx={{
                    minHeight: 30,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    py: 0,
                    '&:hover': {
                        borderRadius: 2,
                    }
                }} onClick={() => {
                    navigate("/app" + item.url)
                }}>
                    <ListItemIcon sx={{
                        minWidth: 0,
                        mr: 3,
                        ml: 1,
                        justifyContent: 'center',
                    }}>
                        <Circle sx={{fontSize: 8}}/>
                    </ListItemIcon>
                    <ListItemText primary={item.title}/>
                </ListItemButton>)}
            </List>
        </Collapse>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    width: 170,
                    borderRadius: 2,
                    boxShadow: 4,
                    marginLeft: 1,
                    overflow: 'hidden',
                    p: 1,
                }
            }}
        >
            <List component="div" disablePadding dense>
                {subItems?.map((item, index) => <ListItemButton key={`${item.title}-${index}-subitems`} sx={{
                    minHeight: 30,
                    justifyContent: open ? 'initial' : 'center',
                    px: 1.5,
                    py: 0,
                    '&:hover': {
                        borderRadius: 2,
                    }
                }} onClick={() => {
                    navigate("/app" + item.url)
                }}>
                    <ListItemText primary={item.title}/>
                </ListItemButton>)}
            </List>
        </Popover>
    </>

}