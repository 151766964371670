import {Divider, List, ListItem, ListItemText, Popover, Typography} from "@mui/material";
import {useUser} from "../tools/context/User";

interface PopoverProfileProps {
    anchorEl: Element | null;
    handleClose: () => void;
}

export default function PopoverProfile({anchorEl, handleClose}: PopoverProfileProps): JSX.Element {
    const user = useUser();

    return <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        sx={{
            '& .MuiPopover-paper': {
                width: 220,
                borderRadius: 2,
                boxShadow: 4,
                marginTop: 1,
                overflow: 'hidden',
            }
        }}
    >
        {user && <ListItemText primary={`${user.lastName} ${user.firstName}`} secondary={user.email} sx={{mx: 2, my: 1}}/>}
        <Divider/>
        <List sx={{p: 1}} component="div" disablePadding>
            <ListItem button disablePadding sx={{
                px: 1,
                borderRadius: 2,
                '&:hover': {
                    borderRadius: 2,
                }
            }}>
                <ListItemText primary={'Profile'}/>
            </ListItem>
            <ListItem button disablePadding sx={{
                px: 1,
                borderRadius: 2,
                '&:hover': {
                    borderRadius: 2,
                }
            }}>
                <ListItemText primary={'Paramètre'}/>
            </ListItem>
        </List>
        <Divider/>
        <List sx={{px: 1}}>
            <ListItem button onClick={() => {
                localStorage.removeItem('token');
                window.location.href = "/login";
            }} disablePadding sx={{
                px: 1,
                borderRadius: 2,
                '&:hover': {
                    borderRadius: 2,
                }
            }}>
                <ListItemText primary={<Typography sx={{color: 'red'}}>Logout</Typography>}/>
            </ListItem>
        </List>
    </Popover>
}