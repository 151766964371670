import {
    Avatar,
    Box, Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, Icon, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText,
    MenuItem, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import MultiAutocompleteUrl from "../../tools/input/MultiAutocompleteUrl";
import {TimePicker} from "@mui/x-date-pickers";
import {TextFieldTheme} from "../../../themes/themes";
import axios from "axios";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {useSnackBar} from "../../tools/context/SnackBar";
import {Close, Search} from "@mui/icons-material";
import AddLinkIcon from '@mui/icons-material/AddLink';
import Notes from "../../tools/display/TableCell/Notes";
import DialogEventImportPlanning from "./DailogEventImportPlanning";
import CellPlanning from "./CellPlanning";

interface DialogImportPlanningProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
}

function translateEnDayToFrDay(day: string) {
    switch (day) {
        case 'Monday':
            return 'Lundi';
        case 'Tuesday':
            return 'Mardi';
        case 'Wednesday':
            return 'Mercredi';
        case 'Thursday':
            return 'Jeudi';
        case 'Friday':
            return 'Vendredi';
        case 'Saturday':
            return 'Samedi';
        case 'Sunday':
            return 'Dimanche';
    }
}

function getAllDatesBetween(startDate: any, endDate: any) {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isBefore(dayjs(endDate)) || currentDate.isSame(dayjs(endDate))) {
        dates.push({
            date: currentDate,
            dayOfWeek: translateEnDayToFrDay(currentDate.format('dddd'))
        });
        currentDate = currentDate.add(1, 'day');
    }

    return dates;
}

function getUpcomingWeeks() {
    const currentDate = dayjs();
    const currentWeekDay = currentDate.day(); // Dimanche: 0, Lundi: 1, ..., Samedi: 6
    const daysUntilNextMonday = (7 - currentWeekDay) % 7; // Nombre de jours jusqu'à Lundi
    const nextMondayDate = currentDate.add(daysUntilNextMonday, 'day').startOf('day');

    const upcomingWeeks = [];

    for (let i = 0; i < 10; i++) {
        const weekStartDate = nextMondayDate.add(i * 7, 'day').startOf('week');
        const weekEndDate = weekStartDate.add(6, 'day').endOf('day');

        const week = {
            // weekNumber: weekStartDate,
            startDate: weekStartDate.add(1, 'day'),
            endDate: weekEndDate.add(1, 'day')
        };

        upcomingWeeks.push(week);
    }

    return upcomingWeeks;
}

export default function DialogImportPlanning({open, onClose}: DialogImportPlanningProps) {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/events/templatePlanning`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const [week, setWeek] = useState<any>(`${getUpcomingWeeks()[0].startDate} - ${getUpcomingWeeks()[0].endDate}`);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [dataTemplate, setDataTemplate] = useState<any[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [users, setUsers] = useState<any[]>([]);
    const [search, setSearch] = useState<string>('');
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dataToDialog, setDataToDialog] = useState<any>(null);
    const handleError = useHandleError()
    const msg = useSnackBar();

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setSelectedTemplate(null);
        setWeek(`${getUpcomingWeeks()[0].startDate} - ${getUpcomingWeeks()[0].endDate}`);
        setDataTemplate([]);
    }

    useEffect(() => {
        if (!open)
            return;
        (async () => {
            try {
                setIsLoadingData(true)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/import?week=${week}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setUsers(response.data);
                setFilteredUsers(response.data);
                setSearch('');
                setIsLoadingData(false)
            } catch (err: any) {
                setIsLoadingData(false)
                handleError(err);
            }
        })();
    }, [handleError, open, week]);

    async function handleSubmit(event: any) {
        event.preventDefault();
        let events = [];
        for (const item of dataTemplate) {
            for (const day of item.data) {
                if (day.users.length === 0 || !day.startTime || !day.endTime) {
                    continue;
                }

                events.push({
                    title: item.title,
                    start: dayjs(day.date.format('YYYY-MM-DD') + 'T' + day.startTime.format('HH:mm:ss')),
                    end: dayjs(day.date.format('YYYY-MM-DD') + 'T' + day.endTime.format('HH:mm:ss')),
                    users: day.users,
                    color: item.color,
                    notes: day.notes
                })
            }
        }

        try {
            setIsSubmitting(true)
            await axios.post(`${process.env.REACT_APP_API_URL}/api/events/import`, {
                name: data.find((item: any) => item.id === selectedTemplate).title,
                week,
                dataTemplate,
                events: events
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Planning importé avec succès', 'success')
            handleClose(true)
            setIsSubmitting(false)
        } catch (err: any) {
            setIsSubmitting(false)
            handleError(err);
        }

    }

    return <Dialog open={open} onClose={() => handleClose(false)} fullScreen component={'form'}
                   onSubmit={handleSubmit}>
        <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant={'h6'}>Import Planning</Typography>
            <IconButton onClick={() => handleClose(false)}>
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={3}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                        Selectionner la semaine
                    </Typography>
                    <FormControl size={'small'} fullWidth>
                        <Select
                            value={week}
                            onChange={(event: any) => {
                                setWeek(event.target.value);

                                dataTemplate.map((item: any) => {
                                    item.data = item.data.map((row: any, index: number) => ({
                                        ...getAllDatesBetween(event.target.value.split(' - ')[0], event.target.value.split(' - ')[1])[index],
                                        title: row.title,
                                        users: row.users,
                                        startTime: row.startTime,
                                        endTime: row.endTime,
                                        notes: row.notes
                                    }))
                                })

                                setDataTemplate([...dataTemplate]);
                            }}
                        >
                            {
                                getUpcomingWeeks().map((item, index) => <MenuItem
                                    key={index} value={
                                    `${item.startDate} - ${item.endDate}`
                                }>
                                    {item.startDate.format('DD/MM/YYYY')} - {item.endDate.format('DD/MM/YYYY')}
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                        Selectionner une template
                    </Typography>
                    <FormControl size={'small'} fullWidth>
                        <Select
                            value={selectedTemplate}
                            onChange={(event: any) => {
                                const target = data.find((item: any) => item.id === event.target.value);

                                if (!target) {
                                    return;
                                }
                                console.log(target.data)
                                setDataTemplate(target.data.map((item: any) => ({
                                    ...item,
                                    data: getAllDatesBetween(week.split(' - ')[0], week.split(' - ')[1]).map((date: any) => ({
                                        title: item.title,
                                        ...date,
                                        users: [],
                                        notes: item.notes,
                                        startTime: item.startTime ? dayjs(item.startTime) : null,
                                        endTime: item.endTime ? dayjs(item.endTime) : null
                                    }))
                                })))

                                setSelectedTemplate(event.target.value)
                            }}
                        >
                            {
                                data && data.map((item: any) => <MenuItem
                                    key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={7} alignItems={'center'} spacing={2} mt={1}>
                    <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 5,
                                background: 'red',
                                mr: 1
                            }}/>
                            <Typography variant={'body2'} color={'text.primary'}>
                                Pas disponible
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 5,
                                background: 'blue',
                                mr: 1
                            }}/>
                            <Typography variant={'body2'} color={'text.primary'}>
                                En congé
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 5,
                                background: 'green',
                                mr: 1
                            }}/>
                            <Typography variant={'body2'} color={'text.primary'}>
                                Dispobible
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 5,
                                background: 'yellowgreen',
                                mr: 1
                            }}/>
                            <Typography variant={'body2'} color={'text.primary'}>
                                Dispobible le matin
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                borderRadius: 5,
                                background: 'orange',
                                mr: 1
                            }}/>
                            <Typography variant={'body2'} color={'text.primary'}>
                                Dispobible l&apos;après-midi
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={2.3}>
                        <TextField value={search} onChange={
                            (event) => {
                                setSearch(event.target.value);
                                setFilteredUsers(users.filter((item: any) => item.firstName.toLowerCase().includes(event.target.value.toLowerCase()) || item.lastName.toLowerCase().includes(event.target.value.toLowerCase())))
                            }
                        } variant={'outlined'} size={'small'} placeholder={'Rechercher'}
                                   InputProps={{
                                       startAdornment: <InputAdornment position={'start'}>
                                           <Search fontSize={'small'}/>
                                       </InputAdornment>
                                   }}
                                   fullWidth/>
                        <List dense sx={{
                            maxHeight: 'calc(100vh - 270px)',
                            overflow: 'auto'
                        }}>
                            {
                                isLoadingData ? <Typography variant={'body2'} color={'text.secondary'}>
                                    Chargement...
                                </Typography> : filteredUsers.map((item: any, index: number) => <ListItem button
                                                                                                          onClick={
                                                                                                              () => {
                                                                                                                  if (selectedUser === item)
                                                                                                                      setSelectedUser(null)
                                                                                                                  else
                                                                                                                      setSelectedUser(item)
                                                                                                              }
                                                                                                          } key={index}
                                                                                                          sx={{
                                                                                                              display: 'flex',
                                                                                                              flexDirection: 'column',
                                                                                                              alignItems: 'flex-start',
                                                                                                              borderRight: selectedUser === item ? 3 : 0,
                                                                                                              borderColor: selectedUser === item ? 'secondary.main' : 'transparent',
                                                                                                              transition: 'border-color 0.5s'
                                                                                                          }}
                                >
                                    <Box display={'flex'} alignItems={'center'}>
                                        <ListItemAvatar>
                                            <Avatar src={item.avatar} sx={{
                                                width: 30,
                                                height: 30
                                            }}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.firstName + ' ' + item.lastName}/>
                                    </Box>

                                    <Typography variant={'caption'}>
                                        {item.availability.map((row: any, index: number) => {
                                            return <>
                                            <span style={{
                                                color: row.status
                                            }}>{row.dayName}</span>{index + 1 !== item.availability.length ? ` | ` : ''}
                                            </>
                                        })}
                                    </Typography>
                                </ListItem>)
                            }
                        </List>
                    </Grid>
                    <Grid item xs={9.7}>
                        <TableContainer component={Paper} variant={'outlined'}>
                            <Table size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        {getAllDatesBetween(week.split(' - ')[0], week.split(' - ')[1]).map((item: any, index: number) =>
                                            <TableCell key={index} align={'center'}>
                                                {item.dayOfWeek}
                                            </TableCell>)}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        {getAllDatesBetween(week.split(' - ')[0], week.split(' - ')[1]).map((item: any, index: number) =>
                                            <TableCell key={index} align={'center'}>
                                                {item.date.format('DD/MM/YYYY')}
                                            </TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataTemplate && dataTemplate.map((item, index) => <TableRow key={index}>
                                        <TableCell sx={{
                                            borderRight: 1,
                                            borderColor: 'divider'
                                        }}>
                                            <Box width={'100px'} display={'flex'} alignItems={'center'}>
                                                <Box sx={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 5,
                                                    background: item.color,
                                                    mr: 1
                                                }}/>
                                                {item.title}
                                            </Box>
                                        </TableCell>
                                        {item.data && item.data.map((day: any, index: number) => <CellPlanning
                                            key={index}
                                            day={day}
                                            selectedUser={selectedUser}
                                            dataTemplate={dataTemplate}
                                            setDataTemplate={setDataTemplate}
                                        />)}
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(false)}>
                Annuler
            </Button>
            <LoadingButton loading={isSubmitting} variant={'contained'} color={'primary'} disabled={
                selectedTemplate === null
            } type={'submit'}>
                Importer
            </LoadingButton>
        </DialogActions>
    </Dialog>
}