import {PaperTheme} from "../../themes/themes";
import {Paper, Typography} from "@mui/material";

interface CardStatsProps {
    label: string;
    data: any;
}

export default function CardStats({label, data}: CardStatsProps) {
    return <Paper variant={'outlined'} sx={{...PaperTheme, boxShadow: 0}}>
        <Typography variant={'body2'} sx={{mb: 1}}>
            {label}
        </Typography>
        <Typography variant={'h5'} fontWeight={'bold'}>
            {data}
        </Typography>
    </Paper>
}