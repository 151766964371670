import {Drawer, IconButton, Typography, Box, Divider, Button, Grid} from "@mui/material";
import {Circle, Close} from "@mui/icons-material";
import {useContext} from "react";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import {useTheme} from "@mui/material/styles";
import {ColorModeContext} from "../../App";

interface DrawerSettingsProps {
    open: boolean;
    onClose?: () => void;
}

export default function DrawerSettings({open, onClose}: DrawerSettingsProps): JSX.Element {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const listColors: string[] = [
        'rgb(0, 167, 111)',
        'rgb(7, 141, 238)',
        'rgb(118, 53, 220)',
        'rgb(32, 101, 209)',
        'rgb(253, 169, 45)',
        'rgb(255, 48, 48)'
    ];

    return <Drawer
        anchor={"right"}
        open={open}
        onClose={onClose}
        sx={{
            width: 280,
            flexShrink: 0,
            '& .MuiBackdrop-root': {
                bgcolor: 'transparent',
            },
            '& .MuiDrawer-paper': {
                width: 280,
                boxSizing: 'border-box',
                boxShadow: 2,
            },
        }}
    >
        <Box py={2} pl={3} pr={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>Settings</Typography>
            <IconButton onClick={onClose}>
                <Close/>
            </IconButton>
        </Box>
        <Divider/>
        <Box p={3}>
            <Typography variant={'body2'} sx={{mb: 1}}>Mode</Typography>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Button disabled={theme.palette.mode === "light"} variant={'outlined'}
                        onClick={() => colorMode.toggleColorMode('light', theme.palette.secondary.main)}
                        sx={{width: '45%'}}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}
                         justifyContent={'space-evenly'} height={80} width={'100%'}>
                        <WbSunnyIcon/>
                        Light
                    </Box>
                </Button>
                <Button disabled={theme.palette.mode === "dark"} variant={'outlined'}
                        onClick={() => colorMode.toggleColorMode('dark', theme.palette.secondary.main)}
                        sx={{width: '45%'}}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}
                         justifyContent={'space-evenly'} height={80} width={'100%'}>
                        <NightsStayIcon/>
                        Dark
                    </Box>
                </Button>
            </Box>
        </Box>
        <Box p={3}>
            <Typography variant={'body2'} sx={{mb: 1}}>Presets</Typography>
            <Grid container item xs={12}>
                {listColors.map((color: string, index: number) => <Grid key={`${index}-settings-presets-color`} item
                                                                        xs={4} height={56}>
                    <Button sx={{height: '100%', bgcolor: 'secondary'}} fullWidth
                            onClick={() => colorMode.toggleColorMode(theme.palette.mode, color)}>
                        <Circle sx={{color}} fontSize={theme.palette.secondary.main === color ? 'large' : 'small'}/>
                    </Button>
                </Grid>)}
            </Grid>
        </Box>
    </Drawer>
}