import {ThemeOptions} from "@mui/material";
import {useTheme} from "@mui/material/styles";

function rgbToRgba(rgbString: string, a: string): string | undefined {
    // Supprimer les espaces et les parenthèses
    rgbString = rgbString.replace(/ /g, '').replace('rgb', '').replace('(', '').replace(')', '');

    // Diviser les valeurs en un tableau
    const rgbArray = rgbString.split(',');

    // Vérifier si le format est correct (3 valeurs RGB)
    if (rgbArray.length !== 3) {
        console.error('Le format de la chaîne RGB est incorrect.');
        return;
    }

    // Convertir les valeurs en nombres
    const r = parseInt(rgbArray[0]);
    const g = parseInt(rgbArray[1]);
    const b = parseInt(rgbArray[2]);

    // Vérifier si les valeurs sont valides (entre 0 et 255)
    if (isNaN(r) || isNaN(g) || isNaN(b) || r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
        console.error('Les valeurs RGB sont invalides.');
        return;
    }

    // Retourner la chaîne RGBA avec une valeur alpha de 0.25
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export default function GenerateTheme(mode: 'light' | 'dark', secondaryColor: string = ''): ThemeOptions {
    return {
        palette: {
            mode,
            background: {
                default: mode === 'light' ? 'rgba(245, 245, 245, 1)' : 'rgba(22, 28, 36, 0.94)',
            },
            primary: {
                main: mode === 'light' ? 'rgb(33, 43, 54)' : 'rgb(255, 255, 255)',
            },
            secondary: {
                main: secondaryColor,
                light: rgbToRgba(secondaryColor, '0.10'),
            }
        },
    }
}

export const TextFieldTheme = {
    '& .MuiOutlinedInput-root': {
        borderRadius: 2
    }
}

export const PaperTheme = {
    p: 3,
    borderRadius: 2,
    boxShadow: 4
}

export const PaperMobileTheme = {
    p: 2,
    borderRadius: 2,
    boxShadow: 4
}

export function useCalendarStyle() {
    const theme = useTheme();

    return {
        minHeight: '100%',
        '.fc .fc-daygrid-day.fc-day-today, .fc-day-today, .fc-day-today .fc-timegrid-now-indicator-container': {
            bgcolor: theme.palette.grey[100]
        },

        '.fc-button': {
            bgcolor: `${theme.palette.primary.main}!important`,
            color: `${theme.palette.primary.contrastText}!important`,
            border: '1px!important',
            borderRightColorColor: `black!important`,
            boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important',
            textTransform: 'uppercase!important',
            marginRight: '8px!important',
            borderRadius: '4px!important',
            padding: '6px 16px!important',
            height: '36.5px!important'
        },
        '.fc-button:active': {
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)!important',
        },

        '.fc-button:hover': {
            bgcolor: `${theme.palette.primary.dark}!important`,
        },

        '.fc .fc-toolbar.fc-header-toolbar': {
            pt: 1,
            pl: 1,
            pr: 1,
            marginBottom: '5px!important'
        },
        '.fc-daygrid': {
            p: 1
        },
        '.fc-toolbar-title': {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
        },

        '.fc-day-today .fc-daygrid-day-number': {
            fontWeight: 'bold'
        },

        '.fc-event': {
            // borderRadius: '8px!important',
            padding: '0px!important',
            border: '0px!important',
            backgroundColor: 'transparent!important',
        },
    }
}
