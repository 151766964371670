import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useSnackBar} from "../../tools/context/SnackBar";
import {useState} from "react";
import {useHandleError} from "../../tools/context/ErrorHandler";
import axios from "axios";
import {TextFieldTheme} from "../../../themes/themes";

interface DialogBanUserProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    userId: string | null;
}

export default function DialogBanUser({open, onClose, userId}: DialogBanUserProps) {
    const [reason, setReason] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const msg = useSnackBar();
    const handleError = useHandleError();

    async function handleSubmit(event: any) {
        event.preventDefault()
        try {
            setIsLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/${userId}/ban`, {
                reason
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Utilisateur banni avec succès', 'success');
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth={'sm'} component={'form'} onSubmit={handleSubmit}>
            <DialogTitle>Bannir un utilisateur</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Etes-vous sûr de vouloir bannir cet utilisateur ?
                </DialogContentText>
                <Typography variant={'body1'} sx={{mt: 2}}>Raison du bannissement</Typography>
                <TextField required value={reason} onChange={(e) => setReason(e.target.value)}
                           fullWidth multiline rows={5} sx={{...TextFieldTheme}}/>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type={'submit'}
                    loading={isLoading}
                    variant="contained"
                    color="error"
                >
                    Bannir
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}