import {
    Badge,
    Box,
    Breadcrumbs,
    Button, Divider,
    Grid,
    Link,
    Paper,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {PaperMobileTheme, PaperTheme, TextFieldTheme} from "../../../themes/themes";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import {useSnackBar} from "../../../components/tools/context/SnackBar";
import {Alert, LoadingButton} from "@mui/lab";
import dayjs from "dayjs";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import {PickersDay} from "@mui/x-date-pickers";
import FileInput from "../../../components/tools/input/FileInput";
import AutocompleteUrl from "../../../components/tools/input/AutocompleteUrl";

export default function HolidaysById(): JSX.Element {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/calendar`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [holidays, setHolidays] = useState<any[]>([{
        reason: null,
        start: null,
        end: null,
        comment: null,
        file: null
    }]);
    const handleError = useHandleError();
    const msg = useSnackBar();

    function handleAddHoliday() {
        setHolidays([...holidays, {
            reason: '',
            start: null,
            end: null,
            comment: null,
            file: null
        }])
    }

    function handleDeleteHoliday(index: number) {
        holidays.splice(index, 1);
        setHolidays([...holidays])
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setIsSubmiting(true);
            let isAnError = false;

            for (let i = 0; i < holidays.length; i++) {
                if (!holidays[i].reason) {
                    return msg('Vous devez renseigner un motif pour chaque souhait de congé.', 'warning');
                }
                if (!holidays[i].start) {
                    holidays[i].error = 'Vous devez renseigner une date de début pour chaque souhait de congé.';
                    isAnError = true;
                }
                if (!holidays[i].end) {
                    holidays[i].error = 'Vous devez renseigner une date de fin pour chaque souhait de congé.';
                    isAnError = true;
                }
            }

            if (isAnError) {
                setHolidays([...holidays]);
                setIsSubmiting(false);
                return msg('Information manquante.', 'warning');
            }


            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/holidays`, {
                holidays: holidays
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Votre demande de congé a bien été enregistrée', 'success');
            navigate('/app/holidays');
            setIsSubmiting(false);
        } catch (error) {
            setIsSubmiting(false);
            handleError(error);
        }
    }

    function getAllDateAvailable(start: any) {
        let newAvailable: any[] = [];
        let nextDay = dayjs(start).add(1, 'day');

        const startIndex = data.slotAvailable.findIndex((slot: any) => dayjs(slot.date).isSame(dayjs(start), 'day'));

        if (startIndex === -1) {
            return newAvailable;
        }

        for (let i = startIndex + 1; i < data.slotAvailable.length; i++) {
            if (!dayjs(data.slotAvailable[i].date).isSame(dayjs(nextDay), 'day')) {
                break;
            }
            nextDay = dayjs(nextDay).add(1, 'day');
            newAvailable.push(data.slotAvailable[i]);
        }

        return newAvailable
    }


    return <Box width={'100%'} height={'100%'} component={'form'} onSubmit={handleSubmit}>
        <Grid container item xs={12} spacing={2} pb={10}>
            <Grid item xs={12}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Demande de congés</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover" color="inherit" onClick={() => navigate('/app/holidays')}
                    >
                        Congés
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Demande de congés
                    </Link>
                </Breadcrumbs>
            </Grid>
            {isLoading && <Grid item xs={12}>
                <Typography variant={'body1'} color={'text.primary'}>Chargement...</Typography>
            </Grid>}
            {error && <Grid item xs={12}>
                <Typography variant={'body1'} color={'text.primary'}>Erreur de chargement...</Typography>
            </Grid>}
            <Grid item xs={12}>
                <Alert severity={'info'}>
                    Vous pouvez ajouter plusieurs souhaits de congés en une seule fois. Si vous ne pouvez pas rensigner
                    une date, c'est que le mois en question ne peut pas être sélectionné pour le moment. Ou qu'il n'y a
                    plus de place pour la date en question.
                </Alert>
            </Grid>
            {!isLoading && !error && data && holidays.map((holiday, index) => (
                <React.Fragment key={index + '-new'}>
                    <Grid item xs={12} lg={5}>
                        <Typography variant={'h6'} color={'text.primary'}>Souhait numéro {index + 1}</Typography>
                        <Typography variant={'caption'} color={'action.disabled'}>Raison, date, documents,
                            ...</Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Paper sx={isMobile ? {...PaperMobileTheme} : {...PaperTheme}}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={'body2'} fontWeight={'bold'}
                                                color={'text.primary'}>Raison</Typography>
                                    <AutocompleteUrl
                                        url={`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/reason`}
                                        value={holiday.reason}
                                        onChange={(value: any | undefined) => {
                                            holidays[index].reason = value;
                                            setHolidays([...holidays])
                                        }}
                                        optionLabel={(option: any) => option.reason}
                                        renderOption={(option: any) => option.reason}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Date de
                                        début</Typography>
                                    <DatePicker
                                        value={holiday.start}
                                        onChange={(date) => {
                                            holidays[index].start = date;
                                            setHolidays([...holidays])
                                        }}
                                        minDate={dayjs()}
                                        maxDate={
                                            dayjs(data.maxDate)
                                        }
                                        sx={{width: '100%'}}
                                        slots={{
                                            day: (props: any) => {
                                                if (holiday.reason?.needSlots === false) {
                                                    return <PickersDay {...props}/>
                                                }

                                                const isInvisible = dayjs(props.day).isBefore(dayjs(), 'day') ||
                                                    dayjs(props.day).isAfter(dayjs(data.maxDate), 'day') || props.disabled || props.outsideCurrentMonth;

                                                const find = data.slotAvailable.find((slot: any) => dayjs(slot.date).isSame(dayjs(props.day), 'day'));

                                                return (
                                                    <Badge
                                                        key={props.day.toString()}
                                                        color="secondary" variant="dot"
                                                        invisible={isInvisible ||
                                                            find === undefined || find === null
                                                        }
                                                        sx={{
                                                            '& .MuiBadge-dot': {
                                                                width: 8,
                                                                height: 8,
                                                                position: 'absolute',
                                                                top: 5,
                                                                right: 7,
                                                            },
                                                        }}
                                                    >
                                                        <PickersDay {...props} disabled={isInvisible ||
                                                            find === undefined || find === null
                                                        }/>
                                                    </Badge>
                                                );
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Date de
                                        reprise</Typography>
                                    <DatePicker
                                        disabled={!holiday.start}
                                        value={holiday.end}
                                        onChange={(date) => {
                                            holidays[index].end = date;
                                            setHolidays([...holidays])
                                        }}
                                        minDate={
                                            dayjs(holiday.start)
                                        }
                                        maxDate={
                                            dayjs(data.maxDate)
                                        }
                                        sx={{width: '100%'}}
                                        slots={{
                                            day: (props: any) => {
                                                if (holidays[index].reason && holidays[index].reason.needSlots === false) {
                                                    return <PickersDay {...props}/>
                                                }

                                                const isInvisible = dayjs(props.day).isBefore(dayjs(), 'day') ||
                                                    dayjs(props.day).isAfter(dayjs(data.maxDate), 'day') || props.disabled || props.outsideCurrentMonth;

                                                const find = getAllDateAvailable(holiday.start).find((slot: any) => dayjs(slot.date).isSame(dayjs(props.day), 'day'));

                                                return (
                                                    <Badge
                                                        key={props.day.toString()}
                                                        color="secondary" variant="dot"
                                                        invisible={isInvisible ||
                                                            find === undefined || find === null
                                                        }
                                                        sx={{
                                                            '& .MuiBadge-dot': {
                                                                width: 8,
                                                                height: 8,
                                                                position: 'absolute',
                                                                top: 5,
                                                                right: 7,
                                                            },
                                                        }}
                                                    >
                                                        <PickersDay {...props} disabled={isInvisible ||
                                                            find === undefined || find === null
                                                        }/>
                                                    </Badge>
                                                );
                                            },
                                        }}
                                    />
                                </Grid>
                                {holiday.error && <Grid item xs={12}>
                                    <Alert severity={'warning'}>
                                        {holiday.error}
                                    </Alert>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Typography variant={'body2'} fontWeight={'bold'}
                                                color={'text.primary'}>Commentaire</Typography>
                                    <TextField
                                        value={holiday.comment}
                                        onChange={(e) => {
                                            holidays[index].comment = e.target.value;
                                            setHolidays([...holidays])
                                        }}
                                        fullWidth variant={'outlined'} multiline rows={6}
                                        placeholder={'Ajouter plus de précision'} sx={{mt: 0.5, ...TextFieldTheme}}
                                    />
                                </Grid>
                                {holiday.reason?.needDocument &&
                                    <Grid item xs={12}>
                                        <Typography variant={'body2'} fontWeight={'bold'}
                                                    color={'text.primary'}>Documents justificatif</Typography>
                                        <FileInput value={
                                            holiday.file
                                        } onChange={(value) => {
                                            holidays[index].file = value;
                                            setHolidays([...holidays])
                                        }}/>
                                    </Grid>}
                            </Grid>
                        </Paper>
                    </Grid>
                    {holidays.length > 1 && <Grid item xs={12}>
                        <Button
                            size={"small"}
                            variant={'outlined'}
                            color={'error'}
                            fullWidth
                            onClick={() => handleDeleteHoliday(index)}
                            sx={{borderRadius: 4}}
                        >
                            Supprimer ce souhait
                        </Button>
                    </Grid>}
                    <Grid item xs={12}>
                        <Divider variant="middle"/>
                    </Grid>
                </React.Fragment>
            ))}
            <Grid item xs={12}>
                <Button
                    size={"small"}
                    variant={'outlined'}
                    fullWidth
                    onClick={handleAddHoliday}
                    sx={{borderColor: 'divider', borderRadius: 4, color: 'grey', mb: 4}}
                >
                    Ajouter un souhait
                </Button>
            </Grid>
            <Grid container item xs={12} justifyContent={'end'} alignItems={'center'}>
                <LoadingButton loading={isLoading || isSubmiting} variant={'contained'} fullWidth={isMobile}
                               type={"submit"}
                               color={'primary'} sx={{mb: 8}}>
                    Sauvegarder
                </LoadingButton>
            </Grid>
        </Grid>
    </Box>
}