export default function getTotalsHour(durations: string[] = []): string {
    let totalHours = 0;
    let totalMinutes = 0;

    // Parcourir chaque durée dans la liste
    durations.forEach((duration) => {
        if (duration && duration.includes(':') && duration.split(':').length === 2) {
            const [hoursStr, minutesStr] = duration.split(':');
            const hours = parseInt(hoursStr);
            const minutes = parseInt(minutesStr);

            // Ajouter les heures et les minutes à la somme totale
            totalHours += hours;
            totalMinutes += minutes;
        }
    });

    // Gérer les minutes dépassant 60
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;

    // Formater le résultat
    const formattedHours = String(totalHours).padStart(2, '0');
    const formattedMinutes = String(totalMinutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
}