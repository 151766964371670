import {useEffect, useState} from "react";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import axios from "axios";

interface DialogReasonProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    dataToEdit?: any;
}

export default function DialogReason({open, onClose, dataToEdit = null}: DialogReasonProps): JSX.Element {
    const [reason, setReason] = useState<string>('');
    const [needFreeSlot, setNeedFreeSlot] = useState<boolean>(false);
    const [needDocument, setNeedDocument] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const handleError = useHandleError();

    useEffect(() => {
        if (dataToEdit) {
            setReason(dataToEdit.reason);
            setNeedFreeSlot(dataToEdit.needSlots);
            setNeedDocument(dataToEdit.needDocument);
        }
    }, [dataToEdit]);

    async function onSubmit(e: any) {
        e.preventDefault();
        try {
            setLoading(true);
            if (dataToEdit) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/reason/${dataToEdit.id}`, {
                    reason,
                    needSlots: needFreeSlot,
                    needDocument
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else
                await axios.post(`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/reason`, {
                    reason,
                    needSlots: needFreeSlot,
                    needDocument
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            handleClose(true);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    }

    function handleClose(isToReload: boolean): void {
        setReason('');
        setNeedDocument(false);
        setNeedFreeSlot(false);
        onClose(isToReload);
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            fullWidth
            maxWidth={'sm'}
            component={'form'}
            onSubmit={onSubmit}
        >
            <DialogTitle>
                Nouvelle raison
            </DialogTitle>
            <DialogContent>
                <Grid container item xs={12} sx={{mt: 0}} spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            label="Raison"
                            type="text"
                            fullWidth
                            required
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={needFreeSlot} onClick={
                                () => setNeedFreeSlot(!needFreeSlot)
                            }/>} label="Besoin d'un slot libre"/>
                            <FormControlLabel control={<Checkbox checked={
                                needDocument
                            } onClick={() => setNeedDocument(!needDocument)
                            }/>} label="Besoin d'un document justificatif"/>
                        </FormGroup>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <LoadingButton loading={loading} type={'submit'} color="primary" variant={'contained'}>
                    Sauvegarder
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}