import React from 'react';
import EmbedRoutes from "./components/tools/router/EmbedRoutes";
import {Navigate, Route} from "react-router-dom";
import Login from "./pages/login/page";
import Dashboard from "./pages/app/page";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import GenerateTheme from "./themes/themes";
import SnackBar from "./components/tools/context/SnackBar";
import ErrorHandler from "./components/tools/context/ErrorHandler";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {frFR} from '@mui/x-date-pickers/locales';

export const ColorModeContext = React.createContext({
    toggleColorMode: (prevMode: 'light' | 'dark', color: string = '') => {
    }
});

function App(): JSX.Element {
    const [mode, setMode] = React.useState<'light' | 'dark'>(localStorage.getItem('theme') as 'light' | 'dark' || 'light');
    const [secondaryColor, setSecondaryColor] = React.useState<string>(localStorage.getItem('secondaryColor') || 'rgb(0, 167, 111)');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: (prevMode: 'light' | 'dark', color: string = '') => {
                setSecondaryColor(color)
                setMode(prevMode)
                localStorage.setItem('secondaryColor', color)
                localStorage.setItem('theme', prevMode)
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () => {
            return createTheme(GenerateTheme(mode, secondaryColor));
        },
        [mode, secondaryColor],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                    frFR.components.MuiLocalizationProvider.defaultProps.localeText
                }
            >
                <ThemeProvider theme={theme}>
                    <SnackBar>
                        <ErrorHandler>
                            <EmbedRoutes>
                                <Route path={'/'} element={<Navigate to={"/login"}/>}/>
                                <Route path={'/login'} element={<Login/>}/>
                                <Route path={'/app/*'} element={<Dashboard/>}/>
                            </EmbedRoutes>
                        </ErrorHandler>
                    </SnackBar>
                </ThemeProvider>
            </LocalizationProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
