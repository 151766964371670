import {
    Avatar,
    Box,
    Chip,
    FormControl,
    IconButton, ListItemText,
    MenuItem,
    Paper,
    Select,
    styled,
    Toolbar,
    useMediaQuery
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Menu, NotificationsActive, People, Search, Settings} from "@mui/icons-material";
import PopoverProfile from "./PopoverProfile";
import {useUser} from "../tools/context/User";
import React, {useState} from "react";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import MuiAppBar from "@mui/material/AppBar";
import {drawerWidth} from "../../pages/app/page";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface AppBarDashboardProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    setOpenSettings: (open: boolean) => void;
    selectedOffice: any;
    handleSelectOffice: (office: any) => void;
    openNotifications: any;
    setOpenNotifications: any;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'transparent',
    width: `calc(100% - ${theme.spacing(7)} - 27px)`,
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const AppBarMobile = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'transparent',
    width: '100%',
    ...(open && {
        marginLeft: 0,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function AppBarDashboard({
                                            open,
                                            setOpen,
                                            setOpenSettings,
                                            selectedOffice,
                                            handleSelectOffice,
                                            openNotifications,
                                            setOpenNotifications
                                        }: AppBarDashboardProps) {
    const user = useUser();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [anchorElPopoverProfile, setAnchorElPopoverProfile] = useState<HTMLButtonElement | null>(null);

    function handleClosePopoverProfile() {
        setAnchorElPopoverProfile(null);
    }

    function handleOpenPopoverProfile(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorElPopoverProfile(event.currentTarget);
    }

    if (isMobile)
        return <AppBarMobile position="fixed" open={open} elevation={1}>
            <Toolbar sx={{display: 'flex', justifyContent: 'start', background: 'white'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                    <IconButton size={'small'} onClick={handleOpenPopoverProfile}>
                        {user && <Avatar
                            alt={user.firstName + " " + user.lastName}
                            sx={{width: 38, height: 38, mr: 1}}
                        />}
                    </IconButton>
                    <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.tag} sx={{
                        fontSize: 12,
                        color: 'black',
                        fontWeight: 'bold',
                        mr: 1,
                    }}/>
                    <PopoverProfile anchorEl={anchorElPopoverProfile} handleClose={handleClosePopoverProfile}/>
                </Box>
            </Toolbar>
        </AppBarMobile>

    return <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Paper variant={'outlined'} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: 50,
                    position: 'relative',
                    left: '-40px',
                    zIndex: 2000,
                }}>
                    <IconButton
                        size={'small'}
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                    >
                        {!open ? <ChevronRightIcon fontSize={'small'} color={'primary'}/> :
                            <ChevronLeftIcon fontSize={'small'} color={'primary'}/>}
                    </IconButton>
                </Paper>
                <IconButton
                    size={'small'}
                >
                    <Search fontSize={'small'} color={'primary'}/>
                </IconButton>
                <Chip color={'primary'} size={'small'} label={'⌘K'} sx={{borderRadius: 1, fontSize: 11}}/>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                {user && user.role !== "WORKER" && user.offices.length > 1 &&
                    <FormControl fullWidth size={'small'} sx={{
                        width: 150,
                        mr: 1,
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                            height: 30,
                        },
                        '& .MuiSelect-select': {
                            fontSize: 12,
                        },
                    }}>
                        <Select onChange={(event: any) => {
                            const office = user.offices.find((office: any) => office.id === event.target.value);
                            handleSelectOffice(office);
                        }} value={selectedOffice ? selectedOffice.id : undefined}
                                defaultValue={user && user.offices[0].id}>
                            {user && user.offices.length > 0 && user.offices.map((office: any) => <MenuItem
                                key={office.id}
                                value={office.id}
                                sx={{fontSize: 12}}>
                                {office.name}
                            </MenuItem>)}
                        </Select>
                    </FormControl>}
                <IconButton
                    size={'small'}
                >
                    <People fontSize={'small'} color={'primary'}/>
                </IconButton>
                <IconButton
                    size={'small'}
                    onClick={() => setOpenNotifications(!openNotifications)}
                >
                    <NotificationsActive fontSize={'small'} color={'primary'}/>
                </IconButton>
                <IconButton onClick={() => setOpenSettings(true)} size={'small'} color={'primary'}>
                    <Settings fontSize={'small'}/>
                </IconButton>
                <IconButton size={'small'} onClick={handleOpenPopoverProfile}>
                    {user && <Avatar
                        alt={user.firstName + " " + user.lastName}
                        sx={{width: 24, height: 24}}
                    />}
                </IconButton>
                <PopoverProfile anchorEl={anchorElPopoverProfile} handleClose={handleClosePopoverProfile}/>
            </Box>
        </Toolbar>
    </AppBar>

}