import {
    Box,
    Breadcrumbs,
    Button,
    Grid, IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import {Add, Search} from "@mui/icons-material";
import CardOffice from "../../../components/offices/CardOffice";
import LoadingCard from "../../../components/tools/feedback/LoadingCard";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import {Alert} from "@mui/lab";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import {TextFieldTheme} from "../../../themes/themes";

export default function Offices(): JSX.Element {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/offices`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const navigate = useNavigate();
    const handleError = useHandleError();
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    function getFilteredData() {
        if (searchQuery && searchQuery.length) {
            return data.filter((office: any) => office.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        return data;
    }

    if (error) {
        handleError(error);
    }

    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Mes bureaux</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        key="2"
                        color="text.primary"
                    >
                        Mes bureaux
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                {isMobile ? <IconButton color="primary"
                                    onClick={() => navigate(window.location.pathname + '/new')}>
                    <Add/>
                </IconButton> : <Button variant="contained" color="primary" startIcon={<Add/>}
                                     onClick={() => navigate(window.location.pathname + '/new')}>
                    Ajouter un bureau
                </Button>}
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    type={"search"}
                    placeholder={"Rechercher un bureau"}
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{...TextFieldTheme}}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <Search/>
                        </InputAdornment>
                    }}
                />
            </Grid>
            <Grid container item xs={12} spacing={2} sx={{mb: 4}}>
                {isLoading && <Grid item xs={12}>
                    <LoadingCard/>
                </Grid>}
                {error && <Grid item xs={12}>
                    <Alert severity="error">Impossible de charger les bureaux</Alert>
                </Grid>}
                {data && getFilteredData().map((office: any) => <Grid key={office.id} item xs={4}>
                    <CardOffice office={office}/>
                </Grid>)}
            </Grid>
        </Grid>
    </Box>;

}