import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent, Chip,
    Grid,
    Link,
    Typography, useMediaQuery
} from "@mui/material";
import {Add, Delete, Edit} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import DialogNews from "../../../components/news/dialog/DialogNews";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import axios from "axios";
import {useUser} from "../../../components/tools/context/User";
import CardImg from "../../../components/tools/display/CardImg";

export default function News() {
    const navigate = useNavigate();
    const [newsToEdit, setNewsToEdit] = useState<any>(null)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [isToReload, setIsToReload] = useState<boolean>(true)
    const [data, setData] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const handleError = useHandleError();
    const user = useUser()
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (!isToReload) {
            return;
        }
        (async () => {
            try {
                setIsLoading(true)
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/news`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                setData(res.data)
                setIsLoading(false)
                setIsToReload(false)
            } catch (e) {
                setIsToReload(false)
                setIsLoading(false)
                handleError(e)
            }
        })();
    }, [handleError, isToReload]);

    const downloadFile = async (file: string) => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/download/file?file=${file}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            // Créer un lien temporaire
            const link = document.createElement('a');

            // Définir le contenu du lien comme le fichier Base64
            link.href = response.data;

            // Définir le nom du fichier pour le téléchargement
            link.download = file.split('/').pop() || '';

            // Ajouter le lien au document
            document.body.appendChild(link);

            // Simuler un clic sur le lien pour lancer le téléchargement
            link.click();

            // Supprimer le lien du document
            document.body.removeChild(link);
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <Grid container item xs={12} spacing={2} pb={10}>
            <Grid item xs={12} md={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>News</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        News
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                {(user.role === "SUPER_ADMIN" || user.role === "DEV") && !isMobile &&
                    <Button variant="contained" color="primary" startIcon={<Add/>} onClick={
                        () => {
                            setNewsToEdit(null);
                            setOpenDialog(true)
                        }
                    }>
                        Cree une news
                    </Button>}
            </Grid>
            {data.map((news: any) => (
                <Grid item xs={12} md={4} key={news.id}>
                    <Card sx={{width: '100%'}}>
                        {news.file && <CardImg src={news.file}/>}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {news.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {news.content}
                            </Typography>
                        </CardContent>
                        {(user.role === "SUPER_ADMIN" || user.role === "DEV") ? <CardActions>
                            <Button size="small" startIcon={
                                <Delete/>
                            }>Supprimer</Button>
                            <Button size="small" startIcon={
                                <Edit/>
                            } onClick={
                                () => {
                                    setNewsToEdit(news);
                                    setOpenDialog(true);
                                }
                            }>Modifier</Button>
                        </CardActions> : <CardActions>
                            {news.readBy.find((u: any) => u === user.id) && <Chip size={'small'} label={'Lu'}
                                                                                  color={'secondary'}/>}
                        </CardActions>}
                    </Card>
                </Grid>
            ))}
            <DialogNews open={openDialog} onClose={(isToReload: boolean) => {
                setIsToReload(isToReload);
                setOpenDialog(false);
            }} news={newsToEdit}/>
        </Grid>
    );
}