import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    Link,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import DialogConfirmationDelete from "../../../components/tools/dialog/DialogConfirmationDelete";
import {Add, Delete, Edit} from "@mui/icons-material";
import DataTable, {ColumnProps} from "../../../components/tools/display/DataTable";
import Notes from "../../../components/tools/display/TableCell/Notes";
import DialogTemplatePlanning from "../../../components/settings/company/DialogTemplatePlanning";

export default function PlanningTemplate(): JSX.Element {
    const [data, setData] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [isToReload, setIsToReload] = React.useState<boolean>(true);
    const [planningToEdit, setPlanningToEdit] = React.useState<any | null>(null);
    const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const handleError = useHandleError();

    useEffect(() => {
        (async () => {
            if (!isToReload)
                return;
            try {
                setIsLoading(true);
                const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/events/templatePlanning`, localStorage.getItem('token'));
                setData(response);
                setIsLoading(false);
                setIsToReload(false);
            } catch (err: any) {
                setIsLoading(false);
                handleError(err);
            }
        })()
    }, [handleError, isToReload]);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleDelete(event: any) {
        setPlanningToEdit(event);
        setOpenDialogDelete(true);
    }

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Titre',
            format: (row: any) => row.title,
            searchDefaultValue: (row: any) => row.title,
        },
        {
            label: 'Note',
            format: (row: any) => row.notes ? <Notes notes={row.notes}/> : '-',
        },
        {
            label: 'Evenements',
            format: (row: any) => row.data.map((event: any) => event.title).join(', '),
        },
        {
            label: 'Bureaux',
            format: (row: any) => row.offices.map((office: any) => office.name).join(', '),
            searchDefaultValue: (row: any) => row.offices.map((office: any) => office.name).join(' '),
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton onClick={async () => {
                    setPlanningToEdit(row);
                    setOpenDialog(true);
                }}>
                    <Edit/>
                </IconButton>
                <IconButton onClick={() => {
                    handleDelete(row)
                }}>
                    <Delete/>
                </IconButton>
            </Box>
        }
    ], [])


    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>
                    Planning Template
                </Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app/settings')}
                    >
                        Paramétre
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Planning Template
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                <Button variant="contained" color="primary" startIcon={<Add/>}
                        onClick={handleOpenDialog}>
                    Créer une template
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataTable isLoading={isLoading} isError={false} data={data ? data : []} columns={columns}/>
            </Grid>
        </Grid>
        <DialogTemplatePlanning open={openDialog} onClose={(isToReload: boolean) => {
            setIsToReload(isToReload)
            setOpenDialog(false)
            setPlanningToEdit(null)
        }} planningToEdit={planningToEdit}/>
        <DialogConfirmationDelete open={openDialogDelete} onClose={(isToReload: boolean) => {
            setIsToReload(isToReload)
            setOpenDialogDelete(false)
            setPlanningToEdit(null)
        }} url={
            `${process.env.REACT_APP_API_URL}/api/events/templatePlanning/${planningToEdit ? planningToEdit.id : ''}`
        }/>
    </Box>
}