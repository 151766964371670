import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Collapse,
    Link,
    List,
    ListItem,
    Paper,
    Typography
} from "@mui/material";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import axios from "axios";

export function Items({data, deep = 0}: any) {
    const [open, setOpen] = useState(false);
    const handleError = useHandleError();
    const [isLoading, setIsLoading] = useState(false)

    async function handleDownload(file: string) {
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/download/file?file=${file}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            // Créer un lien temporaire
            const link = document.createElement('a');

            // Définir le contenu du lien comme le fichier Base64
            link.href = response.data;

            // Définir le nom du fichier pour le téléchargement
            link.download = file.split('/').pop() || '';

            // Ajouter le lien au document
            document.body.appendChild(link);

            // Simuler un clic sur le lien pour lancer le téléchargement
            link.click();

            // Supprimer le lien du document
            document.body.removeChild(link);
            setIsLoading(false);
        } catch (err: any) {
            handleError(err)
            setIsLoading(false);
        }
    }

    return <>
        <ListItem sx={{width: '100%'}}>
            <Paper variant={'outlined'} sx={{width: '100%', ml: 5 * deep}}>
                <Button startIcon={isLoading ? <CircularProgress size={'small'}/> : data.children && data.children.length > 0 ? <FolderIcon sx={{
                    color: '#f7e454'
                }}/> : <InsertDriveFileIcon sx={{
                    color: 'grey'
                }}/>} fullWidth onClick={async () => {
                    if (data.children && data.children.length > 0)
                        setOpen(!open)
                    else
                        await handleDownload(data.path);
                }} sx={{
                    textTransform: 'none',
                    p: 2
                }}>
                    <Box sx={{
                        textAlign: 'left',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',
                    }}>
                        <Typography>{data.name}</Typography>
                    </Box>
                </Button>
            </Paper>
        </ListItem>
        {data.children && data.children.length > 0 && <Collapse in={open}>
            {data.children.map((item: any, index: any) => <Items key={index} data={item} deep={deep + 1}/>)}
        </Collapse>}
    </>
}

export default function FilesManagerPage() {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/company/files`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const navigate = useNavigate();

    return <Box width={'100%'} height={'100%'}>
        <Box mb={2}>
            <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Vos fichiers</Typography>
            <Breadcrumbs separator="-" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                    Dashboard
                </Link>
                <Link
                    color="text.primary"
                >
                    Fichiers
                </Link>
            </Breadcrumbs>
        </Box>
        <List sx={{width: '100%'}}>
            {data && data.map((item: any, index: any) => <Items key={index} data={item}/>)}
        </List>
    </Box>
}