import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@mui/material";
import {useState} from "react";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {useSnackBar} from "../../tools/context/SnackBar";
import axios from "axios";
import {LoadingButton} from "@mui/lab";
import { Close } from "@mui/icons-material";

interface DialogRefuseProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    id?: string | null;
}

export default function DialogRefuse({open, onClose, id}: DialogRefuseProps): JSX.Element {
    const [comment, setComment] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleError = useHandleError();
    const msg = useSnackBar();

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
    }

    async function handleRefuse() {
        try {
            setIsLoading(true);
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/company/holidays/${id}`, {
                comment,
                status: 'REFUSED'
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('La demande de congé a été refusée avec succès', 'success');
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    return <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}>
            Refuser la demande de congé
            <IconButton onClick={() => handleClose(false)}>
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Grid container item xs={12} sx={{mt: 1}}>
                <TextField variant={'outlined'} label={'Commentaire'} fullWidth multiline rows={4} value={comment}
                           onChange={(e) => setComment(e.target.value)}/>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} onClick={handleRefuse} variant={'contained'}>Refuser</LoadingButton>
        </DialogActions>
    </Dialog>
}