export const getStartOfWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is Sunday
    const startOfWeek = new Date(today.setDate(diff));
    return startOfWeek.toISOString(); // Return date only
};

export const getEndOfWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const diff = today.getDate() + (6 - dayOfWeek) + (dayOfWeek === 0 ? -6 : 1); // adjust when day is Sunday
    const endOfWeek = new Date(today.setDate(diff));
    return endOfWeek.toISOString(); // Return date only
};