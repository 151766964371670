import {useNavigate, useParams} from "react-router-dom";
import {Box, Breadcrumbs, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";
import axios from "axios";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import {PaperTheme, TextFieldTheme} from "../../../themes/themes";

export default function OfficeById(): JSX.Element {
    const params = useParams();

    const [name, setName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [address, setAddress] = useState<string | null>('');
    const [zip, setZip] = useState<string | null>('');
    const [city, setCity] = useState<string | null>('');
    const [country, setCountry] = useState<string>('France');

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleError = useHandleError();

    useEffect(() => {
        if (params.id === "new")
            return;
        (async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/offices/${params.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setName(response.data.name);
                setEmail(response.data.email);
                setPhone(response.data.phoneNumber);
                setAddress(response.data.address.street);
                setZip(response.data.address.zip);
                setCity(response.data.address.city);
                setCountry(response.data.address.country);
                setIsLoading(false);
            } catch (err: any) {
                setIsLoading(false);
                handleError(err);
            }
        })()
    }, [handleError, params.id]);

    async function handleSubmit(event: any): Promise<void> {
        event.preventDefault();
        try {
            setIsLoading(true);
            if (params.id !== "new") {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/offices/${params.id}`, {
                    name,
                    phoneNumber: phone,
                    email,
                    address: {
                        street: address,
                        city,
                        zip,
                        country
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/offices`, {
                    name,
                    phoneNumber: phone,
                    email,
                    address: {
                        street: address,
                        city,
                        zip,
                        country
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            }
            navigate('/app/offices');
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        }
    }

    return <Box width={'100%'} height={'100%'} component={'form'} onSubmit={handleSubmit}>
        <Grid container item xs={12}>
            <Grid item xs={12} sx={{mb: 6}}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Mes bureaux</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app/offices')}
                    >
                        Mes bureaux
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        {params.id === "new" ? "Nouveau bureau" : name}
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={5}>
                <Typography variant={'h6'} color={'text.primary'}>Informations principale</Typography>
                <Typography variant={'caption'} color={'action.disabled'}>Nom, email, ....</Typography>
            </Grid>
            <Grid item xs={7}>
                <Paper sx={{
                    ...PaperTheme
                }}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Nom du
                                bureau</Typography>
                            <TextField value={name} onChange={(event: any) => setName(event.target.value)} required
                                       fullWidth variant={'outlined'} placeholder={'Nom de votre bureaux'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Email</Typography>
                            <TextField value={email} onChange={(event: any) => setEmail(event.target.value)}
                                       type={'email'} fullWidth variant={'outlined'} placeholder={'john.doe@gmail.com'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Numéro de
                                téléphone</Typography>
                            <TextField value={phone} onChange={(event: any) => setPhone(event.target.value)} fullWidth
                                       variant={'outlined'} placeholder={'00 00 00 00 00'} sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'}
                                        color={'text.primary'}>Adresse</Typography>
                            <TextField value={address} onChange={(event: any) => setAddress(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'Rue'} sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField value={zip} onChange={(event: any) => setZip(event.target.value)} fullWidth
                                       variant={'outlined'} placeholder={'Code postal'} sx={{...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField value={city} onChange={(event: any) => setCity(event.target.value)} fullWidth
                                       variant={'outlined'} placeholder={'Ville'} sx={{...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField value={country} onChange={(event: any) => setCountry(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'Pays'} sx={{...TextFieldTheme}}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid container item xs={12} justifyContent={'end'} sx={{mt: 3}}>
                <LoadingButton size={'large'} type={'submit'} loading={isLoading} variant="contained" color="primary">
                    Enregistrer
                </LoadingButton>
            </Grid>
        </Grid>
    </Box>
}