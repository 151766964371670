import {Close, Delete} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {
    Avatar,
    Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Select, TextField,
    Typography
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import {TextFieldTheme} from "../../../themes/themes";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import MultiAutocompleteUrl from "../../tools/input/MultiAutocompleteUrl";
import axios from "axios";
import {useSnackBar} from "../../tools/context/SnackBar";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {useOffice} from "../../tools/context/Office";

export enum ActionType {
    ADD = 'ADD',
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    CLOSE = 'CLOSE'
}

interface DialogEventProps {
    open: boolean;
    onClose: (type: ActionType, eventData: any | null) => void;
    event: any;
    officesFilter?: any[];
    usersFilter?: any[];
    isOfficeMode?: boolean;
}

const listGradientColor: string[] = [
    "linear-gradient(45deg, #2196F3, #3F51B5)",
    "linear-gradient(45deg, #4CAF50, #8BC34A)",
    "linear-gradient(45deg, #F44336, #FF5722)",
    "linear-gradient(45deg, #9C27B0, #E91E63)",
    "linear-gradient(45deg, #FFEB3B, #FFC107)",
    "linear-gradient(45deg, #FF9800, #FFC107)",
    "linear-gradient(45deg, #E91E63, #FF4081)",
    "linear-gradient(45deg, #00BCD4, #009688)",
    "linear-gradient(45deg, #607D8B, #9E9E9E)",
    "linear-gradient(45deg, #000000, #FFFFFF)",
    "linear-gradient(45deg, #00FFFF, #008080)",
    "linear-gradient(45deg, #8A2BE2, #4169E1)",
    "linear-gradient(45deg, #FF1493, #DC143C)",
    "linear-gradient(45deg, #FFD700, #FF4500)",
    "linear-gradient(45deg, #ADFF2F, #32CD32)",
    "linear-gradient(45deg, #FF69B4, #DA70D6)",
    "linear-gradient(45deg, #4682B4, #A9A9A9)",
    "linear-gradient(45deg, #FF8C00, #B22222)",
    "linear-gradient(45deg, #2E8B57, #00CED1)",
    "linear-gradient(45deg, #FFC0CB, #FFFFFF)"
]


export default function DialogEvent({
                                        open,
                                        onClose,
                                        event = null,
                                        officesFilter = [],
                                        usersFilter = [],
                                        isOfficeMode = false
                                    }: DialogEventProps): JSX.Element {
    const [templateEvent, setTemplateEvent] = useState<any[]>([]);
    const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [start, setStart] = useState<any | null>(null);
    const [end, setEnd] = useState<any | null>(null);
    const [note, setNote] = useState<string>('');
    const [offices, setOffices] = useState<any[] | undefined>([]);
    const [users, setUsers] = useState<any[] | undefined>([]);
    const [color, setColor] = useState<string>(listGradientColor[0]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingTemplate, setIsLoadingTemplate] = useState<boolean>(false);
    const msg = useSnackBar();
    const handleError = useHandleError();
    const office = useOffice();

    useEffect(() => {
        if (!open) {
            return;
        }

        if (event) {
            setTitle(event.title ? event.title : '');
            setStart(event.start ? dayjs(event.start) : null);
            if (!event.end || event.start === event.end) {
                setEnd(dayjs(event.start).add(1, 'hour'));
            } else {
                setEnd(event.end ? dayjs(event.end) : null);
            }
            if (event.data) {
                setOffices(event.data.offices ? event.data.offices : []);
                setUsers(event.data.users ? event.data.users : []);
                setNote(event.data.notes);
                setColor(event.data.color ? event.data.color : listGradientColor[0]);
                setIsTemplateSelected(true);
            } else {
                (async () => {
                    try {
                        setIsLoadingTemplate(true)
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/template` + (isOfficeMode ? `?officeId=${office.id}` : ''), {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        });
                        setTemplateEvent(response.data);
                        setUsers(usersFilter);
                        setOffices(officesFilter);
                        setIsLoadingTemplate(false);
                        if (response.data.length === 0) {
                            setIsTemplateSelected(true);
                        }
                    } catch (err: any) {
                        setIsLoadingTemplate(false);
                        handleError(err);
                    }
                })();
            }
        } else {
            setUsers(usersFilter);
            setOffices(officesFilter);
        }
    }, [event, handleError, officesFilter, open, usersFilter, office, isOfficeMode]);

    function handleClose(type: ActionType, eventData: any | null) {
        onClose(type, eventData);
        setUsers([]);
        setOffices([]);
        setTitle('');
        setStart(null);
        setEnd(null);
        setNote('');
        setColor(listGradientColor[0])
        setIsTemplateSelected(false);
    }

    async function handleSubmit(eventTarget: any) {
        eventTarget.preventDefault();

        try {
            if (event.id) {
                setIsLoading(true);
                await axios.put(`${process.env.REACT_APP_API_URL}/api/events/${event.id}`, {
                    title: title,
                    start: dayjs(start).toISOString(),
                    end: dayjs(end).toISOString(),
                    offices: offices,
                    users: users,
                    notes: note,
                    color: color
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                msg('Événement mis à jour', 'success');

                handleClose(ActionType.EDIT, {
                    ...event,
                    title: title,
                    start: dayjs(start).toISOString(),
                    end: dayjs(end).toISOString(),
                    data: {
                        offices: offices,
                        users: users,
                        notes: note,
                        color: color
                    }
                });
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/events`, {
                title: title,
                start: dayjs(start).toISOString(),
                end: dayjs(end).toISOString(),
                offices: offices,
                users: users,
                notes: note,
                color: color
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            msg('Événement créé', 'success');

            handleClose(ActionType.ADD, {
                title: title,
                start: dayjs(start).toISOString(),
                end: dayjs(end).toISOString(),
                id: response.data.id.toString(),
                data: {
                    offices: offices,
                    users: users,
                    notes: note,
                    color: color
                }
            });
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    if (!event)
        return <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Chargement...</DialogTitle>
        </Dialog>

    return <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth component={'form'}
                   onSubmit={handleSubmit}>
        <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {event.id ? event.title : "Nouveaux evénement"}
            <IconButton onClick={() => handleClose(
                ActionType.CLOSE, null
            )}>
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {isLoadingTemplate && <Typography>Chargement...</Typography>}
            {isTemplateSelected ? <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Titre</Typography>
                    <TextField value={title} onChange={(event: any) => setTitle(event.target.value)} required fullWidth
                               variant={'outlined'} placeholder={'Titre de l\'événement'} sx={{...TextFieldTheme}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Date et heure de
                        débit</Typography>
                    <DateTimePicker sx={{
                        width: '100%', ...TextFieldTheme
                    }} minDateTime={
                        dayjs()
                    } value={
                        start
                    } onChange={(date) => setStart(date)}
                                    format={'DD/MM/YYYY HH:mm'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Date et heure de
                        fin</Typography>
                    <DateTimePicker sx={{
                        width: '100%', ...TextFieldTheme
                    }} disabled={!start} minDateTime={
                        start
                    } value={
                        end
                    } onChange={(date) => setEnd(date)}
                                    format={'DD/MM/YYYY HH:mm'}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Relier des
                        employers</Typography>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/users?start=${start}&end=${end}` + (isOfficeMode ? `&officeId=${office.id}` : '')}
                                          value={users}
                                          disabled={start === null || end === null}
                                          onChange={(value: any[] | undefined) => setUsers(value)}
                                          optionLabel={(option: any) => option.firstName + ' ' + option.lastName}
                                          renderOption={(option: any) => <ListItem sx={{p: 0}}>
                                              <ListItemAvatar>
                                                  <Avatar src={option.avatar}/>
                                              </ListItemAvatar>
                                              <ListItemText primary={option.firstName + ' ' + option.lastName}
                                                            secondary={option.tag}/>
                                              {option.holidays && option.holidays.length > 0 &&
                                                  <Chip label={option.holidays[0].reason}/>}
                                          </ListItem>}
                    />
                </Grid>
                {!isOfficeMode && <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Relier des
                        bureaux</Typography>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={offices}
                                          onChange={(value: any[] | undefined) => setOffices(value)}
                                          renderOption={(option: any) => option.name}
                                          helperText={'Tout les employés de ce bureau seront liés à cet événement'}
                    />
                </Grid>}
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Note</Typography>
                    <TextField value={note} onChange={(event: any) => setNote(event.target.value)} fullWidth
                               multiline rows={6}
                               variant={'outlined'} placeholder={'Note...'} sx={{...TextFieldTheme}}/>
                </Grid>
            </Grid> : <List>
                {templateEvent.map((template, index) => <ListItem key={index} button
                                                                  onClick={() => {
                                                                      setTitle(template.title);
                                                                      setOffices(template.offices);
                                                                      setUsers(template.users);
                                                                      setNote(template.notes);
                                                                      setColor(template.color);
                                                                      setIsTemplateSelected(true);
                                                                  }}>
                    <ListItemIcon>
                        <Box sx={{background: template.color, borderRadius: 1, height: 24, width: 24}}/>
                    </ListItemIcon>
                    <ListItemText primary={template.title}
                                  secondary={`Utilisateurs: ${template.users.map(
                                        (user: any) => user.firstName + ' ' + user.lastName
                                    ).join(', ')}`}/>
                </ListItem>)}
                <ListItem button>
                    <ListItemText primary={'Autre'} onClick={() => {
                        setIsTemplateSelected(true)
                    }}/>
                </ListItem>
            </List>}
        </DialogContent>
        {isTemplateSelected && <DialogActions sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2}}>
            <FormControl size={'small'} sx={{width: 150}}>
                <InputLabel>Couleur</InputLabel>
                <Select
                    label="Couleur"
                    value={color}
                    onChange={(event: any) => setColor(event.target.value)}
                    renderValue={(value) => <Box sx={{background: value, borderRadius: 1, height: 24}}/>}
                >
                    {
                        listGradientColor.map((color, index) => <MenuItem key={index} value={color}
                                                                          sx={{
                                                                              background: color,
                                                                              borderRadius: 1,
                                                                              height: 20,
                                                                              m: 1
                                                                          }}>

                        </MenuItem>)
                    }
                </Select>
            </FormControl>
            <Box sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                {event.id &&
                    <Button disabled={isLoading} variant={'outlined'} sx={{mr: 2}} color={'error'} startIcon={<Delete/>}
                            onClick={async () => {
                                try {
                                    setIsLoading(true);
                                    await axios.delete(`${process.env.REACT_APP_API_URL}/api/events/${event.id}`, {
                                        headers: {
                                            Authorization: `Bearer ${localStorage.getItem('token')}`
                                        }
                                    });
                                    msg('Événement supprimé', 'success');
                                    handleClose(ActionType.DELETE, event);
                                    setIsLoading(false);
                                } catch (err: any) {
                                    setIsLoading(false);
                                    handleError(err);
                                }
                            }}>Supprimer</Button>}
                <LoadingButton loading={isLoading} variant={'contained'} type={'submit'}>Sauvegarder</LoadingButton>
            </Box>
        </DialogActions>}
    </Dialog>
}