import {PaperTheme} from "../../themes/themes";
import {Box, Paper, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {Alert} from "@mui/lab";

interface CardNewsProps {
    data: any[];
}

export default function CardNews({data}: CardNewsProps): JSX.Element {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return <Paper variant={'outlined'} sx={{...PaperTheme, boxShadow: 0}}>
        <Typography variant={'h6'}>
            News
        </Typography>
        <Box sx={{mb: 1, mt: 1, height: 125}}>
            <Alert severity="info" >
                Aucune news pour le moment
            </Alert>
        </Box>
        <MobileStepper
            variant="dots"
            steps={6}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400, flexGrow: 1, background: 'transparent' }}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
                    Next
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                </Button>
            }
        />
    </Paper>
}