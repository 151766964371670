import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControlLabel,
    ListItemText
} from "@mui/material";
import React, {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useHandleError} from "../../tools/context/ErrorHandler";
import axios from "axios";
import dayjs from "dayjs";
import CardImg from "../../tools/display/CardImg";

export default function DialogSeeNews({open, onClose, news}: any) {
    const [isSigned, setIsSigned] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleError = useHandleError()

    async function handleClose() {
        try {
            setIsSubmitting(true)
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/news/${news.id}`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            setIsSubmitting(false)
        } catch (err) {
            handleError(err)
            setIsSubmitting(false)
        }
        onClose();
        setIsSigned(false);
    }

    return <Dialog open={open && news} fullWidth maxWidth={'md'}>
        <DialogTitle>
            <ListItemText primary={news ? news.title : null} secondary={
                `Publié le ${news ? dayjs(news.createdAt).format("DD/MM/YYYY") : ''}`
            }/>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>{news ? news.content : null}</DialogContentText>
            {news && news.file && <CardImg src={news.file}/>}
        </DialogContent>
        <DialogActions>
            <FormControlLabel control={<Checkbox size={'small'} checked={isSigned} onChange={() => setIsSigned(!isSigned)} />} label="Signer" />
            <LoadingButton loading={isSubmitting} onClick={handleClose} disabled={!isSigned}>Fermer</LoadingButton>
        </DialogActions>
    </Dialog>
}