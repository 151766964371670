import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormLabel,
    Grid, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {useParams} from "react-router-dom";
import {useSnackBar} from "../../tools/context/SnackBar";
import axios from "axios";
import {LoadingButton} from "@mui/lab";
import {TextFieldTheme} from "../../../themes/themes";
import AutocompleteUrl from "../../tools/input/AutocompleteUrl";
import TimeDuration from "../../tools/input/TimeDuration";
import getTotalsHour from "../../../lib/time/getTotalsHour";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface DialogDailyFormEditProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    data: any;
    user: any;
}

export default function DialogDailyFormEdit({open, onClose, data, user}: DialogDailyFormEditProps) {
    const [timeDriving, setTimeDriving] = useState<any>('0:0');
    const [timeWork, setTimeWork] = useState<any>('0:0');
    const [timeRest, setTimeRest] = useState<any>('0:0');
    const [isLoading, setIsLoading] = useState(false);
    const handleError = useHandleError();
    const [plateNumber, setPlateNumber] = useState<string | null>(null);
    const [comment, setComment] = useState<string | null>('');
    const [officeStart, setOfficeStart] = useState<any>(null);
    const [officeEnd, setOfficeEnd] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [tour, setTour] = useState<any | null>(null);
    const [extraWork, setExtraWork] = useState<boolean>(false);
    const [extraWorkTime, setExtraWorkTime] = useState<any>('0:0');
    const msg = useSnackBar();

    useEffect(() => {
        if (!open) return;
        if (!data) return;
        setPlateNumber(data.plateNumber);
        setComment(data.comment);
        setOfficeStart(data.officeStart);
        setOfficeEnd(data.officeEnd);
        setTimeDriving(data.timeDriving);
        setTimeWork(data.timeWorking);
        setTimeRest(data.timeRest);
        setDate(dayjs(data.createdAt));
        setTour(data.tour);
        setExtraWork(data.extraWork);
        setExtraWorkTime(data.extraWorkTime);
    }, [data, open]);

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setComment('');
        setPlateNumber('');
        setOfficeStart(null);
        setOfficeEnd(null);
        setTimeDriving('0:0');
        setTimeWork('0:0');
        setTimeRest('0:0');
        setDate(null);
        setTour(null);
        setExtraWork(false);
        setExtraWorkTime('0:0');
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setIsLoading(true);
            if (data === null) {
                if (!date) {
                    msg('Veuillez selectionner une date', 'error');
                    setIsLoading(false);
                    return;
                }
                await axios.post(`${process.env.REACT_APP_API_URL}/api/users/dailyForms`, {
                    plateNumber,
                    officeStart,
                    officeEnd,
                    timeDriving,
                    timeWork,
                    timeRest,
                    comment,
                    tour,
                    user,
                    createdAt: date,
                    extraWork,
                    extraWorkTime
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else
                await axios.put(`${process.env.REACT_APP_API_URL}/api/users/dailyForms/${data.id}`, {
                    plateNumber,
                    officeStart,
                    officeEnd,
                    timeDriving,
                    timeWork,
                    timeRest,
                    tour,
                    comment,
                    extraWork,
                    extraWorkTime
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            msg('Formulaire journalier enregistré avec succès', 'success');
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false)
            handleError(err);
        }
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)}
                component={'form'}
                onSubmit={handleSubmit}
                fullWidth
                maxWidth={'md'}>
            <DialogTitle>
                Modifier le formulaire
            </DialogTitle>
            <DialogContent>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Numero d'immatriculation du vehicule utiliser
                        </Typography>
                        <TextField sx={{...TextFieldTheme}} required placeholder={
                            'Exemple: 1234 AB 01'
                        } variant={'outlined'} fullWidth value={
                            plateNumber
                        } onChange={(event: any) => {
                            setPlateNumber(event.target.value);
                        }}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Lieu de prise de service
                        </Typography>
                        <AutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={officeStart}
                                         onChange={(value: any | undefined) => {
                                             setOfficeStart(value)
                                         }} required
                                         renderOption={(option: any) => option.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Lieu de cessation de service
                        </Typography>
                        <AutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={officeEnd}
                                         onChange={(value: any | undefined) => {
                                             setOfficeEnd(value)
                                         }} required
                                         renderOption={(option: any) => option.name}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Tournée
                        </Typography>
                        <AutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/tour`} value={tour}
                                         onChange={(value: any | undefined) => {
                                             setTour(value)
                                         }} required
                                         renderOption={(option: any) => option.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Temps de conduite
                        </Typography>
                        <TimeDuration value={
                            timeDriving
                        } onChange={(value: any) => {
                            setTimeDriving(value);
                        }}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Temps de travail manuelle
                        </Typography>
                        <TimeDuration value={
                            timeWork
                        } onChange={(value: any) => {
                            setTimeWork(value);
                        }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} color={'text.primary'}>
                            Total des heures de travail: {getTotalsHour(
                            [timeDriving, timeWork]
                        ).split(':')[0]} heures et {getTotalsHour(
                            [timeDriving, timeWork]
                        ).split(':')[1]} minutes
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Temps de repos
                        </Typography>
                        <TimeDuration value={
                            timeRest
                        } onChange={(value: any) => {
                            setTimeRest(value);
                        }}/>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl >
                            <FormLabel>
                                Avez-vous effectué un nettoyage ou entretien technique du vehicule ?
                            </FormLabel>
                            <RadioGroup
                                row
                                value={extraWork}
                                onChange={
                                    (_: any) => {
                                        setExtraWork(!extraWork);
                                    }
                                }
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                <FormControlLabel value={false} control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {extraWork && <Grid item md={6} xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Temps de travail supplementaire
                        </Typography>
                        <TimeDuration value={
                            extraWorkTime
                        } onChange={(value: any) => {
                            setExtraWorkTime(value);
                        }}/>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>
                            Commentaire
                        </Typography>
                        <TextField sx={{...TextFieldTheme}} rows={4} multiline variant={'outlined'}
                                   fullWidth value={
                            comment
                        } onChange={
                            (event: any) => {
                                setComment(event.target.value);
                            }
                        }/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <DatePicker readOnly={data !== null} format={'DD/MM/YYYY'} maxDate={dayjs()} value={date} onChange={(value: any) => {
                    setDate(value);
                }} sx={{...TextFieldTheme, width: 200}}/>
                <LoadingButton loading={isLoading} type={'submit'} variant="contained" color="primary">
                    Enregistrer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}