import {
    Avatar, Box, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel,
    ListItem,
    ListItemAvatar, ListItemText, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import {TextFieldTheme} from "../../../themes/themes";
import MultiAutocompleteUrl from "../../tools/input/MultiAutocompleteUrl";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import {useHandleError} from "../../tools/context/ErrorHandler";

interface DialogEventTemplateProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    event?: any;
}

export const listGradientColor: string[] = [
    "linear-gradient(45deg, #2196F3, #3F51B5)",
    "linear-gradient(45deg, #4CAF50, #8BC34A)",
    "linear-gradient(45deg, #F44336, #FF5722)",
    "linear-gradient(45deg, #9C27B0, #E91E63)",
    "linear-gradient(45deg, #FFEB3B, #FFC107)",
    "linear-gradient(45deg, #FF9800, #FFC107)",
    "linear-gradient(45deg, #E91E63, #FF4081)",
    "linear-gradient(45deg, #00BCD4, #009688)",
    "linear-gradient(45deg, #607D8B, #9E9E9E)",
    "linear-gradient(45deg, #000000, #FFFFFF)",
    "linear-gradient(45deg, #00FFFF, #008080)",
    "linear-gradient(45deg, #8A2BE2, #4169E1)",
    "linear-gradient(45deg, #FF1493, #DC143C)",
    "linear-gradient(45deg, #FFD700, #FF4500)",
    "linear-gradient(45deg, #ADFF2F, #32CD32)",
    "linear-gradient(45deg, #FF69B4, #DA70D6)",
    "linear-gradient(45deg, #4682B4, #A9A9A9)",
    "linear-gradient(45deg, #FF8C00, #B22222)",
    "linear-gradient(45deg, #2E8B57, #00CED1)",
    "linear-gradient(45deg, #FFC0CB, #FFFFFF)"
]

export default function DialogEventTemplate({open, onClose, event = null}: DialogEventTemplateProps): JSX.Element {
    const [title, setTitle] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [users, setUsers] = useState<any[] | undefined>([]);
    const [offices, setOffices] = useState<any[] | undefined>([]);
    const [color, setColor] = useState<string>(listGradientColor[0]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleError = useHandleError();

    useEffect(() => {
        if (!open)
            return;
        if (event) {
            setTitle(event.title);
            setNote(event.notes);
            setUsers(event.users);
            setColor(event.color);
            setOffices(event.offices)
        }
    }, [event, open]);

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setTitle('');
        setNote('');
        setUsers([]);
        setColor(listGradientColor[0]);
        setOffices([]);
    }

    async function handleSubmit(eventTarget: any) {
        eventTarget.preventDefault();
        setIsLoading(true);
        try {
            if (event) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/events/template/${event.id}`, {
                    title,
                    notes: note,
                    color,
                    users,
                    offices
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/events/template`, {
                    title,
                    notes: note,
                    color,
                    users,
                    offices
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            }
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    return <Dialog open={open} onClose={() => handleClose(false)} onSubmit={handleSubmit} component={'form'}>
        <DialogTitle>
            Template
        </DialogTitle>
        <DialogContent>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Titre</Typography>
                    <TextField value={title} onChange={(event: any) => setTitle(event.target.value)} required fullWidth
                               variant={'outlined'} placeholder={'Titre de l\'événement'} sx={{...TextFieldTheme}}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Relier des
                        employers</Typography>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/users`} value={users}
                                          onChange={(value: any[] | undefined) => setUsers(value)}
                                          optionLabel={(option: any) => option.firstName + ' ' + option.lastName}
                                          renderOption={(option: any) => <ListItem sx={{p: 0}}>
                                              <ListItemAvatar>
                                                  <Avatar src={option.avatar}/>
                                              </ListItemAvatar>
                                              <ListItemText primary={option.firstName + ' ' + option.lastName}
                                                            secondary={option.tag}/>
                                              {option.holidays && option.holidays.length > 0 &&
                                                  <Chip label={option.holidays[0].reason}/>}
                                          </ListItem>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Relier des
                        bureaux</Typography>
                    <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={offices}
                                          onChange={(value: any[] | undefined) => setOffices(value)}
                                          renderOption={(option: any) => option.name}
                                          helperText={'Permet de relier une template à des bureaux, si aucun bureau n\'est relié, la template sera visible pour tous les bureaux.'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Note</Typography>
                    <TextField value={note} onChange={(event: any) => setNote(event.target.value)} fullWidth
                               multiline rows={6}
                               variant={'outlined'} placeholder={'Note...'} sx={{...TextFieldTheme}}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2}}>
            <FormControl size={'small'} sx={{width: 150}}>
                <InputLabel>Couleur</InputLabel>
                <Select
                    label="Couleur"
                    value={color}
                    onChange={(event: any) => setColor(event.target.value)}
                    renderValue={(value) => <Box sx={{background: value, borderRadius: 1, height: 24}}/>}
                >
                    {
                        listGradientColor.map((color, index) => <MenuItem key={index} value={color}
                                                                          sx={{
                                                                              background: color,
                                                                              borderRadius: 1,
                                                                              height: 20,
                                                                              m: 1
                                                                          }}>

                        </MenuItem>)
                    }
                </Select>
            </FormControl>
            <LoadingButton loading={isLoading} variant={'contained'} type={'submit'}>Sauvegarder</LoadingButton>
        </DialogActions>
    </Dialog>
}