import {
    Box, Button, Checkbox, Dialog, DialogTitle, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {Add, Remove} from "@mui/icons-material";
import generateDaysWithHolidays from "../../../lib/planning/generateDaysWithHolidays";
import {LoadingButton} from "@mui/lab";
import {useHandleError} from "../../tools/context/ErrorHandler";
import axios from "axios";

interface HolidaysTableProps {
    open: boolean
    onClose: (isToReload: boolean) => void
    idSettingsYear: string | null
    newYear: number
}

export default function DialogHolidaysTable({open, onClose, idSettingsYear, newYear}: HolidaysTableProps): JSX.Element {
    const [data, setData] = useState<any[]>([]);
    const [activeYear, setActiveYear] = useState<number>(new Date().getFullYear());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleError = useHandleError();

    useEffect(() => {
        if (!open) return;

        if (idSettingsYear) {
            (async () => {
                try {
                    setIsLoading(true)
                    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/calendar/${idSettingsYear}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    setData(data.data);
                    setActiveYear(data.year);
                    setIsLoading(false);
                } catch (err: any) {
                    setIsLoading(false);
                    handleError(err);
                }
            })();
        } else {
            setData(generateDaysWithHolidays(newYear));
            setActiveYear(newYear);
        }
    }, [handleError, idSettingsYear, newYear, open]);

    async function handleSubmit(): Promise<void> {
        try {
            setIsLoading(true);
            await axios.put(`${process.env.REACT_APP_API_URL}/api/company/settings/holidays/${activeYear}`, {
                year: activeYear,
                data,
                id: idSettingsYear
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    const memoizedGetBgColor = useMemo(() => {
        return (dayData: any) => {
            if (dayData.dayOfWeek === "Dimanche") return "action.disabledBackground";
            if (dayData.isHoliday) return "secondary.main";
            return undefined;
        };
    }, []);

    function handleAddSlot(monthIndex: number, dayIndex: number) {
        const newData = [...data];
        newData[monthIndex].days[dayIndex].slots += 1;
        setData(newData);
    }

    function handleRemoveSlot(monthIndex: number, dayIndex: number) {
        const newData = [...data];
        newData[monthIndex].days[dayIndex].slots -= 1;
        setData(newData);
    }

    function handleAddSlotForAllDaysOfMonth(monthIndex: number) {
        const newData = [...data];
        newData[monthIndex].days.forEach((day: any) => {
            day.slots += 1;
        });
        setData(newData);
    }

    function handleRemoveSlotForAllDaysOfMonth(monthIndex: number) {
        const newData = [...data];
        newData[monthIndex].days.forEach((day: any) => {
            if (day.slots > 0)
                day.slots -= 1;
        });
        setData(newData);
    }

    function handleOpenMonth(monthIndex: number) {
        const newData = [...data];
        newData[monthIndex].open = !newData[monthIndex].open;
        setData(newData);
    }

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setData([]);
    }

    const MemoizedTableCell = React.memo(({dayData, index, dayIndex}: any) => (
        <TableCell sx={{
            bgcolor: memoizedGetBgColor(dayData),
            border: 0,
            borderLeft: 0.5,
            borderRight: 0.5,
            borderColor: 'divider'
        }}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Box display={'flex'} justifyContent={'space-between'} width={'28%'}>
                    <Typography variant={'body2'}>{dayData.day}</Typography>
                    <Typography variant={'body2'}>{dayData.dayOfWeek[0].toUpperCase()}</Typography>
                </Box>

                {dayData.dayOfWeek !== "Dimanche" && !dayData.isHoliday && <React.Fragment>
                    <IconButton size={'small'} sx={{p: 0}} disabled={dayData.slots === 0} onClick={() => {
                        handleRemoveSlot(index, dayIndex);
                    }}>
                        <Remove fontSize={"small"}/>
                    </IconButton>
                    {dayData.slots}
                    <IconButton size={'small'} sx={{p: 0}} onClick={() => {
                        handleAddSlot(index, dayIndex);
                    }}>
                        <Add fontSize={"small"}/>
                    </IconButton>
                </React.Fragment>}
            </Box>
        </TableCell>
    ));

    return (
        <Dialog open={open} onClose={handleClose} fullScreen>
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                Année 2024
                <Box>
                    <Button onClick={() => handleClose(false)} sx={{mr: 3}} disabled={isLoading}>
                        Fermer
                    </Button>
                    <LoadingButton loading={isLoading} variant={'contained'} onClick={handleSubmit}>
                        Sauvegarder
                    </LoadingButton>
                </Box>
            </DialogTitle>
            <TableContainer component={Box}>
                <Table size={'small'} stickyHeader>
                    {/* Table Header */}
                    <TableHead>
                        <TableRow sx={{border: 0}}>
                            {data.map((row: any) => <TableCell key={`${row.month}-header`} sx={{
                                width: '8.33%',
                                border: 0,
                                borderLeft: 0.5,
                                borderRight: 0.5,
                                borderColor: 'divider'
                            }}
                                                               align="center">
                                <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                                    {row.month}
                                    <IconButton size={'small'} sx={{p: 0}} onClick={() => {
                                        handleRemoveSlotForAllDaysOfMonth(data.indexOf(row));
                                    }}>
                                        <Remove fontSize={"small"}/>
                                    </IconButton>
                                    <IconButton size={'small'} sx={{p: 0}} onClick={() => {
                                        handleAddSlotForAllDaysOfMonth(data.indexOf(row));
                                    }}>
                                        <Add fontSize={"small"}/>
                                    </IconButton>
                                    <Checkbox size={"small"} sx={{p: 0}} checked={row.open} onClick={() => {
                                        handleOpenMonth(data.indexOf(row));
                                    }}/>
                                </Box>

                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    <TableBody>
                        {[...Array(31)].map((_, dayIndex) => (
                            <TableRow key={dayIndex} sx={{border: 0}}>
                                {data.map((monthData: any, index: any): any => {
                                    const dayData = monthData.days[dayIndex];

                                    if (!dayData)
                                        return <TableCell key={index} sx={{
                                            border: 0,
                                            borderLeft: 0.5,
                                            borderRight: 0.5,
                                            borderColor: 'divider'
                                        }}/>

                                    // @ts-ignore
                                    return <MemoizedTableCell
                                        key={index}
                                        dayData={dayData}
                                        index={index}
                                        dayIndex={dayIndex}
                                    />
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Dialog>
    )
}