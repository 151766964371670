import {
    Avatar,
    AvatarGroup,
    Box, Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton, List, ListItem, ListItemText, Popover,
    Tooltip,
    Typography
} from "@mui/material";
import {Business, Delete, Edit, MoreVert, Visibility} from "@mui/icons-material";
import React, {useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import DialogConfirmationDelete from "../tools/dialog/DialogConfirmationDelete";
import {useSnackBar} from "../tools/context/SnackBar";

export default function CardOffice({office}: any): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
    const navigate = useNavigate();
    const msg = useSnackBar()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <Card sx={{width: '100%'}}>
        <CardHeader
            sx={{pb: 0}}
            avatar={
                <Box borderRadius={2} width={20} height={20} p={2} sx={{
                    bgcolor: 'primary.main',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Business sx={{color: 'background.paper'}}/>
                </Box>
            }
            action={
                <IconButton size={'small'} onClick={handleClick}>
                    <MoreVert fontSize={'small'}/>
                </IconButton>
            }
        />
        <CardContent>
            <Typography variant="h6" component="div">
                {office.name}
            </Typography>
            <Typography sx={{mb: 1.5}} variant="body2" color="text.secondary">
                Crée le: {moment(office.createdAt).format('DD/MM/YYYY')}
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'start', mb: 2}}>
                <Tooltip title={`Employés total: ${office.users.length}`}>
                    <AvatarGroup total={office.users.length} max={6} sx={{
                        '& .MuiAvatar-root': {width: 24, height: 24, fontSize: 15, border: 1},
                    }}>
                        {office.users.map((user: any) => <Avatar key={user.id} alt={user.firstName}
                                                                 src={user.avatar}/>)}
                    </AvatarGroup>
                </Tooltip>
            </Box>
            <Divider/>
            <Typography sx={{mt: 2}}>
                Adresse: {office.address.street}, {office.address.city} {office.address.zip}, {office.address.country}
            </Typography>
        </CardContent>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    borderRadius: 2,
                    boxShadow: 4,
                    marginTop: 1,
                    overflow: 'hidden',
                }
            }}
        >
            <List sx={{p: 1}} component="div" disablePadding>
                <ListItem button disablePadding sx={{
                    px: 1,
                    borderRadius: 2,
                    '&:hover': {
                        borderRadius: 2,
                    }
                }} onClick={() => {
                    msg('Aperçu', 'success')
                }}>
                    <Visibility fontSize={'small'} sx={{mr: 1.5}}/>
                    <ListItemText primary={'Aperçu'}/>
                </ListItem>
                <ListItem button disablePadding sx={{
                    px: 1,
                    borderRadius: 2,
                    '&:hover': {
                        borderRadius: 2,
                    }
                }} onClick={() => navigate(window.location.pathname + '/' + office.id)}>
                    <Edit fontSize={'small'} sx={{mr: 1.5}}/>
                    <ListItemText primary={'Modifer'}/>
                </ListItem>
                <ListItem button disablePadding sx={{
                    px: 1,
                    borderRadius: 2,
                    '&:hover': {
                        borderRadius: 2,
                    }
                }} onClick={() => setOpenDialogDelete(true)}>
                    <Delete fontSize={'small'} sx={{mr: 1.5, color: 'red'}}/>
                    <ListItemText primary={<Typography sx={{color: 'red'}}>Supprimer</Typography>}/>
                </ListItem>
            </List>
        </Popover>
        <DialogConfirmationDelete open={openDialogDelete} onClose={(isToReload: boolean) => {
            setOpenDialogDelete(false);
            if (isToReload) {
                window.location.reload();
            }
        }} url={`${process.env.REACT_APP_API_URL}/api/offices/${office.id}`}/>
    </Card>
}