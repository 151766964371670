import {
    Checkbox,
    FormControl, FormControlLabel,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper, Select,
    TextField,
    Typography
} from "@mui/material";
import {PaperTheme, TextFieldTheme} from "../../themes/themes";
import AvatarInput from "../tools/input/AvatarInput";
import MultiAutocompleteUrl from "../tools/input/MultiAutocompleteUrl";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {useSnackBar} from "../tools/context/SnackBar";
import {useHandleError} from "../tools/context/ErrorHandler";
import axios from "axios";

interface ProfileProps {
    data: any;
}

export default function Profile({data}: ProfileProps) {
    const [firstName, setFirstName] = useState<string | null>(data.firstName);
    const [lastName, setLastName] = useState<string | null>(data.lastName);
    const [email, setEmail] = useState<string | null>(data.email);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(data.phoneNumber);
    const [role, setRole] = useState<string>(data.role);
    const [tag, setTag] = useState<string>(data.tag);
    const [offices, setOffices] = useState<any[] | undefined>(data.offices);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormDaily, setIsFormDaily] = useState<boolean>(data.dailyFormMandatory);
    const [numberOfHolidays, setNumberOfHolidays] = useState<number>(data.numberOfHolidays);
    const msg = useSnackBar();
    const handleError = useHandleError();

    async function handleSubmit(event: any): Promise<void> {
        event.preventDefault();
        try {
            setIsLoading(true);
            await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${data.id}`, {
                firstName,
                lastName,
                email,
                phoneNumber,
                role,
                offices,
                isFormDaily,
                tag,
                numberOfHolidays
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Utilisateur mis à jour', 'success');
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    return <Grid container item xs={12} spacing={3} component={'form'} onSubmit={handleSubmit}>
        <Grid item xs={4}>
            <Paper sx={{...PaperTheme, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <AvatarInput/>
                <ListItem sx={{mt: 2}}>
                    <ListItemText primary={"Email verifié"}
                                  secondary={'La désactivation de cette option enverra automatiquement à l\'utilisateur un e-mail de vérification'}/>
                    <ListItemIcon>
                        <Checkbox defaultChecked/>
                    </ListItemIcon>
                </ListItem>
            </Paper>
        </Grid>
        <Grid container item xs={8}>
            <Paper sx={{...PaperTheme}}>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Prénom</Typography>
                        <TextField required value={firstName}
                                   onChange={(event: any) => setFirstName(event.target.value)}
                                   fullWidth variant={'outlined'} placeholder={'Prénom'}
                                   sx={{mt: 0.5, ...TextFieldTheme}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Nom</Typography>
                        <TextField required value={lastName}
                                   onChange={(event: any) => setLastName(event.target.value)}
                                   fullWidth variant={'outlined'} placeholder={'Nom'}
                                   sx={{mt: 0.5, ...TextFieldTheme}}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>E-mail</Typography>
                        <TextField required value={email} onChange={(event: any) => setEmail(event.target.value)}
                                   type={'email'}
                                   fullWidth variant={'outlined'} placeholder={'john.doe@gmail.com'}
                                   sx={{mt: 0.5, ...TextFieldTheme}}/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Numéro de
                            télephone</Typography>
                        <TextField value={phoneNumber} onChange={(event: any) => setPhoneNumber(event.target.value)}
                                   fullWidth variant={'outlined'} placeholder={'00 00 00 00 00'}
                                   sx={{mt: 0.5, ...TextFieldTheme}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Tag</Typography>
                        <TextField value={tag} onChange={(event: any) => setTag(event.target.value)}
                                   fullWidth variant={'outlined'} placeholder={'CEO, CTO, ...'}
                                   sx={{mt: 0.5, ...TextFieldTheme}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Role</Typography>
                        <FormControl fullWidth sx={{mt: 0.5, ...TextFieldTheme}}>
                            <Select placeholder="Employé" defaultValue={'WORKER'} value={role}
                                    onChange={(event: any) => setRole(event.target.value)}
                            >
                                <MenuItem value={'WORKER'}>Employé</MenuItem>
                                <MenuItem value={'ADMIN'}>Administrateur</MenuItem>
                                <MenuItem value={'SUPER_ADMIN'}>Super administrateur</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'}
                                    color={'text.primary'}>Bureaux</Typography>
                        <MultiAutocompleteUrl url={`${process.env.REACT_APP_API_URL}/api/offices`} value={offices}
                                              onChange={(value: any[] | undefined) => setOffices(value)}
                                              renderOption={(option: any) => option.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Nombre de
                            congés</Typography>
                        <TextField value={numberOfHolidays} onChange={(event: any) => setNumberOfHolidays(event.target.value)}
                                   fullWidth variant={'outlined'} placeholder={'Nombre de congés'}
                                   sx={{mt: 0.5, ...TextFieldTheme}}/>
                    </Grid>
                    <Grid container item xs={8} justifyContent={'start'} alignItems={'center'}>
                        <FormControlLabel control={<Checkbox checked={
                            isFormDaily
                        } onChange={(event: any) => setIsFormDaily(event.target.checked)
                        }/>} label={
                            "Besoin du formulaire journalier de temps de travail"
                        }/>
                    </Grid>
                    <Grid container item xs={4} justifyContent={'end'}>
                        <LoadingButton loading={isLoading} variant="contained" color="primary" type={'submit'}
                                       sx={{mt: 2}}>Sauvegarder</LoadingButton>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>
}