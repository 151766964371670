import {
    Avatar, Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, IconButton, InputAdornment,
    TextField, Typography
} from "@mui/material";
import {AddAPhoto, Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";
import evaluatePasswordStrength from "../../../lib/password/evaluatePasswordStrength";
import axios from "axios";
import {LoadingButton} from "@mui/lab";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {TextFieldTheme} from "../../../themes/themes";

interface DialogFirstConnectionUserProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    user: any;
}

export default function DialogFirstConnectionUser({open, onClose, user}: DialogFirstConnectionUserProps) {
    const [avatar, setAvatar] = useState<string>('');
    const [firstName, setFirstName] = useState<string>(user.firstName);
    const [lastName, setLastName] = useState<string>(user.lastName);
    const [email, setEmail] = useState<string>(user.email);
    const [phoneNumber, setPhoneNumber] = useState<string>(user.phoneNumber);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [errorConfirmPassword, setErrorConfirmPassword] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleError = useHandleError();

    function handleClickShowPassword() {
        setShowPassword(!showPassword);
    }

    function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
    }

    function handleFileInputChange(event: any){
        const file = event.target.files[0];
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            if (password !== confirmPassword) {
                setErrorConfirmPassword(true);
                return;
            }
            setIsLoading(true);
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/login`, {
                email,
                password,
                firstName,
                lastName,
                phoneNumber,
                // avatar
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            onClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    return <Dialog open={open} component={'form'} onSubmit={handleSubmit}>
        <DialogTitle>Bienvenue sur .....</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Avant de commencer, veuillez vérifier que toute les information son correcte pour votre profil.
            </DialogContentText>
            <Grid container item xs={12} sx={{mt: 0}} spacing={2}>
                <Grid container item xs={12} justifyContent={'center'} direction={'column'} alignItems={'center'}>
                    <Box sx={{width: 144, height: 144, border: 1, borderRadius: 50, borderStyle: 'dashed', p: 1}}>
                        <label htmlFor="image-upload" style={{width: '100%', height: '100%', cursor: 'pointer'}}>
                            {avatar ? (
                                <Avatar
                                    alt="Selected Image"
                                    src={avatar}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        bgcolor: 'background.default'
                                    }}
                                />
                            ) : (
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        bgcolor: 'background.default'
                                    }}
                                >
                                    <AddAPhoto sx={{fontSize: 35, mb: 1}} color={'primary'}/>
                                    <Typography variant={'body2'} color={'primary'}>Ajouter une photo</Typography>
                                </Avatar>
                            )}
                        </label>
                        <input
                            id="image-upload"
                            type="file"
                            accept=".jpeg, .jpg, .png"
                            style={{display: 'none'}}
                            onChange={handleFileInputChange}
                        />
                    </Box>
                    <Typography sx={{mt: 1}} variant={'caption'} color={'text.secondary'}>Autorisé *.jpeg, *.jpg,
                        *.png</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField value={lastName} required onChange={(event: any) => setLastName(event.target.value)}
                               fullWidth label="Nom" variant="outlined" sx={{
                        ...TextFieldTheme
                    }}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField value={firstName} required onChange={(event: any) => setFirstName(event.target.value)}
                               fullWidth label="Prénom" variant="outlined" sx={{
                        ...TextFieldTheme
                    }}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField value={email} required onChange={(event: any) => setEmail(event.target.value)} fullWidth
                               label="Email" type={'email'} variant="outlined" sx={{
                        ...TextFieldTheme
                    }}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField value={phoneNumber} required
                               onChange={(event: any) => setPhoneNumber(event.target.value)} fullWidth label="Téléphone"
                               variant="outlined" sx={{
                        ...TextFieldTheme
                    }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={password} required onChange={(event: any) => setPassword(event.target.value)} fullWidth type={showPassword ? 'text' : 'password'} label="Mot de passe" variant="outlined" sx={{
                        ...TextFieldTheme
                    }} InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField error={errorConfirmPassword} helperText={errorConfirmPassword && "Le mot de passe ne correspond pas."} value={confirmPassword} required onChange={(event: any) => setConfirmPassword(event.target.value)} fullWidth type={showPassword ? 'text' : 'password'} label="Confirmez votre mot de passe" variant="outlined" sx={{
                        ...TextFieldTheme
                    }} InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }}/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{width: '100%', height: 6, bgcolor: evaluatePasswordStrength(password), borderRadius: 2}}>
                    </Box>
                    <Typography variant={'caption'} color={'text.secondary'}>Votre mot de passe doit contenir au moins 8
                        caractères, une lettre majuscule, une lettre minuscule, un chiffre et un caractère
                        spécial.</Typography>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={isLoading} type={'submit'} color="primary">
                Sauvegarder
            </LoadingButton>
        </DialogActions>
    </Dialog>;
}