function evaluatePasswordStrength(password: string): string {
    // Minimum password length
    const minLength = 8;

    // Regular expression to check for digits
    const containsDigits = /\d/.test(password);

    // Regular expression to check for lowercase letters
    const containsLowercase = /[a-z]/.test(password);

    // Regular expression to check for uppercase letters
    const containsUppercase = /[A-Z]/.test(password);

    // Regular expression to check for special characters
    const containsSpecialChars = /[$&+,:;=?@#|'<>.^*()%!-]/.test(password);

    // Evaluate password strength
    let strength = 0;
    if (password.length >= minLength) {
        strength += 1;
    }
    if (containsDigits) {
        strength += 1;
    }
    if (containsLowercase) {
        strength += 1;
    }
    if (containsUppercase) {
        strength += 1;
    }
    if (containsSpecialChars) {
        strength += 1;
    }

    // Determine color based on strength
    let color = "";
    switch (strength) {
        case 0:
        case 1:
        case 2:
            color = "red";
            break;
        case 3:
        case 4:
            color = "orange";
            break;
        case 5:
            color = "green";
            break;
        default:
            color = "black";
            break;
    }

    return color;
}

export default evaluatePasswordStrength;
