import {useNavigate, useParams} from "react-router-dom";
import {Box, Breadcrumbs, Link, Tab, Tabs, Typography} from "@mui/material";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import {useState} from "react";
import Profile from "../../../components/accounts/Profile";
import Holidays from "../../../components/accounts/Holidays";
import DailyFormByUser from "../../../components/workers/DailyFormByUser";
import UserAnalyse from "../../../components/workers/UserAnalyse";

export default function UserById(): JSX.Element {
    const params: any = useParams<any>();
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/users/${params.id}`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const navigate = useNavigate();
    const handleError = useHandleError();

    const [tabs, setTabs] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabs(newValue);
    };

    if (error)
        handleError(error);

    if (isLoading)
        return <p>Loading...</p>

    return <Box width={'100%'} height={'100%'}>
        <Box sx={{mb: 2}}>
            <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Compte
                de {data.firstName} {data.lastName}</Typography>
            <Breadcrumbs separator="-" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                    Dashboard
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate('/app/workers')}
                >
                    Mes employés
                </Link>
                <Link
                    color="text.primary"
                >
                    {data.email}
                </Link>
            </Breadcrumbs>
        </Box>
        <Box sx={{mb: 2}}>
            <Tabs value={tabs} onChange={handleChange}>
                <Tab label="Profile"/>
                <Tab label="Congés"/>
                <Tab label="Feuille quotidienne"/>
                <Tab label="Analyse"/>
            </Tabs>
        </Box>
        {tabs === 0 && <Profile data={data}/>}
        {tabs === 1 && <Holidays holidays={data.holidays}/>}
        {tabs === 2 && <DailyFormByUser user={data}/>}
        {tabs === 3 && <UserAnalyse user={data}/>}
    </Box>
}