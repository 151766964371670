import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {CalendarMonth, DynamicForm} from "@mui/icons-material";
import ChecklistIcon from "@mui/icons-material/Checklist";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {DrawerItemsUrl} from "../../../components/drawer";
import {useUser} from "../../../components/tools/context/User";

export default function MobilePage() {
    const [value, setValue] = useState<number>(0);
    const navigate = useNavigate();
    const user = useUser();

    const drawerUser: DrawerItemsUrl[] = [
        {name: 'Dashboard', icon: <DashboardIcon fontSize={'medium'}/>, url: '/dashboard'},
        // {name: 'Chat', icon: <ChatIcon fontSize={'small'}/>, url: '/chat'},
        {name: 'Quotidien', icon: <DynamicForm fontSize={'medium'}/>, url: '/dailyForm', condition: user.dailyFormMandatory},
        {name: 'Planning', icon: <CalendarMonth fontSize={'medium'}/>, url: '/calendar'},
        {name: 'Congés', icon: <ChecklistIcon fontSize={'medium'}/>, url: '/holidays'},
        {name: 'News', icon: <NewspaperIcon fontSize={'medium'}/>, url: '/news'},
    ];

    return <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
            {drawerUser.map((item, index) => <BottomNavigationAction key={item.name} label={item.name} icon={item.icon} onClick={() => navigate("/app" + item.url)} />)}
        </BottomNavigation>
    </Paper>
}