import {
    Box,
    Breadcrumbs, Checkbox, FormControl,
    FormControlLabel,
    Grid,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper, Select, Switch,
    TextField,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PaperTheme, TextFieldTheme} from "../../../themes/themes";
import AvatarInput from "../../../components/tools/input/AvatarInput";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useSnackBar} from "../../../components/tools/context/SnackBar";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import axios from "axios";
import MultiAutocompleteUrl from "../../../components/tools/input/MultiAutocompleteUrl";
import {useOffice} from "../../../components/tools/context/Office";

interface CreateNewUserProps {
    isOfficeMode?: boolean;
}

export default function CreateNewUser({isOfficeMode}: CreateNewUserProps): JSX.Element {
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [role, setRole] = useState<string>('WORKER')
    const [tag, setTag] = useState<string>('');
    const [offices, setOffices] = useState<any[] | undefined>([]);
    const [isFormDaily, setIsFormDaily] = useState<boolean>(false);
    const [numberOfHolidays, setNumberOfHolidays] = useState<number>(30);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const msg = useSnackBar();
    const handleError = useHandleError();
    const office = useOffice();

    useEffect(() => {
        if (isOfficeMode) {
            setOffices([office]);
        }
    }, [office, isOfficeMode]);

    async function handleSubmit(event: any): Promise<void> {
        event.preventDefault();
        try {
            setIsLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, {
                firstName,
                lastName,
                email,
                phoneNumber,
                role,
                offices,
                isFormDaily,
                tag,
                numberOfHolidays
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Employé créé avec succès', 'success');
            navigate('/app/workers');
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    return <Box width={'100%'} height={'100%'} component={'form'} onSubmit={handleSubmit}>

        <Box sx={{mb: 2}}>
            <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Crée un nouveaux employée</Typography>
            <Breadcrumbs separator="-" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                    Dashboard
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => navigate('/app/workers')}
                >
                    Mes employés
                </Link>
                <Link
                    color="text.primary"
                >
                    Nouveau employé
                </Link>
            </Breadcrumbs>
        </Box>

        <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
                <Paper sx={{...PaperTheme, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <AvatarInput/>
                    <ListItem sx={{mt: 2}}>
                        <ListItemText primary={"Email verifié"}
                                      secondary={'La désactivation de cette option enverra automatiquement à l\'utilisateur un e-mail de vérification'}/>
                        <ListItemIcon>
                            <Switch defaultChecked/>
                        </ListItemIcon>
                    </ListItem>
                </Paper>
            </Grid>
            <Grid container item xs={8}>
                <Paper sx={{...PaperTheme}}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Prénom</Typography>
                            <TextField required value={firstName}
                                       onChange={(event: any) => setFirstName(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'Prénom'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Nom</Typography>
                            <TextField required value={lastName}
                                       onChange={(event: any) => setLastName(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'Nom'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>E-mail</Typography>
                            <TextField required value={email} onChange={(event: any) => setEmail(event.target.value)}
                                       type={'email'}
                                       fullWidth variant={'outlined'} placeholder={'john.doe@gmail.com'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Numéro de
                                télephone</Typography>
                            <TextField value={phoneNumber} onChange={(event: any) => setPhoneNumber(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'00 00 00 00 00'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Tag</Typography>
                            <TextField value={tag} onChange={(event: any) => setTag(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'CEO, CTO, ...'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Role</Typography>
                            <FormControl fullWidth sx={{mt: 0.5, ...TextFieldTheme}}>
                                <Select placeholder="Employé" defaultValue={'WORKER'} value={role}
                                        onChange={(event: any) => setRole(event.target.value)}
                                >
                                    <MenuItem value={'WORKER'}>Employé</MenuItem>
                                    <MenuItem value={'ADMIN'}>Administrateur</MenuItem>
                                    <MenuItem value={'SUPER_ADMIN'}>Super administrateur</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'}
                                        color={'text.primary'}>Bureaux</Typography>
                            <MultiAutocompleteUrl disabled={isOfficeMode}
                                                  url={`${process.env.REACT_APP_API_URL}/api/offices`} value={offices}
                                                  onChange={(value: any[] | undefined) => setOffices(value)}
                                                  renderOption={(option: any) => option.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'body2'} fontWeight={'bold'} color={'text.primary'}>Nombre de
                                congés</Typography>
                            <TextField value={numberOfHolidays} onChange={(event: any) => setNumberOfHolidays(event.target.value)}
                                       fullWidth variant={'outlined'} placeholder={'Nombre de congés'}
                                       sx={{mt: 0.5, ...TextFieldTheme}}/>
                        </Grid>
                        <Grid container item xs={8} justifyContent={'start'} alignItems={'center'}>
                            <FormControlLabel control={<Checkbox checked={
                                isFormDaily
                            } onChange={(event: any) => setIsFormDaily(event.target.checked)
                            }/>} label={
                                "Besoin du formulaire journalier de temps de travail"
                            }/>
                        </Grid>
                        <Grid container item xs={4} justifyContent={'end'}>
                            <LoadingButton loading={isLoading} variant="contained" color="primary" type={'submit'}
                                           sx={{mt: 2}}>Sauvegarder</LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    </Box>
}