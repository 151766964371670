import {PaperTheme} from "../../themes/themes";
import {Paper, Typography} from "@mui/material";
import { LineChart } from "@mui/x-charts";

const allMonthsNameFr = [
    'Janv', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin',
    'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec'
];

interface CardPlanningProps {
    data: any[]
}

export default function CardBilan({data}: CardPlanningProps) {
    return <Paper variant={'outlined'} sx={{...PaperTheme, boxShadow: 0, height: '300px'}}>
        <Typography variant={'body1'} fontWeight={'bold'} sx={{mb: 1}}>
            Bilan heures de travail, heures de repos sur l'annee
        </Typography>
        <LineChart
            series={data}
            xAxis={[{ data: allMonthsNameFr, scaleType: 'band' }]}
        />
    </Paper>
}