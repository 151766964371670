import {
    Avatar,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemAvatar, ListItemText,
    TextField
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import FileInput from "../../tools/input/FileInput";
import {useSnackBar} from "../../tools/context/SnackBar";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import MultiAutocompleteUrl from "../../tools/input/MultiAutocompleteUrl";
import {useUser} from "../../tools/context/User";

interface DialogNewsProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    news: any;
}

export default function DialogNews({open, onClose, news}: DialogNewsProps) {
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [users, setUsers] = useState<any[]>([])
    const [file, setFile] = useState<{
        name: string;
        data: string;
    } | null>(null)
    const handleError = useHandleError();
    const msg = useSnackBar()
    const user = useUser()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (news && open) {
            setTitle(news.title)
            setContent(news.content)
            setUsers(news.users.map((user: any) => ({
                ...user.user,
                seen: user.seen
            })))
            /* if (news.file) {
                setFile({
                    name: news.file,
                    data: `${process.env.REACT_APP_API_URL}/api/news/${news.id}/file`
                })
            } */
        }
    }, [news, open]);

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setTitle('');
        setContent('');
        setFile(null);
        setUsers([])
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            setIsLoading(true)
            if (news) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/news/${news.id}`, {
                    title,
                    content,
                    users,
                    file
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                msg('News modifiée avec succès', 'success')
                handleClose(true)
                setIsLoading(false)
                return;
            }
            await axios.post(`${process.env.REACT_APP_API_URL}/api/news`, {
                title,
                content,
                users,
                file
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            msg('News créée avec succès', 'success')
            handleClose(true)
            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false)
            handleError(error)
        }
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)} component={'form'} onSubmit={handleSubmit} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                {news ? news.title : 'Nouvelle news'}
            </DialogTitle>
            <DialogContent>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <TextField disabled={
                            user && user.role !== 'SUPER_ADMIN'
                        } label={'Titre'} required fullWidth value={title} onChange={(event: any) => setTitle(event.target.value)}
                                   variant={'outlined'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField disabled={
                            user && user.role !== 'SUPER_ADMIN'
                        } label={'Contenu'} required fullWidth value={content} onChange={(event: any) => setContent(event.target.value)}
                                   variant={'outlined'} multiline rows={5}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FileInput disabled={
                            user && user.role !== 'SUPER_ADMIN'
                        } value={file} onChange={(value: {
                            name: string;
                            data: string;
                        }) => {
                            setFile(value)
                        }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <MultiAutocompleteUrl disabled={user && user.role !== 'SUPER_ADMIN'}
                                              url={`${process.env.REACT_APP_API_URL}/api/users`} value={users}
                                              onChange={(value: any[] | undefined) => setUsers(value ? value : [])}
                                              label={'Utilisateurs'}
                                              optionLabel={(option: any) => option.firstName + ' ' + option.lastName + ' - ' + (option.seen ? 'Vu' : 'Non vu')}
                                              renderOption={(option: any) => <ListItem sx={{p: 0}}>
                                                  <ListItemAvatar>
                                                      <Avatar src={option.avatar}/>
                                                  </ListItemAvatar>
                                                  <ListItemText primary={option.firstName + ' ' + option.lastName}
                                                                secondary={option.tag}/>
                                                  {option.holidays && option.holidays.length > 0 &&
                                                      <Chip label={option.holidays[0].reason}/>}
                                              </ListItem>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    Fermer
                </Button>
                <LoadingButton loading={isLoading} type={'submit'} color="primary" variant={'contained'}>
                    Sauvegarder
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}