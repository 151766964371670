import {Avatar, Box, Typography} from "@mui/material";
import {AddAPhoto} from "@mui/icons-material";
import {useState} from "react";

export default function AvatarInput(): JSX.Element {
    const [avatar, setAvatar] = useState<string>('');

    function handleFileInputChange(event: any){
        const file = event.target.files[0];
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    return <Box>
        <Box sx={{width: 144, height: 144, border: 1, borderRadius: 50, borderStyle: 'dashed', p: 1}}>
            <label htmlFor="image-upload" style={{width: '100%', height: '100%', cursor: 'pointer'}}>
                {avatar ? (
                    <Avatar
                        alt="Selected Image"
                        src={avatar}
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: 'background.default'
                        }}
                    />
                ) : (
                    <Avatar
                        alt="Remy Sharp"
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: 'background.default'
                        }}
                    >
                        <AddAPhoto sx={{fontSize: 35, mb: 1}} color={'primary'}/>
                        <Typography variant={'body2'} color={'primary'}>Ajouter une photo</Typography>
                    </Avatar>
                )}
            </label>
            <input
                id="image-upload"
                type="file"
                accept=".jpeg, .jpg, .png"
                style={{display: 'none'}}
                onChange={handleFileInputChange}
            />
        </Box>
        <Typography sx={{mt: 1}} variant={'caption'} color={'text.secondary'}>Autorisé *.jpeg, *.jpg,
            *.png</Typography>
    </Box>
}