export default function getTranslateStatus(status: string): string {
    switch (status) {
        case 'PENDING':
            return 'En attente';
        case 'SAVED':
            return 'Sauvegardé';
        case 'EDITED':
            return 'Modifier par un administrateur';
        default:
            return 'En attente';
    }
}