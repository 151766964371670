import React from "react";
import {useState} from "react";
import {IconButton, Popover, Typography} from "@mui/material";
import {Description} from "@mui/icons-material";

interface NotesProps {
    notes: string;
    size?: "small" | "medium";
}

export default function Notes({notes, size = 'medium'}: NotesProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return <React.Fragment>
        <IconButton onClick={handleClick} size={size}>
            <Description fontSize={size}/>
        </IconButton>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Typography sx={{ p: 1 }}>{notes}</Typography>
        </Popover>
    </React.Fragment>
}