import {useEffect, useState} from "react";
import axios from "axios";
import {useSnackBar} from "../context/SnackBar";
import {useHandleError} from "../context/ErrorHandler";
import {Button} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function CardImg({src}: any) {
    const [bs64, setBs64] = useState<any>(null)
    const msg = useSnackBar();
    const handleError = useHandleError();

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/download/file?file=${src}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                setBs64(response.data)
            } catch (err) {
                setBs64(null)
            }
        })()

    }, [src]);

    const downloadFile = async () => {
        try {

            // Créer un lien temporaire
            const link = document.createElement('a');

            // Définir le contenu du lien comme le fichier Base64
            link.href = bs64;

            // Définir le nom du fichier pour le téléchargement
            link.download = src.split('/').pop() || '';

            // Ajouter le lien au document
            document.body.appendChild(link);

            // Simuler un clic sur le lien pour lancer le téléchargement
            link.click();

            // Supprimer le lien du document
            document.body.removeChild(link);
            msg('Fichier téléchargé avec succès', 'success');
        } catch (err) {
            handleError(err);
        }
    };

    if (!bs64) {
        return null;
    }

    return <Button onClick={downloadFile} color={'inherit'} fullWidth variant={'outlined'}>
        <AttachFileIcon sx={{
            fontSize: 70
        }}/>
    </Button>
}