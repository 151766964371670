import {Box, Breadcrumbs, Button, Grid, IconButton, Link, Typography} from "@mui/material";
import useSWR from "swr";
import fetchWithToken from "../../lib/fetch/fetchWithToken";
import {useNavigate} from "react-router-dom";
import DataTable, {ColumnProps} from "../tools/display/DataTable";
import React, {useMemo, useState} from "react";
import getTotalsHour from "../../lib/time/getTotalsHour";
import Notes from "../tools/display/TableCell/Notes";
import getTranslateStatus from "../../lib/translate/statusDailyForm";
import {Add, Edit} from "@mui/icons-material";
import DialogDailyFormEdit from "./dialog/DialogDailyFormEdit";

interface DailyFormByUserProps {
    user: any;
}

export default function DailyFormByUser({user}: DailyFormByUserProps): JSX.Element {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/users/dailyForms?idUser=${user.id}`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const [openDialog, setOpenDialog] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<any>(null);

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Titre',
            format: (row: any) => row.title,
            searchDefaultValue: (row: any) => row.title
        },
        {
            label: 'Immatriculation',
            format: (row: any) => row.plateNumber,
            searchDefaultValue: (row: any) => row.plateNumber,
        },
        {
            label: 'Total des heures de travail',
            format: (row: any) => row.timeDriving && row.timeWorking ?
                <Typography variant={'body2'} color={'text.primary'}>
                    {getTotalsHour(
                        [row.timeDriving, row.timeWorking]
                    ).split(':')[0]} heures et {getTotalsHour(
                    [row.timeDriving, row.timeWorking]
                ).split(':')[1]} minutes
                </Typography> : '-',
        },
        {
            label: 'Total des heures de repos',
            format: (row: any) => row.timeRest ? <Typography variant={'body2'} color={'text.primary'}>
                {getTotalsHour(
                    [row.timeRest]
                ).split(':')[0]} heures et {getTotalsHour(
                [row.timeRest]
            ).split(':')[1]} minutes
            </Typography> : '-',
        },
        {
            label: 'Commentaire',
            format: (row: any) => row.comment ? <Notes notes={row.comment}/> : '-',
        },
        {
            label: 'Status',
            format: (row: any) => getTranslateStatus(row.status),
            searchDefaultValue: (row: any) => getTranslateStatus(row.status),
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton onClick={() => {
                    setDataToEdit(row)
                    setOpenDialog(true)
                }}>
                    <Edit/>
                </IconButton>
            </Box>
        }
    ], [])


    return <Box width={'100%'}>
        <Grid container item xs={12} justifyContent={'end'} alignItems={'center'} sx={{mb: 2}}>
            <Button variant="contained" color="primary" startIcon={<Add/>}
                    onClick={() => {
                        setOpenDialog(true)
                    }}>
                Ajouter un feuillet quotidien
            </Button>
        </Grid>
        <DataTable isLoading={isLoading}
                   isError={error}
                   data={data ? data : []}
                   columns={columns}
                   targetLabel={'Status'}/>
        <DialogDailyFormEdit open={openDialog} onClose={
            (isToReload: boolean) => {
                setOpenDialog(false)
                setDataToEdit(null)
                if (isToReload) {
                    window.location.reload()
                }
            }
        } data={dataToEdit} user={user}/>
    </Box>
}