import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import {Box, Breadcrumbs, Grid, IconButton, Link, Typography, useMediaQuery} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React, {useMemo} from "react";
import DataTable, {ColumnProps} from "../../../components/tools/display/DataTable";
import moment from "moment/moment";
import Notes from "../../../components/tools/display/TableCell/Notes";
import {Visibility} from "@mui/icons-material";
import getTranslateStatus from "../../../lib/translate/statusDailyForm";
import getTotalsHour from "../../../lib/time/getTotalsHour";

export default function DailyForm() {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/users/me/dailyForms`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Titre',
            format: (row: any) => row.title,
            searchDefaultValue: (row: any) => row.title
        },
        {
            label: 'Immatriculation',
            format: (row: any) => row.plateNumber,
            searchDefaultValue: (row: any) => row.plateNumber,
        },
        {
            label: 'Total des heures de travail',
            format: (row: any) => row.timeDriving && row.timeWorking ?
                <Typography variant={'body2'} color={'text.primary'}>
                    {getTotalsHour(
                        [row.timeDriving, row.timeWorking]
                    ).split(':')[0]} heures et {getTotalsHour(
                    [row.timeDriving, row.timeWorking]
                ).split(':')[1]} minutes
                </Typography> : '-',
        },
        {
            label: 'Total des heures de repos',
            format: (row: any) => row.timeRest ? <Typography variant={'body2'} color={'text.primary'}>
                {getTotalsHour(
                    [row.timeRest]
                ).split(':')[0]} heures et {getTotalsHour(
                [row.timeRest]
            ).split(':')[1]} minutes
            </Typography> : '-',
        },
        {
            label: 'Commentaire',
            format: (row: any) => row.comment ? <Notes notes={row.comment}/> : '-',
        },
        {
            label: 'Status',
            format: (row: any) => getTranslateStatus(row.status),
            searchDefaultValue: (row: any) => getTranslateStatus(row.status),
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton disabled={
                    row.timeDriving !== null || row.timeWorking !== null || row.timeRest !== null
                } onClick={() => navigate('/app/dailyForm/' + row.id)}>
                    <Visibility/>
                </IconButton>
            </Box>
        }
    ], [navigate])

    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2} pb={10}>
            <Grid item xs={12}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>
                    Mes formulaires journaliers
                </Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Formulaires journaliers
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <DataTable isLoading={isLoading}
                           isError={error}
                           data={data ? data : []}
                           columns={columns}
                           mobileItem={(row: any) => <Grid container item xs={12} spacing={2}>
                               <Grid item xs={12}>
                                   <Typography variant={'h6'} color={'text.primary'}>{row.title}</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'caption'} color={'action.disabled'}>
                                       {moment(row.createdAt).format('DD/MM/YYYY')}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body2'} color={'text.primary'}>Immatriculation</Typography>
                                   <Typography variant={'body2'} color={'text.secondary'}>{row.plateNumber}</Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body2'} color={'text.primary'}>Total des heures de
                                       travail</Typography>
                                   <Typography variant={'body2'} color={'text.secondary'}>
                                       {getTotalsHour(
                                           [row.timeDriving, row.timeWorking]
                                       ).split(':')[0]} heures et {getTotalsHour(
                                       [row.timeDriving, row.timeWorking]
                                   ).split(':')[1]} minutes
                                   </Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body2'} color={'text.primary'}>Total des heures de
                                       repos</Typography>
                                   <Typography variant={'body2'} color={'text.secondary'}>
                                       {getTotalsHour(
                                           [row.timeRest]
                                       ).split(':')[0]} heures et {getTotalsHour(
                                       [row.timeRest]
                                   ).split(':')[1]} minutes
                                   </Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body2'} color={'text.primary'}>Commentaire</Typography>
                                   <Typography variant={'body2'} color={'text.secondary'}>
                                       {row.comment ? row.comment : '-'}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography variant={'body2'} color={'text.primary'}>Status</Typography>
                                   <Typography variant={'body2'} color={'text.secondary'}>
                                       {getTranslateStatus(row.status)}
                                   </Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <IconButton disabled={
                                       row.timeDriving !== null || row.timeWorking !== null || row.timeRest !== null
                                   } onClick={() => navigate('/app/dailyForm/' + row.id)}>
                                       <Visibility/>
                                   </IconButton>
                               </Grid>
                           </Grid>}
                           targetLabel={'Status'}/>
            </Grid>
        </Grid>
    </Box>
}