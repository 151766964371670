import {Paper, Typography} from "@mui/material";
import moment from "moment/moment";
import React from "react";

export default function CalendarEvent({event}: any) {
    // Hauteur maximale que le Paper peut contenir
    const maxHeight = 200; // Modifier selon vos besoins

    // Calcul de la hauteur totale des éléments
    const totalHeight = 40 + 20 + (event.data.users.length * 24); // Taille typique d'un Avatar est de 24px

    // Vérifier si tous les éléments peuvent tenir dans le Paper
    const canFit = totalHeight <= maxHeight;

    // Afficher uniquement les éléments affichables si tout ne peut pas tenir
    const displayUsers = canFit ? event.data.users : event.data.users.slice(0, Math.floor((maxHeight - 60) / 24));
    const displayOffices = canFit ? event.data.offices : event.data.offices.slice(0, Math.floor((maxHeight - 60) / 24));


    return (
        <Paper
            sx={{
                width: '100%',
                height: '100%',
                p: 0.5,
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'start',
                flexDirection: 'column',
                overflow: 'hidden',
                background: event.data.color,
            }}
        >
            <Typography variant={'subtitle1'} color={'text.primary'}>
                {event.title}
            </Typography>
            {displayOffices.map((user: any) => (
                <Typography key={user.id} variant={'subtitle2'}>{user.name}</Typography>
            ))}
            {displayUsers.map((user: any) => (
                <Typography key={user.id} variant={'subtitle2'}>{user.firstName + ' ' + user.lastName}</Typography>
            ))}
            <Typography variant={'caption'} color={'text.primary'}>
                {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
            </Typography>
            {!canFit && (
                <Typography variant="body2" color="text.secondary">
                    ...et plus
                </Typography>
            )}
        </Paper>
    );
}
