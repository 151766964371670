import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem, ListItemText
} from "@mui/material";
import axios from "axios";
import {useHandleError} from "../../tools/context/ErrorHandler";
import {useSnackBar} from "../../tools/context/SnackBar";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import moment from "moment";

interface DialogHolidaysEventErrorProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    events?: any[];
    holidays?: any | null;
}

export default function DialogHolidaysEventError({open, onClose, events = [], holidays = null}: DialogHolidaysEventErrorProps) {
    const handleError = useHandleError();
    const msg = useSnackBar();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        if (!holidays) {
            return;
        }
        try {
            setIsLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}/api/company/holidays/${holidays.id}`, {
                status: 'ACCEPTED',
                events
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Congé accepté avec succès !', 'success');
            handleClose(true);
            setIsLoading(false)
        } catch (err: any) {
            handleError(err);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            fullWidth
            maxWidth={'sm'}
            component={'form'}
            onSubmit={handleSubmit}
        >
            <DialogTitle>Attention</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Vous ne pouvez accepter ce congé car il y a des événements qui se chevauchent.
                    Voulez-vous tout de même accepter ce congé ?
                    Si vous abceptez, {holidays ? holidays.user.firstName + " " + holidays.user.lastName : ''} sera retirer des événements suivants:
                </DialogContentText>
                <List dense>
                    {events?.map((event: any) => {
                        return <ListItem key={event.id} disablePadding>
                            <ListItemText  primary={event.title} secondary={
                                `${moment(event.start).format('DD/MM/YYYY HH:mm')} - ${moment(event.end).format('DD/MM/YYYY HH:mm')}`
                            } />
                        </ListItem>
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>
                    Annuler
                </Button>
                <LoadingButton loading={isLoading} variant={'contained'} type={'submit'}>
                    Sauvegarder
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}