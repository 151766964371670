import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel
} from "@mui/material";
import {useState} from "react";
import {Alert, LoadingButton} from "@mui/lab";
import axios from "axios";
import {useSnackBar} from "../context/SnackBar";
import {useHandleError} from "../context/ErrorHandler";

interface DialogConfirmationDeleteProps {
    open: boolean;
    onClose: (isToReload: boolean) => void;
    url: string;
}

export default function DialogConfirmationDelete({ open, onClose, url }: DialogConfirmationDeleteProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const msg = useSnackBar();
    const handleError = useHandleError();

    async function deleteItem() {
        try {
            setIsLoading(true);
            await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            });
            msg('L\'élément a bien été supprimé', 'success');
            handleClose(true);
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            handleError(err);
        }
    }

    function handleClose(isToReload: boolean) {
        onClose(isToReload);
        setIsConfirmed(false);
    }

    return (
        <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth={'sm'}>
            <DialogTitle>Confirmation de suppression</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Etes-vous sûr de vouloir supprimer cet élément ?
                </DialogContentText>
                <Alert severity="warning" sx={{mt: 1}}>Cette action est irréversible</Alert>
            </DialogContent>
            <DialogActions>
                <FormControlLabel control={<Checkbox checked={isConfirmed} onClick={() => setIsConfirmed(!isConfirmed)} />} label="Confirmation" />
                <LoadingButton disabled={!isConfirmed} variant={'contained'} loading={isLoading} onClick={() => deleteItem()} color="primary">
                    Supprimer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}