import {useEffect, useState} from "react";
import getStartAndEndOfMonth from "../../lib/time/getStartAndEndOfMonth";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import dayjs from "dayjs";
import DialogNewDateRange from "./dialog/DialogNewDateRange";
import {useHandleError} from "../tools/context/ErrorHandler";
import axios from "axios";
import {BarChart, LineChart} from "@mui/x-charts";
import {PaperTheme} from "../../themes/themes";
import React from "react";

interface UserAnalyseProps {
    user: any;
}

export default function UserAnalyse({user}: UserAnalyseProps): JSX.Element {
    const [range, setRange] = useState([
        {
            ...getStartAndEndOfMonth(),
            color: 'blue'
        }
    ])
    const [openRange, setOpenRange] = useState(false);
    const handleError = useHandleError();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                const response: any = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/${user.id}/analytics`, {
                    range
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setData(response.data);

                setIsLoading(false);
            } catch (err: any) {
                setIsLoading(false);
                handleError(err);
            }
        })();
    }, [handleError, range, user]);


    return <Grid container item xs={12} spacing={1} sx={{pb: 3}}>
        <Grid container item xs={12}>
            {range.map((r, index) => {
                return <Grid alignItems={'center'} container item xs={2.3} justifyContent={'space-around'} key={index}
                             component={Paper} variant={'outlined'} sx={{p: 0.5, mr: 1, cursor: 'pointer'}} onClick={
                    () => {
                        setRange(range.filter((_, i) => i !== index))
                    }
                }>
                    <Box sx={{
                        backgroundColor: r.color,
                        width: 15,
                        height: 15,
                        borderRadius: 50,
                    }}/>
                    <Typography>
                        {dayjs(r.startDate).format('DD/MM/YYYY')} - {dayjs(r.endDate).format('DD/MM/YYYY')}
                    </Typography>
                </Grid>
            })}
            <Grid item xs={2.3}>
                <Button sx={{
                    textTransform: 'none'
                }} onClick={() => {
                    setOpenRange(true)
                }}>
                    + Ajouter une periode d'etude
                </Button>
            </Grid>
        </Grid>
        {!isLoading && data !== null && range.length > 0 && <React.Fragment>
            <Grid item xs={4}>
                <Paper sx={{...PaperTheme}}>
                    <Typography variant={'h6'} color={'text.primary'} sx={{
                        mb: 1
                    }}>Temps de travail global</Typography>
                    <BarChart
                        series={data.barWorkGlobal.series}
                        height={290}
                        xAxis={[{data: [''], scaleType: 'band'}]}
                        margin={{top: 10, bottom: 30, left: 40, right: 10}}
                        title={'Analyse temps de repos'}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                position: {vertical: 'bottom', horizontal: 'middle'},
                                hidden: true,
                                padding: 0,
                            },
                        }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper sx={{...PaperTheme}}>
                    <Typography variant={'h6'} color={'text.primary'} sx={{
                        mb: 1
                    }}>Temps de repos global</Typography>
                    <BarChart
                        series={data.barRestGlobal.series}
                        height={290}
                        xAxis={[{data: [''], scaleType: 'band'}]}
                        margin={{top: 10, bottom: 30, left: 40, right: 10}}
                        title={'Analyse temps de repos'}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                position: {vertical: 'bottom', horizontal: 'middle'},
                                hidden: true,
                                padding: 0,
                            },
                        }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper sx={{...PaperTheme}}>
                    <Typography variant={'h6'} color={'text.primary'} sx={{
                        mb: 1
                    }}>Nombre de repas global</Typography>
                    <BarChart
                        series={data.barMealGlobal.series}
                        height={290}
                        xAxis={[{data: range.map((item: any) => ''), scaleType: 'band'}]}
                        margin={{top: 10, bottom: 30, left: 40, right: 10}}
                        title={'Analyse temps de repos'}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                position: {vertical: 'bottom', horizontal: 'middle'},
                                hidden: true,
                                padding: 0,
                            },
                        }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{...PaperTheme}}>
                    <Typography variant={'h6'} color={'text.primary'} sx={{
                        mb: 1
                    }}>Analyse temps de travail durant periode</Typography>
                    <LineChart
                        series={data.linearWorkTime.data}
                        height={290}
                        xAxis={[{data: data.linearWorkTime.dataAxis, scaleType: 'band'}]}
                        margin={{top: 10, bottom: 30, left: 40, right: 20}}
                        title={'Analyse temps de repos'}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                position: {vertical: 'bottom', horizontal: 'middle'},
                                hidden: true,
                                padding: 0,
                            },
                        }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{...PaperTheme}}>
                    <Typography variant={'h6'} color={'text.primary'} sx={{
                        mb: 1
                    }}>Analyse temps de repos durant periode</Typography>
                    <LineChart
                        series={data.linearRestTime.data}
                        height={290}
                        xAxis={[{data: data.linearRestTime.dataAxis, scaleType: 'band'}]}
                        margin={{top: 10, bottom: 30, left: 40, right: 20}}
                        title={'Analyse temps de repos'}
                        slotProps={{
                            legend: {
                                direction: 'row',
                                hidden: true,
                                position: {vertical: 'bottom', horizontal: 'middle'},
                                padding: 0,
                            },
                        }}
                    />
                </Paper>
            </Grid>
        </React.Fragment>}
        <DialogNewDateRange open={openRange} onClose={(isToReload, data) => {
            if (isToReload && data !== null) {
                setRange([
                    ...range,
                    data
                ])
            }
            setOpenRange(false);
        }}/>
    </Grid>
}