import {
    Avatar,
    Box,
    Breadcrumbs, Button,
    Chip,
    Grid,
    IconButton,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import moment from "moment/moment";
import {Add, Check, Close} from "@mui/icons-material";
import DataTable, {ColumnProps} from "../../../components/tools/display/DataTable";
import Notes from "../../../components/tools/display/TableCell/Notes";
import {useSnackBar} from "../../../components/tools/context/SnackBar";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import axios from "axios";
import {useOffice} from "../../../components/tools/context/Office";
import DialogHolidaysEventError from "../../../components/holidays/dialog/DialogHolidaysEventError";
import DialogRefuse from "../../../components/holidays/dialog/DialogRefuse";
import DialogHolidaysCreate from "../../../components/holidays/dialog/DialogHolidaysCreate";

interface CheckHolidaysProps {
    isOfficeMode?: boolean;
}

export default function CheckHolidays({isOfficeMode}: CheckHolidaysProps): JSX.Element {
    const office = useOffice();
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/company/holidays${isOfficeMode ? `?officeId=${office.id}` : ''}`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const navigate = useNavigate();
    const msg = useSnackBar();
    const handleError = useHandleError();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [eventsToCheck, setEventsToCheck] = useState<any[]>([])
    const [holiday, setHoliday] = useState<any>(null)
    const [openDialogRefuse, setOpenDialogRefuse] = useState<boolean>(false);
    const [idToRefuse, setIdToRefuse] = useState<string | null>(null);
    const [openDialogHolidaysCreate, setOpenDialogHolidaysCreate] = useState<boolean>(false)

    function translateStatus(status: string, isDeleted: boolean) {
        if (isDeleted) {
            return 'Supprimé';
        }
        switch (status) {
            case 'PENDING':
                return 'En attente';
            case 'ACCEPTED':
                return 'Accepté';
            case 'REFUSED':
                return 'Refusé';
            case 'CANCELLED':
                return 'Annulé';
            default:
                return 'En attente';
        }
    }

    function translateStatusElement(status: string, isDeleted: boolean): JSX.Element {
        if (isDeleted) {
            return <Chip color={'error'} label={'Supprimé'} sx={{borderRadius: 1}}/>;
        }
        switch (status) {
            case 'PENDING':
                return <Chip color={'info'} label={'En attente'} sx={{borderRadius: 1}}/>;
            case 'ACCEPTED':
                return <Chip color={'success'} label={'Accepté'} sx={{borderRadius: 1}}/>;
            case 'REFUSED':
                return <Chip color={'error'} label={'Refusé'} sx={{borderRadius: 1}}/>;
            case 'CANCELLED':
                return <Chip color={'warning'} label={'Annulé'} sx={{borderRadius: 1}}/>;
            default:
                return <Chip color={'info'} label={'En attente'} sx={{borderRadius: 1}}/>;
        }
    }

    const handleSubmit = useCallback(async (id: any, status: string) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/company/holidays/${id}`, {
                status
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Congé accepté avec succès !', 'success');
            window.location.reload();
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setEventsToCheck(err.response.data.events);
                setOpenDialog(true);
            } else
                handleError(err);
        }
    }, [handleError, msg]);

    const downloadFile = async (file: string) => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/download/file?file=${file}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            // Créer un lien temporaire
            const link = document.createElement('a');

            // Définir le contenu du lien comme le fichier Base64
            link.href = response.data;

            // Définir le nom du fichier pour le téléchargement
            link.download = file.split('/').pop() || '';

            // Ajouter le lien au document
            document.body.appendChild(link);

            // Simuler un clic sur le lien pour lancer le téléchargement
            link.click();

            // Supprimer le lien du document
            document.body.removeChild(link);
        } catch (err) {
            handleError(err);
        }
    };

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Reason',
            format: (row: any) => row.reason.reason,
            searchDefaultValue: (row: any) => row.reason.reason
        },
        {
            label: 'Date de début',
            format: (row: any) => moment(row.start).format("DD/MM/YYYY"),
            searchDefaultValue: (row: any) => moment(row.start).format("DD/MM/YYYY"),
        },
        {
            label: 'Date de fin',
            format: (row: any) => moment(row.end).format("DD/MM/YYYY"),
            searchDefaultValue: (row: any) => moment(row.end).format("DD/MM/YYYY"),
        },
        {
            label: 'Nom',
            format: (row: any) => <ListItem sx={{p: 0}}>
                <ListItemIcon>
                    <Avatar/>
                </ListItemIcon>
                <ListItemText primary={row.user.firstName + " " + row.user.lastName} secondary={row.user.email}/>
            </ListItem>,
            searchDefaultValue: (row: any) => row.user.firstName + " " + row.user.lastName
        },
        {
            label: 'Commentaire',
            format: (row: any) => row.comment ? <Notes notes={row.comment}/> : <em>-</em>,
        },
        {
            label: 'Commentaire de refus',
            format: (row: any) => row.refuseComment ? <Notes notes={row.refuseComment}/> : <em>-</em>,
        },
        {
            label: 'Fichier',
            format: (row: any) => row.file ? <Link target={'_blank'} onClick={() => {
                    downloadFile(row.file);
                }}>{row.file.split('/').pop() || ''}</Link> :
                <em>-</em>,
        },
        {
            label: 'Status',
            format: (row: any) => translateStatusElement(row.status, row.deleted),
            searchDefaultValue: (row: any) => translateStatus(row.status, row.deleted)
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton disabled={row.status === "ACCEPTED"} onClick={async () => {
                    setHoliday(row)
                    if (row.id)
                        await handleSubmit(row.id, 'ACCEPTED');
                }}>
                    <Check color={row.status === "ACCEPTED" ? undefined : 'success'}/>
                </IconButton>
                <IconButton disabled={row.status === "REFUSED"} onClick={async () => {
                    if (row.id) {
                        setIdToRefuse(row.id);
                        setOpenDialogRefuse(true);
                    }
                }}>
                    <Close color={row.status === "REFUSED" ? undefined : 'error'}/>
                </IconButton>
            </Box>
        }
    ], [handleSubmit])

    return <Box width={'100%'} height={'100%'}>
        <Grid container item xs={12} spacing={2} mb={2}>
            <Grid item xs={6}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Approbation de congés</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Approbation de congés
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid container item xs={6} justifyContent={'end'} alignItems={'center'}>
                <Button variant="contained" color="primary" startIcon={<Add/>}
                        onClick={() => {
                            setOpenDialogHolidaysCreate(true)
                        }}>
                    Enregistrer un congés
                </Button>
            </Grid>
        </Grid>
        <DataTable isLoading={isLoading}
                   isError={error}
                   data={data ? data : []}
                   columns={columns}
                   targetLabel={'Status'}/>
        <DialogHolidaysEventError onClose={
            (isToReload: boolean) => {
                setOpenDialog(false);
                setHoliday(null);
                setEventsToCheck([])
                if (isToReload) {
                    window.location.reload();
                }
            }
        } open={openDialog} events={eventsToCheck} holidays={holiday}/>
        <DialogRefuse open={openDialogRefuse} onClose={
            (isToReload: boolean) => {
                setOpenDialogRefuse(false);
                setIdToRefuse(null);
                if (isToReload) {
                    window.location.reload();
                }
            }
        } id={idToRefuse}/>
        <DialogHolidaysCreate open={openDialogHolidaysCreate} onClose={(isToReload: boolean) => {
            setOpenDialogHolidaysCreate(false);
            if (isToReload) {
                window.location.reload();
            }
        }}/>
    </Box>;
}