import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {useState} from "react";

interface TimeDurationProps {
    value: any;
    onChange: (value: any) => void;
}

export default function TimeDuration({value, onChange}: TimeDurationProps) {
    return <Grid container item xs={12} justifyContent={'center'} alignItems={'center'} sx={{mt: 1}}>
        <Grid item xs={5.3}>
            <FormControl variant="outlined" fullWidth size={'small'}>
                <InputLabel>Hrs</InputLabel>
                <Select
                    id="hours"
                    label="Heures"
                    value={
                        value ? value.split(':')[0] : 0
                    }
                    onChange={(event) => {
                        onChange(
                            `${event.target.value}:${
                                value ? value.split(':')[1] : 0
                            }`
                        );
                    }}
                >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                            {hour}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item>
            <Typography variant={'h6'} color={'text.primary'} sx={{mx: 1}}>:</Typography>
        </Grid>
        <Grid item xs={5.3}>
            <FormControl variant="outlined" fullWidth size={'small'}>
                <InputLabel>Min</InputLabel>
                <Select
                    id="minutes"
                    label="Minutes"
                    value={
                        value ? value.split(':')[1] : 0
                    }
                    onChange={(event) => {
                        onChange(
                            `${
                                value ? value.split(':')[0] : 0
                            }:${event.target.value}`
                        );
                    }}
                >
                    {[0, 15, 30, 45].map((minute) => (
                        <MenuItem key={minute} value={minute}>
                            {minute}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    </Grid>

}