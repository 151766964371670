import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {Box, CircularProgress, Paper, useMediaQuery} from '@mui/material';
import {PaperTheme, useCalendarStyle} from "../../../themes/themes";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import {useHandleError} from "../../../components/tools/context/ErrorHandler";
import React, {useEffect, useState} from "react";
import CalendarEvent from "../../../components/calendar/CalendarEvent";
import {useUser} from "../../../components/tools/context/User";
import {getEndOfWeek, getStartOfWeek} from "../../../lib/planning/week";

export default function Calendar(): JSX.Element {
    const user = useUser()
    const [range, setRange] = useState<{ start: string, end: string }>({start: getStartOfWeek(), end: getEndOfWeek()})
    const handleError = useHandleError();
    const [events, setEvents] = useState<any[]>([]);
    const calendarStyle = useCalendarStyle();
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isToReload, setIsToReload] = useState<boolean>(true);

    useEffect(() => {
        if (!isToReload) {
            return
        }
        (async () => {
            try {
                setLoading(true)
                const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/events?start=${range.start}&end=${range.end}&userId=${user.id}`, localStorage.getItem('token'));
                let updatedEvents = response.map((e: any) => {
                    return {
                        id: e.id.toString(),
                        title: e.title,
                        start: e.start,
                        end: e.end,
                        data: {
                            notes: e.notes,
                            color: e.color,
                            users: e.users,
                            offices: e.offices
                        }
                    }
                });
                setEvents(updatedEvents);
                setIsToReload(false);
                setLoading(false);
            } catch (err: any) {
                setIsToReload(false);
                setLoading(false);
                handleError(err);
            }
        })();
    }, [handleError, range, user, isToReload]);

    return <Paper sx={{ ...PaperTheme, p: 1.5 }}>
        <Box sx={{ ...calendarStyle, maxHeight: '100%', height: isMobile ? '670px' : '760px', position: 'relative' }}>
            <Paper variant={'outlined'} sx={{
                position: 'absolute',
                zIndex: 100,
                top: '50%',
                left: '50%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 10,
                display: (loading) ? 'flex' : 'none',
            }}>
                <CircularProgress sx={{ mb: 2 }} />
                Chargement...
            </Paper>
            <FullCalendar
                datesSet={(info) => {
                    setRange({
                        start: new Date(info.startStr).toISOString(),
                        end: new Date(info.endStr).toISOString()
                    });
                    setIsToReload(true);
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
                headerToolbar={{
                    start: isMobile ? 'prev,next' : 'prev,next today',
                    center: 'title',
                    end: isMobile ? '' : 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                eventContent={(eventInfo) => (
                    <CalendarEvent key={eventInfo.event._def.publicId}
                                   event={events.find((e) => e.id === eventInfo.event._def.publicId)}
                    />
                )}
                events={events}
                editable={false}
                selectable={false}
                locale={'fr'}
                height='100%' // Ensure FullCalendar uses the height of the parent
            />
        </Box>
    </Paper>
}