import {
    Box,
    Breadcrumbs, Button,
    Grid,
    IconButton,
    Link,
    ListItem,
    ListItemText,
    Paper, Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DialogHolidaysTable from "../../../components/settings/company/DialogHolidaysTable";
import {useEffect, useState} from "react";
import useSWR from "swr";
import fetchWithToken from "../../../lib/fetch/fetchWithToken";
import React from "react";
import {Alert} from "@mui/lab";
import {PaperTheme} from "../../../themes/themes";
import {Add, CheckCircle, Delete, DoNotDisturb, Edit} from "@mui/icons-material";
import moment from "moment";
import DialogReason from "../../../components/settings/company/DialogReason";
import DialogConfirmationDelete from "../../../components/tools/dialog/DialogConfirmationDelete";

export default function SettingsHolidays(): JSX.Element {
    const navigate = useNavigate();
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/company/settings/holidays`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const [openDialogCalendarHolliDays, setOpenDialogCalendarHolliDays] = useState<boolean>(false);
    const [idSettingsYear, setIdSettingsYear] = useState<string | null>(null);
    const [newYear, setNewYear] = useState<number>(2024);
    const [listYears, setListYears] = useState<any[]>([]);
    const [openDialogReason, setOpenDialogReason] = useState<boolean>(false);
    const [reasonToEdit, setReasonToEdit] = useState<any>(null);
    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);

    useEffect(() => {
        const lengthYearsConfig = data?.years.length;
        let lastYerConfig = 0
        if (lengthYearsConfig) {
            lastYerConfig = data?.years[lengthYearsConfig - 1].year;
        } else {
            lastYerConfig = new Date().getFullYear() - 1;
        }
        const firstYearConfig = new Date().getFullYear();

        const listYears = [];
        for (let i = lastYerConfig + 1; i < firstYearConfig + 6; i++) {
            listYears.push(i);
        }
        setListYears(listYears);
    }, [data]);

    return (
        <Box width={'100%'} height={'100%'}>
            <Box sx={{mb: 4}}>
                <Typography variant={'h6'} color={'text.primary'} sx={{mb: 1}}>Paramétres des congés</Typography>
                <Breadcrumbs separator="-" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate('/app')}>
                        Dashboard
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate('/app/settings')}
                    >
                        Paramétre
                    </Link>
                    <Link
                        color="text.primary"
                    >
                        Congés
                    </Link>
                </Breadcrumbs>
            </Box>
            {error && <Alert severity={'error'}>Une erreur est survenue</Alert>}
            {isLoading ? <div>
                Loading...
            </div> : <React.Fragment>
                <Typography color={'text.primary'} variant={'h6'}>Configuration des calendriers</Typography>
                <Typography color="text.primary" variant={'body2'}>Gestion et création des plannings des congés par
                    années</Typography>

                <Grid container item xs={12} spacing={1} sx={{mt: 1}}>
                    {data?.years.map((item: any) => {
                        return <Grid item xs={2} key={item.id + "-config"}>
                            <Paper sx={{...PaperTheme, p: 0}}>
                                <ListItem button onClick={() => {
                                    setIdSettingsYear(item.id)
                                    setOpenDialogCalendarHolliDays(true)
                                }} secondaryAction={
                                    <IconButton edge="end" disabled>
                                        <CheckCircle color={'secondary'}/>
                                    </IconButton>
                                }>
                                    <ListItemText primary={item.year}
                                                  secondary={moment(item.updatedAt).format("DD/MM/YYYY HH:mm")}/>
                                </ListItem>
                            </Paper>
                        </Grid>
                    })}
                    {listYears.map((item: any) => {
                        return <Grid item xs={2} key={item}>
                            <Paper sx={{...PaperTheme, p: 0}}>
                                <ListItem button onClick={() => {
                                    setNewYear(item)
                                    setOpenDialogCalendarHolliDays(true)
                                }} secondaryAction={
                                    <IconButton edge="end" disabled>
                                        <DoNotDisturb/>
                                    </IconButton>
                                }>
                                    <ListItemText primary={item} secondary={'Non configuré'}/>
                                </ListItem>
                            </Paper>
                        </Grid>
                    })}
                </Grid>

                <DialogHolidaysTable newYear={newYear} idSettingsYear={idSettingsYear}
                                     open={openDialogCalendarHolliDays} onClose={(isToReload: boolean) => {
                    if (isToReload) {
                        window.location.reload();
                    }
                    setOpenDialogCalendarHolliDays(false)
                }}/>

                <Typography color={'text.primary'} variant={'h6'} sx={{mt: 4}}>Configuration des raisons</Typography>
                <Typography color="text.primary" variant={'body2'}>
                    Gestion des raisons de congés pour les employés
                </Typography>

                <Paper sx={{...PaperTheme, p: 0, mt: 1}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Raison</TableCell>
                                <TableCell>A besoin d'un slot libre</TableCell>
                                <TableCell>A besoin d'un ou plusieurs documents justificatif</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.reasons?.map((item: any) => {
                                return <TableRow key={item.id}>
                                    <TableCell>{item.reason}</TableCell>
                                    <TableCell>{item.needSlots ? 'Oui' : 'Non'}</TableCell>
                                    <TableCell>{item.needDocument ? 'Oui' : 'Non'}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={
                                            () => {
                                                setReasonToEdit(item);
                                                setOpenDialogReason(true);
                                            }
                                        }>
                                            <Edit/>
                                        </IconButton>
                                        <IconButton onClick={
                                            () => {
                                                setReasonToEdit(item);
                                                setOpenDialogDelete(true);
                                            }
                                        }>
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })}
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Button variant={'outlined'} color={'primary'} fullWidth startIcon={
                                        <Add/>
                                    } onClick={() => setOpenDialogReason(true)}>
                                        Ajouter une nouvelle raison
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                <DialogReason open={openDialogReason} onClose={(isToReload: boolean) => {
                    if (isToReload) {
                        window.location.reload();
                    }
                    setOpenDialogReason(false)
                    setReasonToEdit(null);
                }} dataToEdit={reasonToEdit}/>

                <DialogConfirmationDelete open={openDialogDelete} onClose={
                    (isToReload: boolean) => {
                        setOpenDialogDelete(false);
                        setReasonToEdit(null);
                        if (isToReload) {
                            window.location.reload();
                        }
                    }
                } url={
                    `${process.env.REACT_APP_API_URL}/api/company/settings/holidays/reason/${reasonToEdit?.id}`
                }/>

            </React.Fragment>}
        </Box>
    )
}