import {Route, Routes} from "react-router-dom";
import PageNotFound from "../feedback/PageNotFound";

interface EmbedRoutesProps {
    children: React.ReactNode;
}

export default function EmbedRoutes({children}: EmbedRoutesProps): JSX.Element {
    return <Routes>
        {children}
        <Route path={'/*'} element={<PageNotFound/>}/>
    </Routes>;
}