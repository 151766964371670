import {useSnackBar} from "../tools/context/SnackBar";
import {useHandleError} from "../tools/context/ErrorHandler";
import {Box, Chip, IconButton} from "@mui/material";
import React, {useCallback, useMemo} from "react";
import axios from "axios";
import DataTable, {ColumnProps} from "../tools/display/DataTable";
import moment from "moment";
import Notes from "../tools/display/TableCell/Notes";
import {Check, Close} from "@mui/icons-material";

interface HolidaysProps {
    holidays: any[];
}

export default function Holidays({holidays}: HolidaysProps) {
    const msg = useSnackBar();
    const handleError = useHandleError();

    function translateStatus(status: string, isDeleted: boolean) {
        if (isDeleted) {
            return 'Supprimé';
        }
        switch (status) {
            case 'PENDING':
                return 'En attente';
            case 'ACCEPTED':
                return 'Accepté';
            case 'REFUSED':
                return 'Refusé';
            case 'CANCELLED':
                return 'Annulé';
            default:
                return 'En attente';
        }
    }

    function translateStatusElement(status: string, isDeleted: boolean): JSX.Element {
        if (isDeleted) {
            return <Chip color={'error'} label={'Supprimé'} sx={{borderRadius: 1}}/>;
        }
        switch (status) {
            case 'PENDING':
                return <Chip color={'info'} label={'En attente'} sx={{borderRadius: 1}}/>;
            case 'ACCEPTED':
                return <Chip color={'success'} label={'Accepté'} sx={{borderRadius: 1}}/>;
            case 'REFUSED':
                return <Chip color={'error'} label={'Refusé'} sx={{borderRadius: 1}}/>;
            case 'CANCELLED':
                return <Chip color={'warning'} label={'Annulé'} sx={{borderRadius: 1}}/>;
            default:
                return <Chip color={'info'} label={'En attente'} sx={{borderRadius: 1}}/>;
        }
    }

    const handleSubmit = useCallback(async (id: any, status: string) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/company/holidays/${id}`, {
                status
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            msg('Congé accepté avec succès !', 'success');
            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    }, [handleError, msg]);

    const columns: ColumnProps[] = useMemo(() => [
        {
            label: 'Reason',
            format: (row: any) => row.reason,
            searchDefaultValue: (row: any) => row.reason
        },
        {
            label: 'Date de début',
            format: (row: any) => moment(row.start).format("DD/MM/YYYY"),
            searchDefaultValue: (row: any) => moment(row.start).format("DD/MM/YYYY"),
        },
        {
            label: 'Date de fin',
            format: (row: any) => moment(row.end).format("DD/MM/YYYY"),
            searchDefaultValue: (row: any) => moment(row.end).format("DD/MM/YYYY"),
        },
        {
            label: 'Commentaire',
            format: (row: any) => row.comment ? <Notes notes={row.comment}/> : <em>Aucun commentaire renseigné.</em>,
        },
        {
            label: 'Status',
            format: (row: any) => translateStatusElement(row.status, row.deleted),
            searchDefaultValue: (row: any) => translateStatus(row.status, row.deleted)
        },
        {
            label: 'Actions',
            format: (row: any) => <Box>
                <IconButton disabled={row.status === "ACCEPTED"} onClick={async () => {
                    await handleSubmit(row.id, 'ACCEPTED');
                }}>
                    <Check color={'success'}/>
                </IconButton>
                <IconButton disabled={row.status === "REFUSED"} onClick={async () => {
                    await handleSubmit(row.id, 'REFUSED');
                }}>
                    <Close color={'error'}/>
                </IconButton>
            </Box>
        }
    ], [handleSubmit])

    return <DataTable isLoading={false}
                      isError={false}
                      data={holidays ? holidays : []}
                      columns={columns}
                      targetLabel={'Status'}/>;
}