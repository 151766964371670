import {
    Avatar,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    ListItemAvatar, ListItemText, TextField
} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import MultiAutocompleteUrl from "../../tools/input/MultiAutocompleteUrl";
import {TextFieldTheme} from "../../../themes/themes";

interface DialogEventImportPlanningProps {
    open: boolean;
    onClose: () => void;
    data?: any;
    setDataTemplate?: any;
    dataTemplate?: any;
}

export default function DialogEventImportPlanning({open, onClose, data = null, setDataTemplate, dataTemplate}: DialogEventImportPlanningProps) {
    return <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
        <DialogTitle>
            {data ? (data.title ? `[${data.title}] ` : '') + data.dayOfWeek + ' ' + data.date.format('DD/MM/YYYY') : 'Chargement...'}
        </DialogTitle>
        <DialogContent>
            {data ? <Grid container item xs={12} justifyContent={'space-between'} spacing={2} alignItems={'end'} sx={{mt: 1}}>
                <Grid item xs={12}>
                    <MultiAutocompleteUrl
                        url={`${process.env.REACT_APP_API_URL}/api/users?start=${data.date}&end=${data.date}`}
                        value={data.users}
                        label={'Utilisateurs'}
                        onChange={(value: any[] | undefined) => {
                            data.users = value;
                            setDataTemplate([...dataTemplate]);
                        }}
                        optionLabel={(option: any) => option.firstName + ' ' + option.lastName}
                        renderOption={(option: any) => <ListItem sx={{p: 0}}>
                            <ListItemAvatar>
                                <Avatar src={option.avatar}/>
                            </ListItemAvatar>
                            <ListItemText primary={option.firstName + ' ' + option.lastName}
                                          secondary={option.tag}/>
                            {option.holidays && option.holidays.length > 0 &&
                                <Chip label={option.holidays[0].reason}/>}
                        </ListItem>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimePicker sx={
                        {
                            width: '100%',
                            ...TextFieldTheme
                        }
                    } label={'Heure de début'}
                                value={
                        data.startTime
                    } onChange={(value: any) => {
                        data.startTime = value;
                        setDataTemplate([...dataTemplate]);
                    }}/>
                </Grid>
                <Grid item xs={6}>
                    <TimePicker sx={
                        {
                            width: '100%',
                            ...TextFieldTheme
                        }
                    } label={'Heure de fin'}
                                disabled={
                        !data.startTime
                    } minTime={
                        data.startTime
                    } value={
                        data.endTime
                    } onChange={(value: any) => {
                        data.endTime = value;
                        setDataTemplate([...dataTemplate]);
                    }}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField value={
                        data.notes
                    } onChange={(e) => {
                        data.notes = e.target.value;
                        setDataTemplate([...dataTemplate]);
                    }} label={'Notes'} fullWidth multiline rows={3} sx={{
                        ...TextFieldTheme
                    }}/>
                </Grid>
            </Grid> : 'Chargement...'}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Fermer</Button>
        </DialogActions>
    </Dialog>
}