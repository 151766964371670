import {
    Drawer,
    IconButton,
    Typography,
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Avatar
} from "@mui/material";
import {Circle, Close} from "@mui/icons-material";
import fetchWithToken from "../../lib/fetch/fetchWithToken";
import useSWR from "swr";
import dayjs from "dayjs";
import {useEffect} from "react";
import {useHandleError} from "../tools/context/ErrorHandler";
import axios from "axios";
import {useNavigate} from "react-router-dom";

interface DrawerNotificationsProps {
    open: boolean;
    onClose?: () => void;
}

export default function DrawerNotifications({open, onClose}: DrawerNotificationsProps): JSX.Element {
    const {
        data,
        error,
        isLoading
    } = useSWR(`${process.env.REACT_APP_API_URL}/api/users/notifications`, (url: string) => fetchWithToken(url, localStorage.getItem('token')))
    const handleError = useHandleError();
    const navigate = useNavigate();

    function getColor(status: string) {
        switch (status) {
            case 'UNREAD':
                return 'info';
            case 'READ':
                return 'success';
            case 'ARCHIVED':
                return 'warning';
            default:
                return 'info';
        }
    }

    useEffect(() => {
        if (!open) {
            return;
        }
        (async () => {
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications/seen`, {}, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
            } catch (e) {
                handleError(e)
            }
        })();
    }, [handleError, open]);

    return <Drawer
        anchor={"right"}
        open={open}
        onClose={onClose}
        sx={{
            width: 380,
            flexShrink: 0,
            '& .MuiBackdrop-root': {
                bgcolor: 'transparent',
            },
            '& .MuiDrawer-paper': {
                width: 380,
                boxSizing: 'border-box',
                boxShadow: 2,
            },
        }}
    >
        <Box py={2} pl={3} pr={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>Notifications</Typography>
            <IconButton onClick={onClose}>
                <Close/>
            </IconButton>
        </Box>
        <Divider/>
        {
            isLoading && <Box p={2}>
                <Typography variant={'body1'}>Loading...</Typography>
            </Box>
        }
        {
            error && <Box p={2}>
                <Typography variant={'body1'}>Error...</Typography>
            </Box>
        }
        {!isLoading && !error && <List>
            {
                data?.map((notification: any) => {
                    return <ListItem key={notification.id} secondaryAction={
                        <IconButton edge="end">
                            <Circle sx={{width: 8, height: 8}} color={getColor(notification.status)}/>
                        </IconButton>
                    } button onClick={() => {
                        navigate(notification.url);
                    }}>
                        <ListItemIcon>
                            <Avatar/>
                        </ListItemIcon>
                        <ListItemText primary={notification.title} secondary={dayjs(notification.createdAt).format('DD/MM/YYYY')}/>
                    </ListItem>
                })
            }
        </List>}
    </Drawer>
}